import { useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import TableMoreMenu from './TableMoreMenu';
import TableListHead from './TableListHead';
import { filter, map } from 'lodash';


export default function CustomTable({onDelete,onEdit,data,info}) {
  const [page, setPage] = useState(0);  
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dataFilter, setFilter] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNew=()=>{
    onEdit()
  }

  const handleFilter=(data)=>{
    return dataFilter ? filter(data, item=> item[dataFilter]) : data;
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - handleFilter(data).length) : 0;

  const filteredUsers = applySortFilter(handleFilter(data));

  return (
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {info.title}
          </Typography>
          <Button variant="contained" onClick={handleNew}>
          {info.new}
          </Button>
        </Stack>

        <Card>     
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead                  
                  headLabel={info.rows}
                  actions = {info.actions}
                  filters = {info.filters}
                  filter = {dataFilter}                                           
                  setFilter = {setFilter}                                           
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {id} = row; 
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        {info.rows.map((field,index )=>{
                           return index == 0 && info.avatar ? <TableCell key={index} component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>  
                            <Avatar alt={row[field.id]} src={row[info.avatar]} />                          
                            <Typography variant="subtitle2" noWrap>
                              {row[field.id]}
                            </Typography>
                            </Stack>
                            </TableCell>:
                            <TableCell key={index} align="left">{row[field.id]}</TableCell>
                        })}

                        {
                          map(row?._actions,(action,index)=>(
                          <TableCell key={index}>
                            {action}
                          </TableCell>))
                        } 
                        <TableCell align="right">
                          <TableMoreMenu info={info} id={id} onDelete={onDelete} onEdit={onEdit} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>               
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>  
  );
}

function applySortFilter(array) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {   
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}