import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo,useState} from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm, Controller,useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button, FormControlLabel } from '@mui/material';

// components
import Label from '../../../components/Label';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar, RHFEditor } from '../../../components/hook-form';

import EmployeeService from 'services/employee-service';
import PageContentForm from './PageContentForm';

import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import PageService from 'services/page-service';
import FixedMenu from 'components/FixedMenu';

// ----------------------------------------------------------------------


PageNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};


export default function PageNewForm({ isEdit, currentData }) {
  const navigate = useNavigate();

  const [lng,setLng] = useState('az')
  const [num,setNum] = useState(-1)
s
  const translates = [{
    lng :'az',
    name :'',  
  },
  {
    lng :'ru',
    name :'',   
  }]
  
  const defaultValues = useMemo(
    () => ({
      id: currentData?.id || 0,
      contents: currentData?.contents || [],
      translates:  currentData?.translates?.length > 0 ? currentData?.translates: translates     
    }),
    [currentData]
  );

  const methods = useForm({   
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const translateMothods = useFieldArray({
    control,
    name: "translates"
  });

  const contentMothods = useFieldArray({
    control,
    name: "contents"
  });

  const values = watch();

  const getId = () =>{
    console.log('getId')
    let id = defaultValues?.contents?.length > 0 ? Math.max(...defaultValues.contents.map(o => o._id),num) : num
    console.log(id,num)
    id = id + 1;
    console.log(id)
    setNum(id)
    return id;  
  }

  useEffect(() => {
    if (isEdit && currentData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, currentData]);

  const onSubmit = async (formData) => {    
    const a = await PageService.edit(formData)
    
    if(!a.hasError) navigate("/dashboard/pages");
  };

  const handleAdd = ()=>{

    const id = getId()

    contentMothods.append({
      _id: id,    
      position: 0,
      reverse: false,
      image: '',
      type:'',
      translates: [{
        lng :'az',
        content :'',  
      },
      {
        lng :'ru',
        content :'',   
      }],    
    });
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FixedMenu setLng={setLng} lng={lng}/>
      <Card sx={{ p: 3 }}>
        <Box >              
          {translateMothods.fields.map((item, index) =>                
            <RHFTextField sx={{ display:item.lng == lng? "inline-flex":"none" }} key={item.id} name={`translates.${index}.name`} label={'Səfifə adı'} />                
          )} 

           <IconButton  color = "primary"  onClick={handleAdd}> <AddIcon/> </IconButton>                          
            {contentMothods.fields.map((item, index) => {            
              return <PageContentForm key={item.id} lng={lng} field={item} methods={methods} move={contentMothods.swap} remove={contentMothods.remove} contentIndex={index} />    
            }                                   
          )}
        </Box>           

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {!isEdit ? 'Create User' : 'Save Changes'}
          </LoadingButton>
        </Stack>        
      </Card>        
    </FormProvider>
  );
}
