import http from '../common/http-common'

const edit = (data) => {
    return data.id == 0 ?
        http.post('users', data):
        http.put('users', data);    
}

const get = () => {    
    return http.get("users");  
}

const del = (id) => {    
    return http.del("users/"+id);  
}

const UserService = {
    get,
    edit,
    del
  };

export default UserService;