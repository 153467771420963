import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import { Box, Card,Button,IconButton, Link, Typography, Stack } from '@mui/material';
import styles from './styles.module.css';
import Iconify from 'components/Iconify';

import Page from '../../components/Page';

import StaticPageService from 'services/static-page-service';
import { find, map } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import VerticalIconMenu from 'components/vertical-icon-menu/VerticalIconMenu';
import HeaderForm from 'sections/@dashboard/static-page/HeaderForm';
import DeleteDialog from 'components/DeleteDialog';

export default function StaticPage() {
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState()
  const [mainId, setMainId] = useState()
  const [open,setOpen] = useState(false)
  const [closeMenu,setCloseMenu] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [openDialog, setOpenDialog] = useState(false)

  let navigate = useNavigate();

  const handleData=()=>{
    StaticPageService.getEditHeaders().then(d=>{
      if(!d.hasError) {
        setPages(d.data)
        console.log(d.data)
      } 
    });
  }

  useEffect(() => {
    handleData() 
  }, []);
  
  const handleEdit = (page,id) => {
    setCloseMenu(true)
    setCurrentPage(page || null)
    setMainId(id || null)
    setOpen(true)
  };

  const handleClose =()=>{
    setOpen(false);
    handleData()
  }

  const handleShow = (page) => {
    setCloseMenu(true)
    setCurrentPage()
  };

  const handleEditPage = (id) => {
    setCloseMenu(true)
    setCurrentPage()
  };

  const handleDelete = () => {
    StaticPageService.del(deleteId).then(res=>{
      if(!res.hasError) {
        handleData()
        setOpenDialog(false);
      }
    });   
  };

  const handlePosition = (first,last) => {    
    StaticPageService.changePosition({firstId:first,lastId:last}).then(res=>{
      if(!res.hasError) handleData()
    });
  };

  const handleCloseDialog =()=>{
    setDeleteId()
    setOpenDialog(false);
  }

  const handleDeleteDialog =(id)=>{
    setCloseMenu(true)
    setDeleteId(id)
    setOpenDialog(true);
  }
  
  return (
    <Page title="İşçilər"> 
    <DeleteDialog open={openDialog} handleClose={handleCloseDialog} handleDelete={handleDelete} />
    <HeaderForm currentData={currentPage} isEdit={currentPage != null} mainId={mainId} onClose={handleClose} open={open} />       
        <Box className={styles.PageContainer}>
          {map(pages, (page,index)=> (
             <Box key={index} >
              <Box className={!page.hasPage && !find(page.subPages,s=>s.hasPage) && styles.disable}>{find(page.translates,a=>a.lng == "az")?.name}
                  {index !=0 && <Box>
                    <IconButton onClick={()=>handlePosition(pages[index-1].id,pages[index].id)}><Iconify icon="akar-icons:arrow-up" rotate={3}/></IconButton>
                  </Box>}
              <VerticalIconMenu close={closeMenu} onClose={setCloseMenu}>
                <MenuItem onClick={()=>handleEdit(page)}>Adı dəyiş</MenuItem>
                <MenuItem onClick={()=>navigate(`/dashboard/static-pages/${page.id}`)}>{!page.hasPage?"Səhifə əlavə et":"Redaktə et"}</MenuItem>
                <MenuItem onClick={()=>handleDeleteDialog(page.id)}>Sil</MenuItem>
              </VerticalIconMenu>                      
              </Box>             
             { page?.subPages?.length > 0 && 
             <Box className={styles.PageSub}> {map(page.subPages,(sub,subi)=>(
                <Box key={subi} className={!sub.hasPage && styles.disable}>
                  {find(sub.translates,a=>a.lng == "az")?.name} 
                  {subi !=0 && <Box>
                    <IconButton><Iconify icon="akar-icons:arrow-up" onClick={()=>handlePosition(page.subPages[subi-1].id,page.subPages[subi].id)}/></IconButton>
                  </Box>}
                  <VerticalIconMenu close={closeMenu} onClose={setCloseMenu}>                    
                  <MenuItem onClick={()=>handleEdit(sub,page.id)}>Adı dəyiş</MenuItem>
                  <MenuItem onClick={()=>navigate(`/dashboard/static-pages/${sub.id}`)}>{!sub.hasPage?"Səhifə əlavə et":"Redaktə et"}</MenuItem>
                  <MenuItem onClick={()=>handleDeleteDialog(sub.id)}>Sil</MenuItem>
                  </VerticalIconMenu>   
                </Box>
              ))}
              </Box>
             } 
             <Button sx={{ color: 'text.secondary' }} size="small" onClick={()=>handleEdit(null,page.id)}>
                Əlavə et
              </Button>
            </Box>
          ))}
          <Box>
          <Button sx={{ color: 'text.secondary' }} size="small" onClick={()=>handleEdit()}>
            Əlavə et
          </Button>
          </Box>
        </Box>             
    </Page>
  );
}
