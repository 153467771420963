import { useParams } from 'react-router-dom';
import { useEffect,useState } from 'react';


import { Box, Container } from '@mui/material';

import Page from '../components/Page';
import AboutForm from 'sections/@dashboard/about/AboutForm';

import AboutService from 'services/about-service';


const About=()=>{
  const [data,setData]=useState([])

  useEffect(() => {
    AboutService.getEdit().then(d=>{
      if(!d.hasError) {
        setData(d.data)
        console.log(d.data)    
      } 
    });    
  }, []);

  return (
    <Page title="Haqqinda">
      <Container maxWidth={false}>       
        <AboutForm currentData={data} />
      </Container>
      <Box height={30}></Box>     
    </Page>
  );
}

export default About;