import { useParams } from 'react-router-dom';
import { useState,useEffect } from 'react';

import { Container } from '@mui/material';

import Page from '../components/Page';
import BlogService from '../services/blog-service';
import BlogForm from '../sections/@dashboard/blog/BlogForm';


export default function BlogCreate() {
  const { id } = useParams();
  const [currentData,setData] = useState();

  const isEdit = id != undefined

  useEffect(() => {    
    BlogService.getEdit(id).then(d=>{
      if(!d.hasError) {
        setData(d.data)
        console.log(d.data)    
      } 
    });
  }, []);

  
  return (
    <Page title="Xəbərlər">
      <Container maxWidth={false}>       
        <BlogForm isEdit={isEdit} currentData={currentData} />
      </Container>
    </Page>
  );
}