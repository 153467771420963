import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate  } from 'react-router-dom';


import Page from '../components/Page';
import CustomTable from '../components/table/CustomTable';

import EmployeeService from 'services/employee-service';
import { map } from 'lodash';
import Iconify from 'components/Iconify';
import { Button } from '@mui/material';
import CalculatorService from 'services/calculator-service';


const INFO = {
  title: "",
  new : "Yeni Kalkulator",
  edit : "Dəyiş",
  delete : "Sil",
  rows : [
    { id: 'name', label: "" },
    { id: 'pageId', label: "" },
  ],
  actions:[
    ""
  ],
  avatar : 'name'
}

export default function Calculator() {
  const [data, setData] = useState([]);
  let navigate = useNavigate();

  const handleData=()=>{
    CalculatorService.getAllPages().then(d=>{
      if(!d.hasError) {
        setData(d.data)
        console.log(d.data)
      } 
    });
  }

  useEffect(() => {
    handleData() 
  }, []);
  
  const handleEdit = (id) => {
    if(id == undefined) navigate(`/dashboard/calculator/add`);
    else navigate(`/dashboard/calculator/${id}/edit`);
  };

  const handleDelete = (id) => {
    CalculatorService.delPage(id).then(res=>{
      if(!res.hasError) handleData()
    });
  };

  return (
    <Page title="Kalkulator">
      <CustomTable info={INFO} onDelete= {handleDelete} onEdit={handleEdit} data={data} />             
    </Page>
  );
}

