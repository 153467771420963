import { useParams } from 'react-router-dom';
import { useEffect,useState } from 'react';


import { Box, Container } from '@mui/material';

import Page from '../components/Page';
import ServiceItemNewForm from 'sections/@dashboard/service/ServiceItemNewForm';

import ServiceItemService from 'services/service-item-service';


const ServiceItemCreate=()=>{
    
  const [data, setData] = useState([]);
    
  const { id } = useParams();
  const { serviceId } = useParams();

  const isEdit = id != undefined

  useEffect(() => {
    ServiceItemService.getEdit(id).then(d=>{
      if(!d.hasError) {
        setData(d.data)
        console.log(d.data)      } 
    });    
  }, []);

  return (
    <Page title="Xidmətlər">
      <Container maxWidth={false}>       
        <ServiceItemNewForm isEdit={isEdit} currentData={data} serviceId={serviceId} />
      </Container>
      <Box height={30}></Box>     
    </Page>
  );
}

export default ServiceItemCreate;