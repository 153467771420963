import PropTypes from 'prop-types';
import { useCallback, useRef, useEffect, useMemo, useState, createElement } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
// form
import { useForm,useWatch, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button, FormControlLabel } from '@mui/material';

// components
import Label from '../../../components/Label';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar, RHFEditor } from '../../../components/hook-form';


import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';

import FixedMenu from 'components/FixedMenu';
import FormTable from 'components/form-table/FormTable';
import { findIndex, forEach } from 'lodash';
import GalleryCategoryService from 'services/gallery-category-service';

// ----------------------------------------------------------------------

CategoryNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};

export default function CategoryNewForm({ isEdit, currentData }) {
  const navigate = useNavigate();

  const [lng, setLng] = useState('az')

  const categorySchema = Yup.object().shape({   
    translates:  Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().nullable()
          .required("Ad vacibdir")      
      })
    )
  });

  const translates = [{
    id:0,
    lng: 'az',
    name: '',
  },
  {
    id:0,
    lng: 'ru',
    name: '',
  }]

  const defaultValues = useMemo(
    () => ({
      id: currentData?.id || 0,
      translates: currentData?.translates?.length > 0 ? currentData?.translates : translates,   
    }),
    [currentData]
  );

  const methods = useForm({
    resolver: yupResolver(categorySchema),
    defaultValues,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting, errors},
  } = methods;

  const translateMothods = useFieldArray({
    control,
    name: "translates"
  });

 
  useEffect(() => {
    if (isEdit && currentData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, currentData]);

  const translatesField = useWatch({
    control,
    name: "translates",
  });

  useEffect(() => {
    errors.translates && setLng(translatesField[findIndex(errors?.translates,a=>a!=null)].lng) 
  }, [errors]);

  const onSubmit = async (formData) => {

    console.log(formData)
    const a = await GalleryCategoryService.edit(formData)

    if(!a.hasError && !isEdit) navigate(`/dashboard/gallery-category/${a.data}/edit`);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FixedMenu setLng={setLng} lng={lng} />      
      <Card sx={{ p: 3 }}>
        <Box >
          {translateMothods.fields.map((item, index) =>
            <RHFTextField sx={{ display: item.lng == lng ? "inline-flex" : "none" }} key={item.id} name={`translates.${index}.name`} label={'Səhifə adı'} />
          )}        
        </Box>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {!isEdit ? 'Yadda saxla' : 'Redatə et'}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
