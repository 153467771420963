// components
import Page from '../components/Page';


import BlogService from 'services/blog-service';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomTable from 'components/table/CustomTable';


const INFO = {
  title: "",
  new : "Yeni Xəbər",
  edit : "Dəyiş",
  delete : "Sil",
  rows : [
    { id: 'name', label: "" },
    { id: 'surname', label: "" },
  ],
  actions:[
    ""
  ],
  avatar : 'image'
}
// ----------------------------------------------------------------------

export default function Blog() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const handleData=()=>{
    BlogService.getAll().then(d=>{
      if(!d.hasError) {
        setData(d.data)
        console.log(d.data)
      } 
    });
  }

  useEffect(() => {
    handleData() 
  }, []);
  
  const handleEdit = (id) => {
    if(id == undefined) navigate(`/dashboard/blog/add`);
    else navigate(`/dashboard/blog/${id}/edit`);
  };


  const handleDelete = (id) => {
    BlogService.del(id).then(res=>{
      if(!res.hasError) handleData()
    });
  };

  return (
    <Page title="İşçilər">
      <CustomTable info={INFO} onDelete= {handleDelete} onEdit={handleEdit} data={data} />             
    </Page>
  );
}
