import { useParams } from 'react-router-dom';
import { useState,useEffect } from 'react';

import { Container } from '@mui/material';

import Page from '../components/Page';
import ProjectNewForm from 'sections/@dashboard/project/ProjectNewForm';
import ProjectService from 'services/project-service';


export default function ProjectCreate() {
  const { id } = useParams();
  const [currentData,setData] = useState();

  const isEdit = id != undefined

  useEffect(() => {    
    ProjectService.getEdit(id).then(d=>{
      if(!d.hasError) {
        setData(d.data)
      } 
    });
  }, []);

  
  return (
    <Page title="Layihələr">
      <Container maxWidth={false}>       
        <ProjectNewForm isEdit={isEdit} currentData={currentData} />
      </Container>
    </Page>
  );
}