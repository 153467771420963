import http from '../common/http-common'

const getAll = () => {    
    return http.get("serviceItems");  
}

const getEdit = (id) => {
    return id == undefined ?
        http.get("serviceItems/add") :
        http.get(`serviceItems/${id}/edit`);
}

const edit = (data) => {
    return data.id == 0 ?
        http.post("serviceItems", data) :
        http.put("serviceItems", data)
}

const del = (id) => {
    console.log('del'+ id)    
    return http.del("serviceItems/"+id);  
}

const ServiceItemService = {
    getAll,
    getEdit,
    edit,
    del
  };

export default ServiceItemService;