import http from '../common/http-common'

const getAll = () => {    
    return http.get("employees");  
}

const getEdit = (id) => {
    if(id == undefined) return http.get("employees/add");  
    return http.get(`employees/${id}/edit`);  
}

const changePosition = (data) => {
    return http.put("employees/Position", data)
}

const edit =(data)=>{

    const form = new FormData();
    
    form.append('Id',data.id || 0)    
    form.append('LinkFacebook',data.linkFacebook)
    form.append('Root',data.root)
    form.append('LinkLinkedin',data.linkLinkedin)
    form.append('LinkInstagram',data.linkInstagram)
    form.append('LinkTwitter',data.linkTwitter)
    form.append('Email',data.email)
    form.append('Image',data.image)
    form.append('ImageName',data?.image?.name || data.imageName)
    form.append('OfficePhonenumber',data.officePhonenumber)
    form.append('Position',data.Position)
    form.append('PerconalPhonenumber',data.perconalPhonenumber)
    form.append('ShowOnHomePage',data.showOnHomePage)

    data.translates.map((i,index)=>{
       form.append(`Translates[${index}].Id`,i.id || 0) 
       form.append(`Translates[${index}].Lng`,i.lng)  
       form.append(`Translates[${index}].Name`,i.name||"")
       form.append(`Translates[${index}].Surname`,i.surname||"")
       form.append(`Translates[${index}].Position`,i.position||"")
       form.append(`Translates[${index}].ShortDescription`,i.shortDescription ||"")       
       form.append(`Translates[${index}].LongDescription`,i.longDescription ||"")       
    })

    if(data.id == 0) 
        return http.post('employees',form,{ "Content-Type": "multipart/form-data" })
    else
        return http.put('employees',form,{ "Content-Type": "multipart/form-data" })
}

const del = (id) => {    
    return http.del("employees/"+id);  
}

const EmployeeService = {
    getAll,   
    getEdit,
    edit,
    del,
    changePosition
  };

export default EmployeeService;