import PropTypes from 'prop-types';
import { useCallback, useRef, useEffect, useMemo, useState, createElement } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { useForm,useWatch, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button, FormControlLabel } from '@mui/material';

import { FormProvider, RHFSelect, RHFSwitch, RHFTextField } from '../../../components/hook-form';
import StaticPageService from 'services/static-page-service';
import FixedMenu from 'components/FixedMenu';
import { findIndex } from 'lodash';



HeaderForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};

export default function HeaderForm({ currentData, mainId, isEdit, onClose, open }) {

  const [lng, setLng] = useState('az')
 
  const translates = [ {
    id: 0,
    lng: "az",
    name: "",
    title: "",
    description: ""
  },
  {
    id: 0,
    lng: "ru",
    name: "",
    title: "",
    description: ""
  }]

  const defaultValues = useMemo(
    () => ({
      id: currentData?.id || 0,
      mainPageId: currentData?.mainPageId || mainId,
      show: currentData?.show || true,
      hasPage: currentData?.hasPage || false,
      translates: currentData?.translates?.length > 0 ? currentData?.translates:translates      
    }),
    [open]
  );

  const videoSchema = Yup.object().shape({
    translates:  Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().nullable()
          .required("Ad vacibdir")       
      })
    )
 
  });

  const methods = useForm({
    resolver: yupResolver(videoSchema),
    defaultValues,
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting, errors},
  } = methods;

  useEffect(() => {
    console.log(defaultValues,currentData,mainId)
    reset(defaultValues);
  }, [open]);
  
  const translatesField = useWatch({
    control,
    name: "translates",
  });

  useEffect(() => {
    errors.translates && setLng(translatesField[findIndex(errors?.translates,a=>a!=null)].lng) 
  }, [errors]);


  const translateMothods = useFieldArray({
    control,
    name: "translates"
  });

  const onSubmit = async (data) => {

   const res = await StaticPageService.editHeader(data)

   res.hasError || onClose();    
  }


  return (
    <Dialog
      maxWidth='xs'
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        {console.log(currentData,"inner")}
      <FixedMenu setLng={setLng} lng={lng} />    
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {translateMothods.fields.map((item, index) =>          
          <RHFTextField key={index} sx={{ display: item.lng == lng ? "block" : "none" }} name={`translates.${index}.name`}  />          
        )}

        <LoadingButton sx={{marginTop:"10px"}} type="submit" variant="contained" loading={isSubmitting}>
          {!isEdit ? 'Yadda saxla' : 'Redatə et'}
        </LoadingButton>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}