import http from '../common/http-common'

const getEdit = () => {    
    return http.get('about/edit');     
}

const edit = async (value)=>{
    const data = value.translates;

    data.forEach(e => {
        e.chartValue1 = value.chartValue1 || 0,
        e.chartValue2 = value.chartValue2 || 0,
        e.chartValue3 = value.chartValue3 || 0,
        e.chartValue4 = value.chartValue4 || 0,
        e.maxChartValue1 = value.maxChartValue1 || 0,
        e.maxChartValue2 = value.maxChartValue2 || 0,
        e.maxChartValue3 = value.maxChartValue3 || 0,
        e.maxChartValue4 = value.maxChartValue4 || 0    
    });

    return await http.put("about",data)
}

const AboutService = {   
    getEdit,
    edit
  };

export default AboutService;