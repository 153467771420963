import { useParams } from 'react-router-dom';
import { useState,useEffect } from 'react';

import { Container } from '@mui/material';

import Page from '../components/Page';
import PageNewForm from 'sections/@dashboard/page/PageNewForm';
import PageService from 'services/page-service';
import { setISODay } from 'date-fns';

export default function DynamicPageCreate() {
  const { id } = useParams();
  const [currentData,setData] = useState();

  const isEdit = id != undefined

  useEffect(() => { 
    console.log(id,'a')   
    PageService.getEdit(id).then(d=>{
      if(!d.hasError) {        
        const data = d.data;
        setId(data?.contents)               
        setData(data)
        console.log(d.data,'a')    
      } 
    });
  }, []);

  return (
    <Page title="User: Create a new user">
      <Container maxWidth={false}>       
        <PageNewForm isEdit={isEdit} currentData={currentData} />
      </Container>
    </Page>
  );
}

function setId(data){
  if(!data) return;
  let id = 0;
  data.forEach(e => {
    e._id = id;
    id++;
  });
}