import PropTypes from 'prop-types';
import { useCallback, useRef, useEffect, useMemo, useState, createElement } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import "../../../modules/custom-validation/EditorValidation"
// form
import { useForm, useWatch, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button, FormControlLabel } from '@mui/material';

// components
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar, RHFEditor } from '../../../components/hook-form';


import FixedMenu from 'components/FixedMenu';
import { find, findIndex, keys, multiply } from 'lodash';
import BlogService from 'services/blog-service';

import styles from "./styles.module.css"
import HomeService from 'services/home-service';
import { SketchPicker } from 'react-color';
import { ColorManyPicker } from 'components/color-utils';


HomeForm.propTypes = {
    currentData: PropTypes.object,
};

export default function HomeForm({ currentData, setLng, lng }) {

    const translates = [{
        id: 0,
        lng: "az",
        mainVideo: "",
        mainTitle: "",
        mainDescription: "",
        aboutTitle1: "",
        aboutDescription1: "",
        aboutTitle2: "",
        aboutDescription2: "",
        aboutTitle3: "",
        aboutDescription3: "",
        about2Title: "",
        about2Description: "",
        teamDescription: "",
        serviceDescription: "",
        projectDescription: "",
        studentDescription: "",
    },
    {
        id: 0,
        lng: "ru",
        mainTitle: "",
        mainVideo: "",
        mainDescription: "",
        aboutTitle1: "",
        aboutDescription1: "",
        aboutTitle2: "",
        aboutDescription2: "",
        aboutTitle3: "",
        aboutDescription3: "",
        about2Title: "",
        about2Description: "",
        teamDescription: "",
        serviceDescription: "",
        projectDescription: "",
        studentDescription: "",
    }]


    const defaultValues = useMemo(
        () => ({
            id: currentData?.id || 0,
            mainImage: currentData?.mainImage || '',
            mainImageName: currentData?.mainImageName || '',
            mainTextColor: currentData?.mainTextColor || '',
            aboutImage1: currentData?.aboutImage1 || '',
            aboutImageName1: currentData?.aboutImageName1 || "",
            aboutImage2: currentData?.aboutImage2 || '',
            aboutImageName2: currentData?.aboutImageName2 || "",
            aboutImage3: currentData?.aboutImage3 || '',
            aboutImageName3: currentData?.aboutImageName3 || "",
            about2Image: currentData?.about2Image || "",
            about2ImageName: currentData?.about2ImageName || "",
            showTeam: currentData?.showTeam,
            showProject: currentData?.showProject,
            showService: currentData?.showService,
            showStudent: currentData?.showStudent,
            showInfo: currentData?.showInfo,
            showAbout1: currentData?.showAbout1,
            showAbout2: currentData?.showAbout2,
            translates: currentData?.translates?.length > 0 ? currentData?.translates : translates
        }),
        [currentData]
    );

    const reg = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

    const videoSchema = Yup.object().shape({
        showAbout1: Yup.boolean(),
        showAbout2: Yup.boolean(),

        aboutImage1: Yup.mixed().when("showAbout1", {
            is: true,
            then: Yup.mixed().test('required', 'Şəkil vacibdir', (value) => value !== ''),
        }),
        aboutImage2: Yup.mixed().when("showAbout1", {
            is: true,
            then: Yup.mixed().test('required', 'Şəkil vacibdir', (value) => value !== ''),
        }),
        aboutImage3: Yup.mixed().when("showAbout1", {
            is: true,
            then: Yup.mixed().test('required', 'Şəkil vacibdir', (value) => value !== ''),
        }),

        about2Image: Yup.mixed().when("showAbout2", {
            is: true,
            then: Yup.mixed().test('required', 'Şəkil vacibdir', (value) => value !== ''),
        }),

        translates: Yup.array().of(
            Yup.object().shape({
                mainTitle: Yup.string().nullable().required("Bu hissə vacibdir"),
                mainDescription: Yup.string().nullable().required("Bu hissə vacibdir"),
                mainVideo: Yup.string().matches(reg, "Format duzgun deyil"),
                aboutTitle1: Yup.string().when("showAbout1", {
                    is: true,
                    then: Yup.string().nullable().required("Bu hissə vacibdir"),
                }),
                aboutDescription1: Yup.string().when("showAbout1", {
                    is: true,
                    then: Yup.string().nullable().required("Bu hissə vacibdir"),
                }),
                aboutTitle2: Yup.string().when("showAbout1", {
                    is: true,
                    then: Yup.string().nullable().required("Bu hissə vacibdir"),
                }),
                aboutDescription2: Yup.string().when("showAbout1", {
                    is: true,
                    then: Yup.string().nullable().required("Bu hissə vacibdir"),
                }),
                aboutTitle3: Yup.string().when("showAbout1", {
                    is: true,
                    then: Yup.string().nullable().required("Bu hissə vacibdir"),
                }),
                aboutDescription3: Yup.string().when("showAbout1", {
                    is: true,
                    then: Yup.string().nullable().required("Bu hissə vacibdir"),
                }),                
                about2Description: Yup.string().when("showAbout2", {
                    is: true,
                    then: Yup.string().nullable().required("Bu hissə vacibdir"),
                }),
            })
        )
    });

    const methods = useForm({
        resolver: yupResolver(videoSchema),
        defaultValues,
    });

    const {
        reset,
        watch,
        setValue,
        control,
        handleSubmit,
        formState: { isSubmitting, errors },
    } = methods;

    const translateMothods = useFieldArray({
        control,
        name: "translates"
    });

    useEffect(() => {
        if (currentData) {
            reset(defaultValues);
        }
    }, [currentData]);

    const translatesField = useWatch({
        control,
        name: "translates",
    });

    useEffect(() => {
        console.log('errors', errors)
        errors.translates && setLng(translatesField[findIndex(errors?.translates, a => a != null)].lng)
    }, [errors]);

    const handleImage = (acceptedFiles, field) => {
        const file = acceptedFiles[0];
        if (file) {
            setValue(
                field,
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
        }
    }


    const onSubmit = async (formData) => {
        await HomeService.edit(formData)
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <FixedMenu setLng={setLng} lng={lng} />
            <Card sx={{ p: 3 }}>

                <Box className={styles.mainSection}>
                    <RHFUploadAvatar
                        sx={{ width: 100, height: 100, marginBottom: "10px" }}
                        name="mainImage"
                        onChange={(img) => { handleImage(img, "mainImage") }}
                        label="Əsas şəkil"
                    />
                    {translateMothods.fields.map((item, index) =>
                        <Box key={item.id} sx={{ display: item.lng == lng ? "block" : "none" }}>
                            <RHFTextField label={"Əsas başlıq"} name={`translates.${index}.mainTitle`} sx={{ marginBottom: "10px" }} />
                            <RHFTextField label={"Video"} name={`translates.${index}.mainVideo`} />
                        </Box>
                    )}

                            

                    {translateMothods.fields.map((item, index) =>
                        <Box key={item.id} sx={{ display: item.lng == lng ? "block" : "none" }}>
                            <RHFTextField
                                label={"Mətn"} name={`translates.${index}.mainDescription`} multiline rows={2} />
                            <RHFSwitch
                                name="showInfo"
                                labelPlacement="start"
                                label={
                                    <>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Info
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, marginLeft: "10px" }}
                            />  
                            <RHFTextField
                            sx={{ width: "100px"}}
                                label={"Əsas Rəng"}
                                type="color"
                                name="mainTextColor"
                            />                          
                        </Box>
                    )}
                </Box>

                <Box className={styles.about1Section}>
                    <RHFSwitch
                        name="showAbout1"
                        labelPlacement="start"
                        label={
                            <>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Haqqımızda 1
                                </Typography>
                            </>
                        }
                        sx={{ mx: 0, marginLeft: "10px" }}
                    />
                    <Box>
                        <RHFUploadAvatar
                            sx={{ width: 100, height: 100, marginBottom: "10px" }}
                            name="aboutImage1"
                            onChange={(img) => { handleImage(img, "aboutImage1") }}
                        />
                        {translateMothods.fields.map((item, index) =>
                            <Box key={item.id} sx={{ display: item.lng == lng ? "block" : "none" }}>
                                <RHFTextField sx={{ marginBottom: "10px" }} label={"Əsas başlıq"} name={`translates.${index}.aboutTitle1`} />
                                <RHFTextField label={"Mətn"} name={`translates.${index}.aboutDescription1`} multiline rows={2} />
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <RHFUploadAvatar
                            sx={{ width: 100, height: 100, marginBottom: "10px" }}
                            name="aboutImage2"
                            onChange={(img) => { handleImage(img, "aboutImage2") }}
                        />
                        {translateMothods.fields.map((item, index) =>
                            <Box key={item.id} sx={{ display: item.lng == lng ? "block" : "none" }}>
                                <RHFTextField sx={{ marginBottom: "10px" }} label={"Əsas başlıq"} name={`translates.${index}.aboutTitle2`} />
                                <RHFTextField label={"Mətn"} name={`translates.${index}.aboutDescription2`} multiline rows={2} />
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <RHFUploadAvatar
                            sx={{ width: 100, height: 100, marginBottom: "10px" }}
                            name="aboutImage3"
                            onChange={(img) => { handleImage(img, "aboutImage3") }}
                        />
                        {translateMothods.fields.map((item, index) =>
                            <Box key={item.id} sx={{ display: item.lng == lng ? "block" : "none" }}>
                                <RHFTextField sx={{ marginBottom: "10px" }} label={"Əsas başlıq"} name={`translates.${index}.aboutTitle3`} />
                                <RHFTextField label={"Mətn"} name={`translates.${index}.aboutDescription3`} multiline rows={2} />
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box className={styles.about2Section}>
                    <Box>
                        <RHFSwitch
                            name="showAbout2"
                            labelPlacement="start"
                            label={
                                <>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        Haqqımızda 2
                                    </Typography>
                                </>
                            }
                            sx={{ mx: 0, marginLeft: "10px" }}
                        />
                        <RHFUploadAvatar
                            sx={{ width: 100, height: 100, marginBottom: "10px" }}
                            name="about2Image"
                            onChange={(img) => { handleImage(img, "about2Image") }}
                        />
                    </Box>
                    {translateMothods.fields.map((item, index) =>
                        <Box key={item.id} sx={{ display: item.lng == lng ? "block" : "none" }} >                            
                            <RHFEditor name={`translates.${index}.about2Description`} nameEditor={`translates${index}about2Description`} />
                        </Box>
                    )}
                </Box>

                <Box className={styles.otherSections}>
                    <Box>
                        <RHFSwitch
                            name="showTeam"
                            labelPlacement="start"
                            label={
                                <>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        Əməkdaşlar
                                    </Typography>
                                </>
                            }
                            sx={{ mx: 0, marginLeft: "10px" }}
                        />
                        {translateMothods.fields.map((item, index) =>
                            <RHFTextField key={item.id} sx={{ display: item.lng == lng ? "block" : "none" }}
                                label={"Mətn"} name={`translates.${index}.teamDescription`} multiline rows={2} />
                        )}
                    </Box>
                    <Box>
                        <RHFSwitch
                            name="showService"
                            labelPlacement="start"
                            label={
                                <>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        Xidmətlər
                                    </Typography>
                                </>
                            }
                            sx={{ mx: 0, marginLeft: "10px" }}
                        />
                        {translateMothods.fields.map((item, index) =>
                            <RHFTextField key={item.id} sx={{ display: item.lng == lng ? "block" : "none" }}
                                label={"Mətn"} name={`translates.${index}.serviceDescription`} multiline rows={2} />
                        )}
                    </Box>
                    <Box>
                        <RHFSwitch
                            name="showProject"
                            labelPlacement="start"
                            label={
                                <>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        Layihələr
                                    </Typography>
                                </>
                            }
                            sx={{ mx: 0, marginLeft: "10px" }}
                        />
                        {translateMothods.fields.map((item, index) =>
                            <RHFTextField key={item.id} sx={{ display: item.lng == lng ? "block" : "none" }}
                                label={"Mətn"} name={`translates.${index}.projectDescription`} multiline rows={2} />
                        )}
                    </Box>
                    <Box>
                        <RHFSwitch
                            name="showStudent"
                            labelPlacement="start"
                            label={
                                <>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        Uğurlu tələbələr
                                    </Typography>
                                </>
                            }
                            sx={{ mx: 0, marginLeft: "10px" }}
                        />
                        {translateMothods.fields.map((item, index) =>
                            <RHFTextField key={item.id} sx={{ display: item.lng == lng ? "block" : "none" }}
                                label={"Mətn"} name={`translates.${index}.studentDescription`} multiline rows={2} />
                        )}
                    </Box>
                </Box>

                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {'Yadda saxla'}
                    </LoadingButton>
                </Stack>
            </Card>
        </FormProvider>
    );
}
