import { forEach } from "lodash";
import http from '../common/http-common'

const getEditText = () => {    
    return http.get(`students/editText`);  
}

const getEditImages = () => {    
    return http.get(`Students/editImages`);  
}

const showHome = (id,show) => {    
    return http.get(`Students/showHome/${id}/${show}`);  
}

const position = (id) => {    
    return http.get(`Students/position/${id}`);  
}

const getEditVideos = () => {    
    return http.get(`Students/editVideos`);  
}

const editText =(data)=>{    
    return http.put('students',data)
}

const addImages =(data)=>{
    const form = new FormData();  
    forEach(data,(i,index)=>{
       form.append(`Images[${index}].Id`,0) 
       form.append(`Images[${index}].ImageName`,i?.name)  
       form.append(`Images[${index}].Image`, i || "")      
    })
    return http.post('students/images',form,{ "Content-Type": "multipart/form-data" })
}

const uploadVideo = (data)=>{
    const form = new FormData();      
    form.append('Id',data?.id) 
    form.append('Name',data?.name) 
    form.append('Video',data?.video) 
    form.append('Uploaded',data?.uploaded)
    form.append('End',data?.end)
    form.append('ShowOnHomePage',data.showOnHomePage)
    
    return http.post('students/uploadVideo',form,{ "Content-Type": "multipart/form-data" })
}

const addVideo =(data)=>{    
    return http.post('students/video',data)
}

const delVideo = (id) => {    
    return http.del("students/video/"+id);  
}

const delImage = (id) => {    
    return http.del("students/image/"+id);  
}

const StudentService = {  
    getEditText,
    getEditImages,
    getEditVideos,
    editText,
    addVideo,
    addImages,
    delVideo,
    delImage,
    uploadVideo,
    showHome,
    position
  };

export default StudentService;