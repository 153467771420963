import http from '../common/http-common'

const add =(data)=>{

    const form = new FormData();

    const config = {
      onUploadProgress: progressEvent => console.log(progressEvent.loaded)
    }

    form.append('Image',data)

    return http.post("images",form,{ "Content-Type": "multipart/form-data" },config)
}



const ImageService = {   
    add
  };

export default ImageService;