import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect, useMemo} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';

import { Box,Button, Card, Stack} from '@mui/material';

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import UserService from 'services/user-service';

UserForm.propTypes = { 
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function UserForm({isEdit, currentUser,onSubmit}) {

  const NewUserSchema = Yup.object().shape({
    username: Yup.string().required('İstifadəci adı vacibdir'),
    password: Yup.string().required('Şifrə vacibdir'),   
  });

  const defaultValues = useMemo(
    () => ({
      id: currentUser?.id || 0,
      username: currentUser?.username || '',
      password: '',     
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,    
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, currentUser]);

  return ( 
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <RHFTextField name="username" label="İstifadəci adı" />
          <RHFTextField name="password" label="Şifrə" />              
        </Box> 
        <LoadingButton fullWidth type="submit" variant="contained" size="medium" loading={isSubmitting}>
          Post
        </LoadingButton>
    </FormProvider>
  );
}
