import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate  } from 'react-router-dom';


import Page from '../components/Page';
import CustomTable from '../components/table/CustomTable';

import EmployeeService from 'services/employee-service';
import { map } from 'lodash';
import Iconify from 'components/Iconify';
import { Button } from '@mui/material';


const INFO = {
  title: "",
  new : "Yeni Əməkdaş",
  edit : "Dəyiş",
  delete : "Sil",
  filters : [
    { id: 'showOnHomePage', label: "Ana səhifədə" },   
  ],
  rows : [
    { id: 'name', label: "Ad" },
    { id: 'surname', label: "Soyad" },    
  ],
  actions:[
    "Sıra",
    "Ana səhifə",
  ],
  avatar : 'image'
}

export default function Employee() {
  const [data, setData] = useState([]);
  let navigate = useNavigate();

  const handleData=()=>{
    EmployeeService.getAll().then(d=>{
      if(!d.hasError) {
        setData(d.data)
        console.log(d.data)
      } 
    });
  }

  useEffect(() => {
    handleData() 
  }, []);
  
  const handleEdit = (id) => {
    if(id == undefined) navigate(`/dashboard/employees/add`);
    else navigate(`/dashboard/employees/edit/${id}`);
  };

  const handlePosition = (first,last) => { 
    console.log(first,last,"change")   
    EmployeeService.changePosition({firstId:first,lastId:last}).then(res=>{
      if(!res.hasError) handleData()
    });
  };

  const getData =()=>{
    return map(data,(item,index)=>({...item,_actions:[
     index != 0 ? 
      <Button sx={{ color: 'text.secondary' }} size="small" 
      onClick={()=>handlePosition(data[index-1].id,data[index].id)} 
        startIcon={<Iconify icon="akar-icons:arrow-down" rotate={2} width={20} height={20} />}>
        Sıranı dəyiş
      </Button>:null,
      item.showOnHomePage ? <Iconify sx={{ color: 'text.secondary' }} icon="ep:success-filled" width={20} height={20} />:null
    ]}))
  }

  const handleDelete = (id) => {
    EmployeeService.del(id).then(res=>{
      if(!res.hasError) handleData()
    });
  };

  return (
    <Page title="İşçilər">
      <CustomTable info={INFO} onDelete= {handleDelete} onEdit={handleEdit} data={getData()} />             
    </Page>
  );
}

