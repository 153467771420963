import { Button,Box ,TextField,IconButton} from '@mui/material';
import { Symbols } from 'assets/Symbols';
import { filter, includes, map, indexOf } from 'lodash';
import { useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './SymbolDialog.css';
import Iconify from 'components/Iconify';

export default function SymbolDialog() {
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false);
  const hiddenButton = useRef(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpen = (value)=>{
    hiddenButton.current.value = value;
    hiddenButton.current.click();
    setOpen(false)
  }
    
  return (
    <Box>
        <button type="button" onClick={()=>{setOpen(true)}}><Iconify width={25} height={25} icon={'emojione:pound-symbol'} /></button>
        <button type="button"  ref={hiddenButton} className="ql-symbol" style={{display: 'none'}}></button>  
        <Box sx={{display: open? "block":"none"}} className="symbol-container" >        
          <Box className="symbol-dialog" > 
           <Box sx ={{height: "40px", display: "flex", justifyContent: "end"}} ><IconButton onClick={()=>setOpen(false)}><Iconify icon={'bi:x-lg'} width={30} height={30} /></IconButton></Box>                        
            {open && map(Symbols, (s,index)=>( 
            <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>                         
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id={`panel${index}bh-header`}
                >
                  <Typography sx={{ width: '100%', flexShrink: 0 }}>
                    {s?.category}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {map(s?.symbols,(item,itemi)=>(
                      <Tooltip key={itemi} title={item?.title}>
                        <button key={itemi} type="button" onClick={()=>handleOpen(item?.value)}  className="icon-symbol">
                          {item.value}               
                        </button>
                      </Tooltip>                      
                    ))}
                </AccordionDetails>
              </Accordion>              
            ))}
          </Box>
        </Box>     
    </Box>      
  );
}
