import { forEach } from 'lodash';
import http from '../common/http-common'

const getEditHeaders = () => {
    return http.get("StaticPages/EditHeaders");
}

const getEditPage = (id) => {
    console.log(id,'id')
    return http.get(`StaticPages/EditPage/${id}`);
}

const getEditImages = (id) => {
    console.log(id,'id')
    return http.get(`StaticPages/Images/${id}`);
}

const addImages = (data,pageId) => {
    console.log(data,pageId)
    const form = new FormData();      
    forEach(data,(i, index) => {       
        form.append(`Images[${index}].Id`, 0)
        form.append(`Images[${index}].StaticPageId`,pageId)
        form.append(`Images[${index}].ImageName`, i?.name)
        form.append(`Images[${index}].Image`, i)
    })

    return http.post(`StaticPages/Images`,form,{ "Content-Type": "multipart/form-data" });
}

const delImage = (id) => {   

    return http.del(`StaticPages/Image/${id}`);
}

const editHeader = (data) => {
    return data.id == 0 ? http.post("StaticPages/Header", data):
    http.put("StaticPages/Header", data)
}

const changePosition = (data) => {
    return http.put("StaticPages/Position", data)
}

const editPage = (data) => {
    console.log(data,'static')

    const form = new FormData();     

    form.append('Id', data.id)
    form.append('PageId', data.pageId)
    form.append('MainPageId', data.mainPageId)
    form.append('MainImage', data.mainImage)
    form.append('MainImageName',data?.mainImage?.name || data.mainImageName)
    form.append('Show', data.show)
    form.append('HasPage', data.hasPage)
    form.append('ShowFooter', data.showFooter)

    data.translates.map((i, index) => {       
        form.append(`Translates[${index}].Id`, i.id)
        form.append(`Translates[${index}].Name`, i.name || "")
        form.append(`Translates[${index}].Title`, i.title || "")
        form.append(`Translates[${index}].Description`, i.description || "")
        form.append(`Translates[${index}].Lng`, i.lng)
    })

    return http.put("StaticPages", form,{ "Content-Type": "multipart/form-data" })
}

const del = (id) => {    
    return http.del("StaticPages/"+id);  
}

const StaticPageService = {
    getEditHeaders,   
    getEditPage,   
    editHeader,   
    changePosition,
    editPage,
    delImage,
    getEditImages,
    addImages,
    del
};

export default StaticPageService;