import { useParams,useNavigate } from 'react-router-dom';
import { useEffect,useState } from 'react';


import { Box, Container } from '@mui/material';

import Page from '../components/Page';
import CustomTable from 'components/table/CustomTable';

import StaticPageForm from 'sections/@dashboard/static-page/StaticPageForm';
import StaticPageService from 'services/static-page-service';

const INFO = {
  title: "Şəkillər",
  new : "Yeni şəkil",
  delete : "Sil",
  rows : [
    { id: 'imageName', label: "  " },
  ],
  avatar : 'image'
}

const StaticPageEdit=()=>{
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [images, setImages] = useState([])
    
  const { id } = useParams();

  const handleData=()=>{
    StaticPageService.getEditPage(id).then(d=>{
      if(!d.hasError) {
        setData(d.data)
        console.log(d.data,"service")      } 
    });  
  }

  const handleImages=()=>{
    StaticPageService.getEditImages(id).then(d=>{
      if(!d.hasError) {
        setImages(d.data)      } 
    });  
  }

  useEffect(() => {
    handleData()
    handleImages()     
  }, []);

  const handleEdit = (itemId) => {       
      navigate(`/dashboard/static-pages`);
  };

  const handleAddImages = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.setAttribute('multiple', 'true');
    input.click();

    input.onchange = async () => {
      const files = input.files;
      console.log(files,'files')

      const res = await StaticPageService.addImages(files,id)
      if(!res.hasError) handleImages()
    };
  }

  const handleDeleteImage = async (imageId) => {
    const res = await StaticPageService.delImage(imageId)
    if(!res.hasError) handleImages()
  };

  const handleDelete = (id) => {
    StaticPageService.del(id).then(res=>{
      if(!res.hasError) handleData()
    });
  };

  return (
    <Page title="Səhifələr">
      <Container maxWidth={false}>       
       {data && <StaticPageForm currentData={data}/>}
      </Container>
      <Box height={30}></Box>      
      <CustomTable info={INFO} onDelete= {handleDeleteImage} onEdit={handleAddImages} data={images || []} /> 
    </Page>
  );
}

export default StaticPageEdit;