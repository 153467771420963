import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate  } from 'react-router-dom';

// components
import Page from '../components/Page';

import EmployeeService from 'services/employee-service';
import CustomTable from 'components/table/CustomTable';
import ServiceService from 'services/service-servise';
import { map } from 'lodash';
import Iconify from 'components/Iconify';
import { IconButton } from '@mui/material';

const INFO = {
  title: "",
  new : "Yeni xidmət",
  edit : "Dəyiş",
  delete : "Sil",
  rows : [
    { id: 'name', label: "" },
  ],
  filters : [
    { id: 'showOnHomePage', label: "Ana səhifədə" },   
    { id: 'showOnAbout', label: "Haqqında sahifəsində" },   
    { id: 'showFooter', label: "Futerdə" },   
  ],
  actions:[ "",  
    "Ana səhifə",
    "Haqqında sahifəsində",
    "Futerdə",
  ],
  avatar : 'name'
}

export default function Service() {  
  const [data, setData] = useState([]);
  let navigate = useNavigate();

  const handleData=()=>{
    ServiceService.getAll().then(d=>{
      if(!d.hasError) {
        setData(d.data)
        console.log(d.data)
      } 
    });
  }

  useEffect(() => {
    handleData()        
  }, []);

  const handlePosition =  async (id) => {    
    const res = await ServiceService.position(id)
    if(!res.hasError) handleData()
  }

  const getData =()=>{
    return map(data,(item,index)=>({...item,_actions:[ 
      index !== 0 ? <IconButton sx={{ color: 'text.secondary' }} onClick={()=>handlePosition(item.id)}> 
        <Iconify icon="akar-icons:arrow-down" rotate={2} width={20} height={20} />   
      </IconButton> : null,    
      item.showOnHomePage ? <Iconify sx={{ color: 'text.secondary' }} icon="ep:success-filled" width={20} height={20} />:null,
      item.showOnAbout ? <Iconify sx={{ color: 'text.secondary' }} icon="ep:success-filled" width={20} height={20} />:null,
      item.showFooter ? <Iconify sx={{ color: 'text.secondary' }} icon="ep:success-filled" width={20} height={20} />:null,
    ]}))
  }

  const handleEdit = (id) => {
    if(id == undefined) navigate(`/dashboard/services/add`);
    else navigate(`/dashboard/services/${id}/edit`);
  };

  const handleDelete =(id) => {
    ServiceService.del(id).then(res=>{
      if(!res.hasError) handleData()
    });
  };


  return (
    <Page title="Xidmətlər">
      <CustomTable info={INFO} onDelete= {handleDelete} onEdit={handleEdit} data={getData()} />             
    </Page>
  );
}
