import PropTypes from 'prop-types';
// material
import { TableRow, TableCell, TableHead, Button } from '@mui/material';
import { map } from 'lodash';

TableListHead.propTypes = { 
  headLabel: PropTypes.array,
};
export default function TableListHead({ headLabel,actions,filters,filter,setFilter}){
  return (
    <TableHead>
      <TableRow>
        {map(filters,(f,index)=>( 
          <Button key={index} sx={{ color: 'text.secondary', margin: "15px 10px", marginLeft: index == 0 ? "20px":"0" }} size="small" variant={ filter == f.id? "outlined": 'text'}
          onClick={()=>{ filter == f.id ? setFilter() : setFilter(f.id)}}>          
           {f.label}
          </Button>
        ))} 
      </TableRow>
      
      <TableRow>        
        {headLabel.map((headCell,index) => (
          <TableCell
            key={index}           
          >           
            {headCell.label}
          </TableCell>
        ))}
       {
        map(actions,(action,index)=>(
        <TableCell key={index}>
          {action}
        </TableCell>))
        } 
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}