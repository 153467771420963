// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Ana səhifə',
    path: '/dashboard/home',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Bizim Tariximiz',
    path: '/dashboard/about',
    icon: getIcon('flat-color-icons:about'),
  },
  {
    title: 'Əməkdaşlar',
    path: '/dashboard/employees',
    icon: getIcon('clarity:employee-group-line'),
  },
  {
    title: 'Layihələr',
    path: '/dashboard/projects',
    icon: getIcon('ic:outline-home-repair-service'),
  },
  {
    title: 'Xidmətlər',
    path: '/dashboard/services',
    icon: getIcon('eos-icons:service-instance'),
  },
  {
    title: 'Səhifələr',
    path: '/dashboard/static-pages',
    icon: getIcon('iconoir:multiple-pages'),
  },
  {
    title: 'Xəbərlər',
    path: '/dashboard/blog',
    icon: getIcon('iconoir:multiple-pages'),
  },
  {
    title: 'Ayarlar',
    path: '/dashboard/settings',
    icon: getIcon('ant-design:setting-twotone'),
  },
  {
    title: 'Kalkulator',
    path: '/dashboard/calculator',
    icon: getIcon('akar-icons:calculator'),
  },
  {
    title: 'Foto Galeriya',
    path: '/dashboard/gallery',
    icon: getIcon('clarity:image-gallery-line'),
  },
  {
    title: 'Uğurlu tələbələr',
    path: '/dashboard/students',
    icon: getIcon('clarity:video-gallery-line'),
  }, 
  {
    title: 'Istifadəçilər',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
 
 
];

export default navConfig;
