import PropTypes from 'prop-types';
import { useCallback, useRef, useEffect, useMemo, useState, createElement } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import "../../../modules/custom-validation/EditorValidation"
// form
import { useForm,useWatch , Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button, FormControlLabel } from '@mui/material';

// components
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar, RHFEditor } from '../../../components/hook-form';


import FixedMenu from 'components/FixedMenu';
import StaticPageService from 'services/static-page-service';
import { find, findIndex, keys } from 'lodash';


StaticPageForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};

export default function StaticPageForm({ currentData }) {
  const [lng, setLng] = useState('az')

  const translates = [ {
    id: 0,
    lng: "az",
    name: "",
    title: "",
    description: ""
  },
  {
    id: 0,
    lng: "ru",
    name: "",
    title: "",
    description: ""
  }]

  console.log(currentData,"currentData")

  const defaultValues = useMemo(
    () => ({
      id: currentData.id,
      pageId: currentData?.pageId||'',
      mainPageId: currentData?.mainPageId || '',
      mainImage:currentData?.mainImage || '',
      mainImageName: currentData?.mainImageName || '',
      show: currentData?.show || true,
      showFooter: currentData?.showFooter || false,
      hasPage: true,
      translates: currentData.translates
    }),
    [currentData]
  );

  const videoSchema = Yup.object().shape({   
    pageId: Yup.string().nullable().required("Format duzgun deyil"),
    mainImage: Yup.mixed().test('required', 'Şəkil vacibdir', (value) => value !== ''),
    translates:  Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().nullable()
          .required("Başliq vacibdir"),
        description: Yup.string().nullable()
          .requiredEditor("Mətn vacibdir")
      })
    )
  });

  const methods = useForm({
    resolver: yupResolver(videoSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { isSubmitting,errors},
  } = methods;

  const translateMothods = useFieldArray({
    control,
    name: "translates"
  });

  useEffect(() => {
    if (currentData) {
      reset(defaultValues);
    }
  }, [currentData]);

  const translatesField = useWatch({
    control,
    name: "translates",
  });

  useEffect(() => {
    console.log('errors',errors)
    errors.translates && setLng(translatesField[findIndex(errors?.translates,a=>a!=null)].lng) 
  }, [errors]);

  const handleImage = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'mainImage',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

 

  const onSubmit = async (formData) => {
    console.log(formData, 'submit')
    await StaticPageService.editPage(formData)

  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FixedMenu setLng={setLng} lng={lng} />     
      <Card sx={{ p: 3 }}>
         <RHFUploadAvatar 
            sx={{width: 100, height:100, marginBottom:"10px" }}
            name="mainImage"               
            onChange={handleImage}                
          />                  
          <RHFTextField name={'pageId'} sx={{ marginBottom:"10px" }} label={"Səhifə yolu"}  />          
        
         {translateMothods.fields.map((item, index) =>          
            <RHFTextField key={index} label={"Başlıq"} sx={{ display: item.lng == lng ? "block" : "none" }} name={`translates.${index}.title`}  />          
        )}
         <RHFSwitch
          name="showFooter"
          labelPlacement="start"
          label={
            <>                  
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Footerdə göstər
              </Typography>
            </>
          }
          sx={{ mx: 0, marginLeft:"10px"}}
        />


        {translateMothods.fields.map((item, index) =>
          <Box key={index} sx={{ display: item.lng == lng ? "block" : "none" }} >
            <RHFEditor name={`translates.${index}.description`} nameEditor={`translates${index}description`} />
          </Box>
        )}
        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Yadda saxla
          </LoadingButton>
        </Stack>
      </Card>     
    </FormProvider>
  );
}
