import PropTypes from 'prop-types';
import { Button, } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

DeleteDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleDelete: PropTypes.func
};

export default function DeleteDialog({open,handleClose,handleDelete}) {

  return ( 
       <Dialog
        maxWidth='xs'
        open={open}
        onClose={handleClose}
      >
        <DialogContent>       
        <DialogContentText>
          Bu məlumatı silmək istədiyinizdən əminsiniz?   
        </DialogContentText>
        <DialogActions>
          <Button size="small" onClick={handleDelete} >Sil</Button>
        </DialogActions>     
        </DialogContent>
        
      </Dialog>
  );
}
