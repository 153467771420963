import { useParams } from 'react-router-dom';
import { useState,useEffect } from 'react';

import { Box, Container } from '@mui/material';

import Page from '../components/Page';

import CustomTable from 'components/table/CustomTable';
import HomeForm from 'sections/@dashboard/home/HomeForm';
import HomeService from 'services/home-service';
import { find } from 'lodash';
import InfoForm from 'sections/@dashboard/home/InfoForm';
import FixedMenu from 'components/FixedMenu';

const INFO = {
  title: "İnfo",
  new : "Yeni info",
  edit: "Dəyis",
  delete : "Sil",
  rows : [
    { id: 'text', label: "  " },
  ],
  avatar : 'image'
}

const IMAGE = {
  title: "Partner şəkilləri",
  new : "Yeni şəkil",
  delete : "Sil",
  rows : [
    { id: 'imageName', label: "  " },
  ],
  avatar : 'image'
}

export default function Home() {
  const [currentData,setData] = useState();
  const [images, setImages] = useState([])
  const [currentInfo, setCurrentInfo] = useState()
  const [open,setOpen] = useState(false)
  const [infos, setInfos] = useState([])
  const [lng, setLng] = useState('az')


  const handleData=()=>{
    HomeService.getEdit().then(d=>{
      if(!d.hasError) {
        setData(d.data)       
      } 
    }); 
  }

  const handleImages=()=>{
    HomeService.getPartnerImages().then(d=>{
      if(!d.hasError) {
        setImages(d.data)       
      } 
    }); 
  }

  const handleInfos=()=>{
    HomeService.getInfo().then(d=>{
      if(!d.hasError) {
        setInfos(d.data) 
        console.log(d.data)     
      } 
    }); 
  }

  const handleEditInfo = (id) => {
    setCurrentInfo(id ? find(infos, i=> i.id == id):null)
    setOpen(true) 
  }

  const handleDeleteInfo = async (Id) => {
    const res = await HomeService.delInfo(id)
    if(!res.hasError) handleInfos()
  };

  const handleAddImage = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      console.log(file,'files')

      const res = await HomeService.addPartnerImage(file)
      if(!res.hasError) handleImages()
    };
  }

  const handleDeleteImage = async (imageId) => {
    const res = await HomeService.delPartnerImage(imageId)
    if(!res.hasError) handleImages()
  };

  const handleClose =()=>{
    setOpen(false);
    handleInfos()
  }

  useEffect(() => {    
    handleData()
    handleImages()
    handleInfos()
  }, []);


  return (
    <Page title="Kateqoriyalar">
      <FixedMenu setLng={setLng} lng={lng} />
      <InfoForm currentData={currentInfo} isEdit={currentInfo != null} lng={lng} setLng={setLng} onClose={handleClose} open={open} />
      <Container maxWidth={false}>       
        <HomeForm currentData={currentData} lng={lng} setLng={setLng}/>
      </Container>
      <Box height={30}></Box>      
      <CustomTable info={INFO} onDelete= {handleDeleteInfo} onEdit={handleEditInfo} data={infos || []} />
      <Box height={30}></Box>      
      <CustomTable info={IMAGE} onDelete= {handleDeleteImage} onEdit={handleAddImage} data={images || []} />
    </Page>
  );
}

