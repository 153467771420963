import PropTypes from 'prop-types';
import { useCallback, useRef, useEffect, useMemo, useState, createElement } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { useForm,useWatch, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button, FormControlLabel } from '@mui/material';

import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import StaticPageService from 'services/static-page-service';
import FixedMenu from 'components/FixedMenu';
import { findIndex } from 'lodash';
import SettingService from 'services/setting-service';
import HomeService from 'services/home-service';



InfoForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};

export default function InfoForm({ currentData, isEdit, onClose, open,setLng, lng}) {
 
  const translates = [ {
    id: 0,
    lng: "az",
    text: "",
  },
  {
    id: 0,
    lng: "ru",
    text: "",
  }]

  const defaultValues = useMemo(
    () => ({
      id: currentData?.id || 0,
      image: currentData?.image || "",
      imageName: currentData?.imageName || "",
      translates: currentData?.translates?.length > 0 ? currentData?.translates:translates      
    }),
    [open]
  );

  const emailFormSchema = Yup.object().shape({
    image: Yup.mixed().test('required', 'Səkil vacibdir', (value) => value !== ''),
    translates:  Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.string().nullable()
          .required("Mətn vacibdir")       
      })
    ) 
  });

  const methods = useForm({
    resolver: yupResolver(emailFormSchema),
    defaultValues,
  });

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting, errors},
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [open]);
  
  const translatesField = useWatch({
    control,
    name: "translates",
  });

  useEffect(() => {
    errors.translates && setLng(translatesField[findIndex(errors?.translates,a=>a!=null)].lng) 
  }, [errors]);

  const handleImage = (acceptedFiles,field) => {
    const file = acceptedFiles[0];
    if (file) {
      setValue(
        field,
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
    }
  }

  const translateMothods = useFieldArray({
    control,
    name: "translates"
  });

  const onSubmit = async (data) => {

   const res = await HomeService.editInfo(data)

   res.hasError || onClose();    
  }

  return (
    <Dialog
      maxWidth='xs'
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <RHFUploadAvatar  
            sx={{width: 100, height:100, marginBottom:"10px" }}
            name="image"               
            onChange={(img)=>{handleImage(img,"image")}}                              
          /> 
        {translateMothods.fields.map((item, index) =>          
          <RHFTextField key={index} sx={{ display: item.lng == lng ? "block" : "none" }} name={`translates.${index}.text`}  />          
        )}

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {isEdit ? 'Yadda saxla' : 'Redatə et'}
        </LoadingButton>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}