import PropTypes from 'prop-types';
import {useRef} from 'react';


import isString from 'lodash/isString';
// @mui
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import Image from '../Image';
import Iconify from '../Iconify';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  borderRadius: '7%',
  padding: 6,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '5%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

UploadAvatar.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadAvatar({ error, file,label, onChange, sx}) {

  const hiddenFileInput = useRef(null);
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleChange=(event)=>{
    const files = event.target.files;
    onChange(files)  
  }

  return (
    <>
      <RootStyle
        sx={{  
          ...(error && {
            borderColor: 'error.light',
          }),      
          ...sx,
        }}
      >
        <DropZoneStyle 
          sx={{
            ...(!file &&{ opacity: 0.72 }),
           
          }}
        >        
          <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{display: 'none'}}  />

          {file  && <Image alt="avatar" src={isString(file) ? file : file.preview} sx={{ zIndex: 8 }} />}

          <PlaceholderStyle
            className="placeholder"
            onClick={handleClick}
            sx={{
              ...(file && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': { opacity: 0.72 },
              }),
             
            }}
          >
            <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 24, height: 24, mb: 1 }} />
            <Typography variant="caption">{file ? 'Update photo' : 'Upload photo'}</Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>

      {label && <Box sx={{textAlign: 'center', margin:'10px'}}>{label}</Box>}
    </>
  );
}
