import http from '../common/http-common'


const login = async (data) => {    
    const res = await http.post("auth/login",data);
    if(!res.hasError) setSession(res.data.accessToken)
    return res;
}

const test = () => {    
    return http.get("auth/test");    
}

const logout = () => {
    setSession()
}

const setSession = (accessToken) => {
if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
} else {
    localStorage.removeItem('accessToken');}
};

const AuthService = {   
    login,
    logout,
    test
};

export default AuthService;