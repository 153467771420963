import PropTypes from 'prop-types';
import * as Yup from 'yup';
import "../../../modules/custom-validation/EditorValidation"
import 'highlight.js'
import {  useEffect, useMemo,useState} from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card,  Stack,  Typography, Button as DefButton, FormControlLabel } from '@mui/material';

import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFEditor } from '../../../components/hook-form';

import ServiceItemService from 'services/service-item-service';
import FixedMenu from 'components/FixedMenu';
import { findIndex } from 'lodash';

// ----------------------------------------------------------------------

ServiceItemNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};


export default function ServiceItemNewForm({ isEdit, currentData, serviceId}) {
  const navigate = useNavigate();

  const [lng,setLng] = useState('az')

  const serviceSchema = Yup.object().shape({    
    translates:  Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().nullable()
          .required("Ad vacibdir"),
        text: Yup.string().nullable()
          .requiredEditor("Mətn vacibdir")
      })
    )
    .required("Go out! Make your life enjoyable!")
  });

  const translates = [
  {
    id:0,
    lng :'az',
    name :'',  
    text:''
  },
  {
    id:0,
    lng :'ru',
    name :'',
    text:''
  }]

  const defaultValues = useMemo(
    () => ({
      id: currentData?.id || 0,
      serviceId : serviceId,   
      root : currentData?.root || '',   
      translates: currentData?.translates?.length > 0 ? currentData?.translates: translates       
    }),
    [currentData]
  );

  const methods = useForm({
    resolver: yupResolver(serviceSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting ,errors},
  } = methods;

  const {
    fields    
  } = useFieldArray({
    control,
    name: "translates"
  });

  useEffect(() => {
    if (isEdit && currentData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, currentData]);

  
  const translatesField = useWatch({
    control,
    name: "translates",
  });

  useEffect(() => {
    errors.translates && setLng(translatesField[findIndex(errors?.translates,a=>a!=null)].lng) 
  }, [errors]);

  const onSubmit = async (formData) => {
    console.log(formData)        
    const a = await ServiceItemService.edit(formData)

    if(!a.hasError) navigate(`/dashboard/services/${serviceId}/edit`)    
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>     
    <FixedMenu setLng={setLng} lng={lng}/>
      <Card sx={{ p: 3 }}>
        <Box > 
          <Box mb={"10px"}>
            {fields.map((item, index) =>                
              <RHFTextField sx={{ display:item.lng == lng? "inline-flex":"none" }} key={item.id} name={`translates.${index}.name`} label={'Ad'} />                
            )} 
          </Box>    
          <Box mb={"10px"}>
              <RHFTextField  name={'root'} label={'Sehife yolu'} />                
          </Box>   
                                   
          {fields.map((item, index) => 
            <Box  key={item.id}  sx={{ display:item.lng == lng? "block":"none"}}>              
              <RHFEditor name={`translates.${index}.text`} nameEditor={`translates${index}text`} />
            </Box>
          )}
        </Box> 

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {!isEdit ? 'Yadda saxla' : 'Redatə et'}
          </LoadingButton>
        </Stack>       
      </Card>
    </FormProvider>
  );
}
