import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo,useState} from 'react';
import isString from 'lodash/isString';
// form
import { useForm, Controller,useFieldArray } from 'react-hook-form';
// @mui
import { Box, Card, Grid, Stack, Switch, Typography, Button, FormControlLabel, Checkbox } from '@mui/material';

// components
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar, RHFEditor, RHFCheckbox } from '../../../components/hook-form';

import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import './PageContentForm.css';

// ----------------------------------------------------------------------


PageContentForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};


export default function PageContentForm({lng, methods, field, remove, move, contentIndex}) {

  const {control, setValue} = methods;

  const [checked,setChecked] = useState()

  const [type, setType] = useState('');

  useEffect(() => {
    if(checked == undefined) setChecked(field.reverse)
    if(type == '') setType(field.type)   
  },[checked,type]);

  const {fields} = useFieldArray({
    control,
    name: `contents.${contentIndex}.translates`
  });

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file) {
      setValue(
        `contents.${contentIndex}.image`,
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
    }
  }

  const handleSelect = (event) => {
    setType(event.target.value);
    setValue(
      `contents.${contentIndex}.type`,
      event.target.value
    )
  };

  const handleCheckbox = (event) => {  
    setChecked( event.target.checked)
    setValue(
      `contents.${contentIndex}.reverse`,
      event.target.checked
    )
  }

  return (
    <Box className='content-box'>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={type}
          onChange={handleSelect}
          autoWidth
          label="Type"
        >
          <MenuItem value={"horizontal"}>Horizontal</MenuItem>
          <MenuItem value={'vertical'}>Vertical</MenuItem>
          <MenuItem value={'text'}>Text</MenuItem>
        </Select>
      </FormControl>

       <IconButton  color = "primary" onClick={() => remove(contentIndex)}><ClearIcon/></IconButton>
       {contentIndex > 0 ? <IconButton type="button" color = "primary"onClick={() => move(contentIndex-1,contentIndex)} ><ArrowCircleUpIcon/> </IconButton>:null}
       
       <Checkbox checked={checked} onChange={handleCheckbox} checkedIcon={<CompareArrowsIcon/>} icon={<CompareArrowsIcon/>}/>
       <Box className='content-input-box'>
        { checked && (type == "horizontal" || type == "vertical") ?<Box className={type}> <RHFUploadAvatar
          sx={{width: 100, height:100 }}
          name={`contents.${contentIndex}.image`}             
          onChange={handleDrop}                
        /> </Box>: null}
        <Box className={`content-editor ${type}`}>
          { type != '' ? fields.map((item, index) => 
            <Box  key={index}  sx={{ display:item.lng == lng? "block":"none" }}>
              <RHFEditor name={`contents.${contentIndex}.translates.${index}.content`} nameEditor={`contents${field._id}t${index}`} /> 
            </Box>
          ) : null}
        </Box> 
        { !checked && (type == "horizontal" || type == "vertical") ?<Box className={type}> <RHFUploadAvatar 
          sx={{width: 100, height:100 }}
          name={`contents.${contentIndex}.image`}             
          onChange={handleDrop}                
        /> </Box>: null} 
      </Box>
     
    </Box>     
  );
}
