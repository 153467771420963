import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import Employee from 'pages/Employee';
import EmplloyeeCreate from 'pages/EmployeeCreate';
import Service from 'pages/Service';
import ServiceCreate from 'pages/ServiceCreate';
import ServiceItemCreate from 'pages/ServiceItemCreate';
import About from 'pages/About';
import DynamicPage from 'pages/DynamicPage';
import DynamicPageCreate from 'pages/DynamicPageCreate';
import Project from 'pages/Project';
import ProjectCreate from 'pages/ProjectCreate';
import Gallery from 'pages/Gallery';
import GalleryCategoryCreate from 'pages/GalleryCategoryCreate';
import Students from 'pages/Students';
import StaticPage from 'pages/static-page/StaticPage';
import StaticPageEdit from 'pages/StaticPageEdit';
import Settings from 'pages/setting/Setting';
import Calculator from 'pages/Calculator';
import CalculatorCreate from 'pages/calculator/CalculatorCreate';
import Blog from 'pages/Blog';
import BlogCreate from 'pages/BlogCreate';
import Home from 'pages/Home';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'home', element: <Home /> },
        { path: 'user', element: <User /> },
        { path: 'employees', element: <Employee /> },
        { path: 'employees/edit/:id', element: <EmplloyeeCreate /> },
        { path: 'employees/add', element: <EmplloyeeCreate /> },
        { path: 'blog', element: <Blog/> },
        { path: 'blog/:id/edit', element: <BlogCreate /> },
        { path: 'blog/add', element: <BlogCreate /> },
        { path: 'services', element: <Service /> },
        { path: 'services/add', element: <ServiceCreate /> },
        { path: 'services/:id/edit', element: <ServiceCreate /> },        
        { path: 'service-items/add', element: <ServiceItemCreate /> },
        { path: 'services/:serviceId/item/add', element: <ServiceItemCreate /> },
        { path: 'services/:serviceId/item/:id/edit', element: <ServiceItemCreate /> },
        { path: 'pages', element: <DynamicPage /> },
        { path: 'pages/add', element: <DynamicPageCreate /> },
        { path: 'pages/:id/edit', element: <DynamicPageCreate /> },
        { path: 'projects', element: <Project /> },
        { path: 'projects/add', element: <ProjectCreate /> },
        { path: 'projects/:id/edit', element: <ProjectCreate /> },
        { path: 'gallery', element: <Gallery /> },
        { path: 'calculator', element: <Calculator /> },
        { path: 'calculator/add', element: <CalculatorCreate /> },
        { path: 'calculator/:pageId/edit', element: <CalculatorCreate /> },
        { path: 'gallery-category/add', element: <GalleryCategoryCreate /> },
        { path: 'gallery-category/:id/edit', element: <GalleryCategoryCreate /> },
        { path: 'students', element: <Students/> },
        { path: 'static-pages', element: <StaticPage/> },        
        { path: 'static-pages/:id', element: <StaticPageEdit/> },
        { path: 'settings', element: <Settings/> },        
        { path: 'about', element: <About /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },       
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
