import http from '../common/http-common'

const getSetting = () => {  
    return http.get("Setting");        
}

const getEmailForms = () => {
    return  http.get("Setting/EmailForms");       
}

const editSetting = (data) => {

    const form = new FormData();     

    form.append('Id', data.id)
    form.append('Email', data.email)
    form.append('CompanyName', data.companyName)
    form.append('Facebook', data.facebook)
    form.append('Instagram', data.instagram)
    form.append('Linkedin',data?.linkedin)
    form.append('Twitter',data?.twitter)
    form.append('Phonenumber', data.phonenumber)
    form.append('EmailSendAddress', data.emailSendAddress)
    form.append('MainImage', data.mainImage)
    form.append('MainImageName',data.mainImage?.name || data.mainImageName)
    form.append('FooterImage', data.footerImage)
    form.append('FooterImageName',data.footerImage.name || data.footerImageName)
    form.append('Logo', data.logo)    
    form.append('LogoName',data.logo.name || data.logoName)
    form.append('Logo2', data.logo2)    
    form.append('Logo2Name',data.logo2.name || data.logo2Name)

    data.translates.map((i, index) => {       
        form.append(`Translates[${index}].Id`, i.id)
        form.append(`Translates[${index}].Address`, i.address || "")
        form.append(`Translates[${index}].FooterDescription`, i.footerDescription || "")
        form.append(`Translates[${index}].Lng`, i.lng)
    })

    return http.put("Setting", form,{ "Content-Type": "multipart/form-data" })
}

const editEmailForm = (data) => {
    return data.id == 0 ? 
        http.post('Setting/EmailForm',data):
        http.put('Setting/EmailForm',data)
}

const delEmailForm = (id)=>{
    return http.del(`Setting/EmailForm/${id}`)
}

const SettingService = {
    getSetting,
    getEmailForms,
    editSetting,
    editEmailForm,
    delEmailForm
};

export default SettingService;