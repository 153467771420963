import http from '../common/http-common'

const getAll = () => {
    return http.get("projects");
}

const getEdit = (id) => {
    console.log(id,'id')
    return id == undefined ?
        http.get("projects/add") :
        http.get(`projects/${id}/edit`);
}

const position = (id) => {    
    return http.get(`projects/position/${id}`);  
}

const edit = (data) => {

    const form = new FormData();

    form.append('Id', data.id || 0)
    form.append('Image', data.image)
    form.append('ImageName', data?.image?.name || data?.imageName)
    form.append('StartDate',new Date(data.startDate).toISOString())
    form.append('EndDate',data.ended ? new Date(data.endDate).toISOString() : '')
    form.append('Ended', data.ended)
    form.append('ShowOnHomePage',data.showOnHomePage)

    data.translates.map((i, index) => {
        form.append(`Translates[${index}].Id`, i.id || 0)
        form.append(`Translates[${index}].Name`, i.name || "")
        form.append(`Translates[${index}].Lng`, i.lng)
    })

    return data.id == 0 ?
        http.post("projects", form, { "Content-Type": "multipart/form-data" }) :
        http.put("projects", form, { "Content-Type": "multipart/form-data" })
}

const del = (id) => {    
    return http.del("projects/"+id);  
}

const ProjectService = {
    getAll,   
    getEdit,
    edit,
    del,
    position
};

export default ProjectService;