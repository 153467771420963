import PropTypes from 'prop-types';
import { useCallback, useRef, useEffect, useMemo, useState, createElement } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import "../../../modules/custom-validation/EditorValidation"
// form
import { useForm, Controller, useWatch, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button, FormControlLabel } from '@mui/material';

// components
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar, RHFEditor } from '../../../components/hook-form';


import FixedMenu from 'components/FixedMenu';
import StudentService from 'services/student-service';
import { findIndex, map } from 'lodash';


StudentNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};

export default function StudentNewForm({ currentData }) {
  const [lng, setLng] = useState('az')

  const studentSchema = Yup.object().shape({
    translates: Yup.array()
    .of(
      Yup.object().shape({     
        text: Yup.string().nullable()
          .requiredEditor("Mətn vacibdir")
      })
    )
  });


  const translates = [{
    id:0,
    lng: 'az',
    text: '',
  },
  {
    id:0,
    lng: 'ru',
    text: '',
  }]

  const defaultValues = useMemo(
    () => ({
      translates:  currentData?.translates
    }),
    [currentData?.translates]
  );

  const methods = useForm({
    resolver: yupResolver(studentSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors},
  } = methods;

  const translateMothods = useFieldArray({
    control,
    name: "translates"
  });

  useEffect(() => {
    if (currentData) {
      reset(defaultValues);
    }
  }, [currentData]);

  const translatesField = useWatch({
    control,
    name: "translates",
  });

  useEffect(() => {
    errors.translates && setLng(translatesField[findIndex(errors?.translates,a=>a!=null)].lng) 
  }, [errors]);


  const onSubmit = async (formData) => {
    console.log(formData)
    const a = await StudentService.editText(formData.translates)
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FixedMenu setLng={setLng} lng={lng} />     
      <Card sx={{ p: 3 }}>
        {console.log(currentData,defaultValues,"test")}
        {map(translateMothods.fields,(item, index) =>
          <Box key={index} sx={{ display: item.lng == lng ? "block" : "none" }} >
            <RHFEditor name={`translates.${index}.text`} nameEditor={`translates${index}text`} />
          </Box>
        )}
        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Yadda saxla
          </LoadingButton>
        </Stack>
      </Card>     
    </FormProvider>
  );
}
