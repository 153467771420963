import http from '../common/http-common'

const getAllPages = () => {    
    return http.get("CalculatorPages");  
}

const getAllInputs = (id) => {    
    return http.get("CalculatorPages/inputs/"+id);  
}

const getEdit = (id) => {
    if(id == undefined) return http.get("CalculatorPages/add");  
    return http.get(`CalculatorPages/${id}/edit`);  
}

const editPage =(data)=>{
   return data.id == 0 ?
    http.post('CalculatorPages',data): 
    http.put('CalculatorPages',data)
}

const editInput =(data)=>{
    return data.id == 0 ?
     http.post('CalculatorPages/input',data): 
     http.put('CalculatorPages/input',data)
 }

 const editOption =(data)=>{
    return data.id == 0 ?
     http.post('CalculatorPages/option',data): 
     http.put('CalculatorPages/option',data)
 }

const delPage = (id) => {    
    return http.del("CalculatorPages/"+id);  
}

const delInput = (id) => {    
    return http.del("CalculatorPages/input/"+id);  
}

const CalculatorService = {
    getAllPages,   
    getAllInputs,
    getEdit,
    editInput,
    editOption,
    editPage,
    delPage,
    delInput   
  };

export default CalculatorService;