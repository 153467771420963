import PropTypes from 'prop-types';
import { useCallback, useRef, useEffect, useMemo, useState, createElement } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { useForm,useWatch, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button,Select, FormControlLabel, MenuItem } from '@mui/material';

import { FormProvider, RHFSelect, RHFSwitch, RHFTextField } from '../../../components/hook-form';
import { findIndex } from 'lodash';
import SettingService from 'services/setting-service';
import CalculatorService from 'services/calculator-service';



InputForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};

export default function InputForm({ currentData, pageId, inputId, isEdit, onClose, open, isOption, calculationType,setLng, lng}) {
 
  const translates = [ {
    id: 0,
    lng: "az",
    name: "",
  },
  {
    id: 0,
    lng: "ru",
    name: "",
  }]

  const defaultValues = useMemo(
    () => ({
      id: currentData?.id || 0,
      inputId: currentData?.inputId || inputId,
      calculatorPageId: pageId,
      calculationType: currentData?.calculationType || calculationType,
      inputType: isOption ? "option" : currentData?.inputType || "checkbox",
      value: currentData?.value || 0,
      translates: currentData?.translates?.length > 0 ? currentData?.translates:translates    
    }),
    [open]
  );

  const emailFormSchema = Yup.object().shape({
    translates:  Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().nullable()
          .required("Ad vacibdir")       
      })
    )
 
  });

  const methods = useForm({
    resolver: yupResolver(emailFormSchema),
    defaultValues,
  });

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting, errors},
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [open]);
  
  const translatesField = useWatch({
    control,
    name: "translates",
  });

  const inputTypeValue = useWatch({
    control,
    name: "inputType",
  });

  useEffect(() => {
    errors.translates && setLng(translatesField[findIndex(errors?.translates,a=>a!=null)].lng) 
  }, [errors]);


  const translateMothods = useFieldArray({
    control,
    name: "translates"
  });

  const onSubmit = async (data) => {

   const res = isOption ? 
    await CalculatorService.editOption(data) :
    await CalculatorService.editInput(data)

   res.hasError || onClose();    
  }

  return (
    <Dialog
      maxWidth='xs'
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {translateMothods.fields.map((item, index) =>          
          <RHFTextField key={index} sx={{ display: item.lng == lng ? "block" : "none" }} name={`translates.${index}.name`} label='Ad' />          
        )}

        {!isOption && <Select
         {...register("inputType")}
          label="tip"         
          autoWidth={true}
          value={inputTypeValue}
        >
          <MenuItem value={"radio"}>Tək seçim</MenuItem>
          <MenuItem value={'checkbox'}>Multi seçim</MenuItem>
        </Select>}

        {isOption && <RHFTextField label='Dəyər' name="value"  /> }

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {!isEdit ? 'Yadda saxla' : 'Redatə et'}
        </LoadingButton>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}