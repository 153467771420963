import PropTypes from 'prop-types';
import { useCallback, useRef, useEffect, useMemo, useState, createElement } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button, FormControlLabel } from '@mui/material';

// components
import Label from '../../../components/Label';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField } from '../../../components/hook-form';
import StudentService from 'services/student-service';
import getYoutubeUrl from 'utils/youtube';
import LinearProgress from '@mui/material/LinearProgress';

let can = false;
// ----------------------------------------------------------------------

VideoNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};

export default function VideoNewForm({ currentData,isEdit, onClose, open }) {

  const [type, setType] = useState('')  
  const [uploadStatus, setUploadStatus] = useState('');
  const [progress, setProgress] = useState(0);
  const [cancel, setCancel] = useState(false);

  const buffer = 5242880;


  const defaultValues = useMemo(
    () => ({
      id: currentData?.id || 0,
      type: currentData?.type || '',
      name: currentData?.name || '',
      url: currentData?.url || '',
      file: '',
      status: currentData?.status || '',
      direction: currentData?.direction || '',
      showOnHomePage: currentData?.showOnHomePage
    }),
    [currentData]
  );

  const reg = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

  const videoSchema = Yup.object().shape({
    type: Yup.string(),   
    url: Yup.string()  
      .when("type", {
        is: 'youtube',
        then: Yup.string().matches(reg,"Format duzgun deyil")
      }),
    file: Yup.mixed().nullable().when("type", {
      is: 'video',
      then: Yup.mixed().nullable().test('required', 'Video vacibdir', (value) => value !== '')
    }), 
   
  });

  const methods = useForm({
    resolver: yupResolver(videoSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors},
  } = methods;

  useEffect(() => {
    if (currentData) {
      if(isEdit) handleVideo()
      setType(currentData?.type || '')  
      setUploadStatus('')
      setProgress(0)
      reset(defaultValues);
      setCancel(false)
      can = false;
    }
  }, [currentData]);


  const handleClose = () => {
    if(uploadStatus != "InProgress") {
      onClose()
    }
  }

  const handleVideo = () => {
    setType("video")

    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'video/mp4,video/x-m4v,video/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];

      if(file){
        setValue("file",file)
        setValue("name",file?.name)
      }
    };

  }

  let handleUpload = async(id,file)=>{    
    for (let index = 0; index < file.size; index += buffer) {

      if(can) return;
           
      setProgress((index/file.size)*100)
      const end = file.size > index + buffer ? index + buffer + 1: file.size;
      const blob = file.slice(index, end);

      console.log(blob.size)
      console.log(cancel,can)
      

      const res = await StudentService.uploadVideo({
        id: id,
        name: file?.name,
        video: blob,
        uploaded: index,
        end: end == file.size    
      }) 

      if(res?.hasError || res?.data == -1) break;
      else {
        console.log(index,res?.data)
        index = res?.data - buffer;
      }             
    }
    setUploadStatus("Done")
    setProgress(100)
  }

  const onSubmit = async (formData)=>{

    const videoData = formData;

    if(type == "youtube"){
      videoData.url = getYoutubeUrl(formData.url)
      const res = await StudentService.addVideo(formData)
      !res.hasError && onClose(true) 
    }

    if(type == "video" && !isEdit){      
     const file = new File([formData.file], formData.file?.name, { type: formData.file?.type })

     formData.file = null;

     const res = await StudentService.addVideo(formData)

     if(!res.hasError){
      setUploadStatus('InProgress')
      await handleUpload(res.data,file)
      onClose(true) 
     }
    }
    
    if(isEdit){
      setUploadStatus('InProgress')
      await handleUpload(formData.id,formData.file)
      onClose(true)
    }
  }


  const handleType = (typeName) => {
    if (typeName == "youtube") {
      setType("youtube")
      setValue('type', "youtube")
    }
    else {
      setType("video")
      setValue('type', "video")
      handleVideo()
    }
  }

  return (
    <Dialog
      maxWidth='xs'
      open={open}
      onClose={handleClose}
    >
      <DialogContent><FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

        {console.log(type,"type")}
        {type != "youtube" && <Button onClick={() => handleType("video")} sx={{ mr: 1 }} >Video</Button>}
        {type != "video" && <Button onClick={() => handleType("youtube")} >YouTube</Button>}

        {type == "youtube" &&
          <Box>
            <RHFTextField name={'url'} label={'Link'} />
          </Box>
        }

        {type == "video" &&
          <Box>
            <RHFTextField disabled = {true} name={'name'} label={'Ad'} />
          </Box>
        }

            {(type == "video" || type == "youtube") && <RHFSwitch
              name="showOnHomePage"
              labelPlacement="start"
              label={
                <>                  
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Əsas səhifədə göstər
                  </Typography>
                </>
              }
              sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
            />}

        {
          errors?.file && <Box sx={{ color:"red", margin:"10px auto" }}><label>video vacibdir</label></Box>
        }

        {progress != 0 && 
         <Box sx={{ width: '100%', marginTop:"10px",marginBottom:"10px" }}>
           <LinearProgress variant="determinate" value={progress} />
           <Button sx={{  marginTop:"10px" }} onClick={()=>{can = true}}>Cancel</Button>           
         </Box>         
        } 
                

        {type != '' && uploadStatus == '' &&
          <LoadingButton sx={{  marginTop:"10px" }} type="submit" variant="contained" loading={isSubmitting}>
            {type == "video" ? (isEdit ? "Yükləməyə davam et" : "Yüklə" ) : "Yadda Saxla"}         
          </LoadingButton>
        }
      </FormProvider>
      </DialogContent>
    </Dialog>
  );
}