import { useEffect, useState } from 'react';
import styles from './styles.module.css';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Box, Button, Card, Container, IconButton } from '@mui/material';

import Page from '../../components/Page';
import SettingService from 'services/setting-service';
import EmailForm from 'sections/@dashboard/setting/EmailForm';
import SettingForm from 'sections/@dashboard/setting/SettingForm';
import { filter, find, map } from 'lodash';
import Iconify from 'components/Iconify';
import FixedMenu from 'components/FixedMenu';
import DeleteDialog from 'components/DeleteDialog';
import { useParams } from 'react-router-dom';
import CalculatorForm from 'sections/@dashboard/calculator/CalculatorForm';
import CalculatorService from 'services/calculator-service';
import InputForm from 'sections/@dashboard/calculator/InputForm';

const CalculatorCreate = () => {
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false)
  const [inputId, setInputId] = useState()
  const [inputData, setInputData] = useState()
  const [inputFormData, setInputFormData] = useState()
  const [expanded, setExpanded] = useState(false);
  const [lng, setLng] = useState('az')
  const [deleteId, setDeleteId] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const [isOption, setOption] = useState(false)
  const [calculationType, setCalculationType] = useState("monthly")
  isOption


  const { pageId } = useParams();

  const isEdit = pageId != undefined

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  const handleData = () => {
    CalculatorService.getEdit(pageId).then(d => {
      if (!d.hasError) {
        setData(d.data)
        console.log(d.data)
      }
    });
  }

  const handleInputs = () => {
    CalculatorService.getAllInputs(pageId).then(d => {
      if (!d.hasError) {
        setInputData(d.data)
      }
    });
  }

  const handleEdit = (form, id, option, monthly) => {
    setCalculationType(monthly ? "monthly" : "yearly")
    setInputFormData(form || null)
    setInputId(id || null)
    setOption(option)
    setOpen(true)
  };

  useEffect(() => {
    handleData()
    handleInputs()
  }, [pageId]);

  const handleClose = () => {
    setOpen(false);
    handleInputs()
  }

  const handleDelete = () => {
    CalculatorService.delInput(deleteId).then(res => {
      if (!res.hasError) {
        handleInputs()
        setOpenDialog(false);
      }
    });
  };

  const handleCloseDialog = () => {
    setDeleteId()
    setOpenDialog(false);
  }

  const handleDeleteDialog = (id) => {
    setDeleteId(id)
    setOpenDialog(true);
  }

  return (
    <Page title="User: Create a new user">
      <DeleteDialog open={openDialog} handleClose={handleCloseDialog} handleDelete={handleDelete} />
      <FixedMenu setLng={setLng} lng={lng} />
      <InputForm
        setLng={setLng}
        currentData={inputFormData}
        inputId={inputId}
        pageId={pageId}
        isEdit={inputFormData != null}
        calculationType={calculationType}
        lng={lng} isOption={isOption}
        onClose={handleClose}
        open={open} />
      <Container maxWidth={false}>
        <CalculatorForm setLng={setLng} currentData={data} lng={lng} isEdit={isEdit} />
      </Container>
      <Box height={30}></Box>
      <Container maxWidth={false}>
        {isEdit && <Card>
          <Box className={styles.InputContainer}>
          <Box className={styles.AccordionContainer}>
            <Box sx={{padding:"10px"}}>Ayliq hesablama</Box>
            {map(filter(inputData, d => d?.calculationType == "monthly"), (input, index) => (
              < Box key={index}>
                <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                  <AccordionSummary className={styles.AccordionSummary}
                  >
                    <Box className={styles.TextAndButton}>
                      <Box></Box>
                      <label>{find(input.translates, a => a.lng == "az")?.name} <span>{input.inputType == 'checkbox'?"multi seçim":"tək seçim"}</span> </label>
                      <IconButton onClick={() => handleEdit(input, null, false, true)}><Iconify icon="eva:edit-outline" /></IconButton>
                      <IconButton onClick={() => handleDeleteDialog(input.id)}><Iconify icon="clarity:remove-line" /></IconButton>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className={styles.SubItems}>
                      {map(input.options, (option, subi) => (
                        <Box key={subi} className={styles.TextAndButton}>
                          <Box></Box>
                          <label>{find(option.translates, a => a.lng == "az")?.name}    {`(${option.value})`}</label>
                          <IconButton onClick={() => handleEdit(option, input.id, true, true)}><Iconify icon="eva:edit-outline" /></IconButton>
                          <IconButton onClick={() => handleDeleteDialog(option.id)}><Iconify icon="clarity:remove-line" /></IconButton>
                        </Box>))}
                      <Button onClick={() => handleEdit(null, input.id, true, true)}> Əlavə et </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>

            ))}

            <Button onClick={() => handleEdit(null, null, false, true)}> Əlavə et </Button>

          </Box>
          <Box className={styles.AccordionContainer}>
          <Box sx={{padding:"10px"}}>Illik hesablama</Box>
            {map(filter(inputData, d => d?.calculationType == "yearly"), (input, index) => (
              < Box key={index}>
                <Accordion expanded={expanded === `panel1${index}`} onChange={handleChange(`panel1${index}`)}>
                  <AccordionSummary className={styles.AccordionSummary}
                  >
                    <Box className={styles.TextAndButton}>
                      <Box></Box>
                      <label>{find(input.translates, a => a.lng == "az")?.name} <span>{input.inputType == 'checkbox'?"multi seçim":"tək seçim"}</span> </label>
                      <IconButton onClick={() => handleEdit(input, null, false, false)}><Iconify icon="eva:edit-outline" /></IconButton>
                      <IconButton onClick={() => handleDeleteDialog(input.id)}><Iconify icon="clarity:remove-line" /></IconButton>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className={styles.SubItems}>
                      {map(input.options, (option, subi) => (
                        <Box key={subi} className={styles.TextAndButton}>
                          <Box></Box>
                          <label>{find(option.translates, a => a.lng == "az")?.name}    {`(${option.value})`}</label>
                          <IconButton onClick={() => handleEdit(option, input.id, true, false)}><Iconify icon="eva:edit-outline" /></IconButton>
                          <IconButton onClick={() => handleDeleteDialog(option.id)}><Iconify icon="clarity:remove-line" /></IconButton>
                        </Box>))}
                      <Button onClick={() => handleEdit(null, input.id, true, false)}> Əlavə et </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>

            ))}

            <Button onClick={() => handleEdit(null, null, false, false)}> Əlavə et </Button>
          </Box>
          </Box>

        </Card>}

      </Container>
    </Page>
  );
}

export default CalculatorCreate;