import { Box, Card, Grid,  Switch, Typography, Button, FormControlLabel } from '@mui/material';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import {useState, useRef, useEffect} from 'react';
import IconButton from '@mui/material/IconButton';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert';


export default function VerticalIconMenu({close, onClose, children}){
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        onClose(false)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(false);
    };

    useEffect(() => {
      close == true && handleClose()
    }, [close]);

    return (
        <Box display={"inline"}>        
        <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        >
           <MoreVertIcon />
        </IconButton>

        <Menu
        id="basic-menu"
        sx={{ zIndex:999999}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children}       
      </Menu>
       
        </Box>
    );
}