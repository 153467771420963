import PropTypes from 'prop-types';
import * as Yup from 'yup';
import 'highlight.js'
import { useCallback, useEffect, useMemo,useState} from 'react';
import { useNavigate } from 'react-router-dom';

// date-fns
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// form
import { useForm,useWatch, Controller,useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box, Card, Grid, Stack, Checkbox,Switch,TextField,TextareaAutosize, Typography, Button as DefButton, FormControlLabel } from '@mui/material';

import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar, RHFEditor } from '../../../components/hook-form';
import FixedMenu from 'components/FixedMenu';
import ProjectService from 'services/project-service';
import { findIndex } from 'lodash';


ProjectNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};


export default function ProjectNewForm({ isEdit, currentData }) {
  const navigate = useNavigate();

  const [lng,setLng] = useState('az')

  const NewUserSchema = Yup.object().shape({  
    ended: Yup.boolean(),
    startDate: Yup.date().nullable().required("Baslama tarixi vacibdir"),
    image: Yup.mixed().test('required', 'Səkil vacibdir', (value) => value !== ''),
    endDate: Yup.date().nullable().when('ended', (ended, schema) => {
      return ended? schema.required("Bitmə tarixi vacibdir") : schema.nullable();
    }),
    translates:  Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().nullable()
          .required("Ad vacibdir"),      
      })
    )   
  }); 

  const translates = [{
    id: 0,
    lng :'az',
    name :'',
  },
  {
    id: 0,
    lng :'ru',
    name :'',    
  }]

  const defaultValues = useMemo(
    () => ({
      id: currentData?.id || 0,      
      image: currentData?.image || '',
      imageName: currentData?.imageName || '',
      startDate: currentData?.startDate,
      endDate: currentData?.endDate,
      ended: currentData?.ended || false,            
      showOnHomePage: currentData?.showOnHomePage,
      translates:  currentData?.translates?.length > 0 ? currentData?.translates: translates     
    }),
    [currentData]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors},
  } = methods;

  const ended = useWatch({
    control,
    name: "ended",
  });

  const {
    fields
  } = useFieldArray({
    control,
    name: "translates"
  });

  useEffect(() => {
    if (isEdit && currentData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, currentData]);

  const translatesField = useWatch({
    control,
    name: "translates",
  });

  useEffect(() => {
    errors.translates && setLng(translatesField[findIndex(errors?.translates,a=>a!=null)].lng) 
  }, [errors]);

  const onSubmit = async (formData) => {    
   
   const a = await ProjectService.edit(formData)
    
   if(!a.hasError) navigate("/dashboard/projects");
  
  };

  const handleImage = (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    }
   

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}> 
      <FixedMenu setLng={setLng} lng={lng}/>     
          <Card sx={{ p: 3 }}>
            <Box width={"50%"}> 
             {fields.map((item, index) =>                
                <RHFTextField sx={{ display:item.lng == lng? "inline-flex":"none" }} 
                key={item.id} name={`translates.${index}.name`} 
                label={`Ad`} multiline rows={3} />                
              )} 

            <RHFSwitch
              name="showOnHomePage"
              labelPlacement="start"
              label={
                <>                  
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Əsas səhifədə göstər
                  </Typography>
                </>
              }
              sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
            />

              <RHFUploadAvatar 
                sx={{width: 100, height:100 }}
                name="image"               
                onChange={handleImage}                
              />                  
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState: { error }}) => (
                  <DesktopDatePicker
                    disableFuture
                    {...field}
                    label="Başlama tarixi"
                    views={['year', 'month', 'day']}
                    inputFormat="dd/MM/yyyy"
                    format="yyyy-MM-dd HH:mm:ss"                   
                    renderInput={(params) => <TextField error={!!error} helperText={error?.message} {...params} fullWidth />}
                  />
                )}
              />
              <Controller
                name="ended"
                control={control}
                render={({ field }) => (
                  <Checkbox  {...field} checked={field.value} />
                )}
              />     
              {console.log(ended,"ended")}
             {ended &&   
              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DesktopDatePicker
                    disableFuture
                    {...field}
                    label="Bitmə tarixi"
                    views={['year', 'month', 'day']}
                    format="yyyy-MM-dd HH:mm:ss"   
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField error={!!error} helperText={error?.message} {...params} fullWidth />}
                  />
                )}
              />}                      
            </Box>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Yadda saxla' : 'Redatə et'}
              </LoadingButton>
          </Card>       
    </FormProvider>
    </LocalizationProvider>
  );
}
