import PropTypes from 'prop-types';
import {useState} from 'react'
// material
import { Button, } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../Iconify';
import { Box } from '@mui/system';

TableMoreMenu.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  id: PropTypes.number,
  info: PropTypes.object
};

export default function TableMoreMenu({onDelete,onEdit,id,info}) {

  const [open,setOpen] = useState(false)
  const [isLoading,setLoading] = useState(false)

  const handleClose =()=>{
    setOpen(false);
  }

  const handleDelete = ()=>{        
     onDelete(id)
     setOpen(false);
  }

  const handleOpen =()=>{
    setLoading(false)
    setOpen(true)
  }

  const handleEdit = ()=>{
    onEdit(id)
  }

  return (    
    <Box >
       <Dialog
        maxWidth='xs'
        open={open}
        onClose={handleClose}
      >
        <DialogContent>       
        <DialogContentText>
          Bu məlumatı silmək istədiyinizdən əminsiniz?   
        </DialogContentText>
        <DialogActions>
          <Button size="small" onClick={handleDelete} >Sil</Button>
        </DialogActions>     
        </DialogContent>
        
      </Dialog>     
     { info?.edit && 
      <Button sx={{ color: 'text.secondary',marginRight:'10px' }} size="small" onClick={handleEdit} startIcon={<Iconify icon="eva:edit-fill" width={20} height={20} />}>
          {info.edit}
      </Button>
     }   
      <Button sx={{ color: 'text.secondary' }} size="small" onClick={handleOpen} startIcon={<Iconify icon="eva:trash-2-outline" width={20} height={20} />}>
          {info.delete}
      </Button>          
    </Box>
  );
}

