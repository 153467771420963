import { Box, Card, Grid,  Switch, Typography, Button, FormControlLabel } from '@mui/material';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import {useState, useRef, useEffect} from 'react';
import IconButton from '@mui/material/IconButton';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert';



import './FixedMenu.css'

const ITEM_HEIGHT = 48;

export default function FixedMenu({ lng,setLng,setPreview}){
    const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    const handleChange = (event, newAlignment) => {
      if(newAlignment)  setLng(newAlignment);
    };
    
    return (
        <Box className='fixed-menu'>
        <ToggleButtonGroup
            color="primary"
            value={lng}
            exclusive
            onChange={handleChange}
            size="small"
            >
            <ToggleButton value="az">Az</ToggleButton>
            <ToggleButton value="ru">Ru</ToggleButton>
        </ToggleButtonGroup>
        <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        >
           <MoreVertIcon />
        </IconButton>

        <Menu
        id="basic-menu"
        sx={{ zIndex:999999}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Preview</MenuItem>
      </Menu>
       
        </Box>
    );
}