import PropTypes from 'prop-types';
import { useCallback, useRef, useEffect, useMemo, useState, createElement } from 'react';
import styles from './styles.module.css';

import * as Yup from 'yup';
import "../../../modules/custom-validation/EditorValidation"
// form
import { useForm, useWatch, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button, FormControlLabel } from '@mui/material';

// components
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar, RHFEditor } from '../../../components/hook-form';


import FixedMenu from 'components/FixedMenu';
import { find, findIndex, keys } from 'lodash';
import SettingService from 'services/setting-service';
import CalculatorService from 'services/calculator-service';
import { useNavigate } from 'react-router-dom';


CalculatorForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};

export default function CalculatorForm({ currentData, lng, setLng, isEdit }) {

  const navigate = useNavigate();

  const translates = [{
    id: 0,
    lng: "az",
    name: "",
    title: "",
    description: ""
  },
  {
    id: 0,
    lng: "ru",
    name: "",
    title: "",
    description: ""
  }]

  console.log(currentData, "currentData")

  const defaultValues = useMemo(
    () => ({
      id: currentData.id,
      root: currentData.root,  
      translates: currentData?.translates?.length > 0 ? currentData?.translates : translates
    }),
    [currentData, isEdit]
  );

  const videoSchema = Yup.object().shape({
    root: Yup.string().nullable().required("Format duzgun deyil"),
    translates: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().nullable()
            .required("Ad vacibdir"),
          title: Yup.string().nullable()
            .required("Başliq vacibdir"),
          description: Yup.string().nullable()
            .requiredEditor("Mətn vacibdir")
        })
      )
  });

  const methods = useForm({
    resolver: yupResolver(videoSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const translateMothods = useFieldArray({
    control,
    name: "translates"
  });

  useEffect(() => {
    if (isEdit && currentData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, currentData]);

  const translatesField = useWatch({
    control,
    name: "translates",
  });

  useEffect(() => {
    errors.translates && setLng(translatesField[findIndex(errors?.translates, a => a != null)].lng)
  }, [errors]);

  const onSubmit = async (formData) => {
    console.log(formData, 'submit')
    const res = await CalculatorService.editPage(formData)

    !res.hasError && !isEdit && navigate(`/dashboard/calculator/${res.data}/edit`);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {console.log(currentData, "bura;")}
      <Card sx={{ p: 3 }}>
        <Box>
          <Box className={styles.Input}>
            {translateMothods.fields.map((item, index) =>
              <RHFTextField key={index + "input"} sx={{ display: item.lng == lng ? "block" : "none" }}
                name={`translates.${index}.name`} label={"Ad"} />
            )}
            <RHFTextField name={'root'} label={"Səhifə yolu"} />
          </Box>

          {translateMothods.fields.map((item, index) =>
            <Box key={index} sx={{ display: item.lng == lng ? "block" : "none" }} >
              <RHFTextField sx={{ marginBottom: "10px"}} name={`translates.${index}.title`} label={"Başlıq"} />
              <RHFEditor name={`translates.${index}.description`} nameEditor={`translates${index}description`} />
            </Box>
          )}
        </Box>
        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Yadda Saxla
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
