import PropTypes from 'prop-types';
import { useCallback, useRef, useEffect, useMemo, useState, createElement } from 'react';
import styles from './styles.module.css';

import * as Yup from 'yup';
import "../../../modules/custom-validation/EditorValidation"
// form
import { useForm,useWatch , Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button, FormControlLabel } from '@mui/material';

// components
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar, RHFEditor } from '../../../components/hook-form';


import FixedMenu from 'components/FixedMenu';
import { find, findIndex, keys } from 'lodash';
import SettingService from 'services/setting-service';


SettingForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};

export default function SettingForm({setLng, currentData, lng }) {
  
  const translates = [ {
    id: 0,
    lng: "az",
    name: "",
    address: "",
    footerDescription: ""  
  },
  {
    id: 0,
    lng: "ru",
    name: "",
    address: "",
    footerDescription: ""  
  }]

  console.log(currentData,"currentData")

  const defaultValues = useMemo(
    () => ({
      id: currentData.id,
      email: currentData?.email || '',
      facebook: currentData?.facebook || '',
      twitter: currentData?.twitter || '',
      instagram: currentData?.instagram || '',
      linkedin: currentData?.linkedin || '',
      phonenumber:currentData?.phonenumber || '',
      emailSendAddress: currentData?.emailSendAddress || '',
      mainImage: currentData?.mainImage || '',
      mainImageName: currentData?.mainImageName || '',
      logoName: currentData?.logoName || '',
      logo: currentData?.logo || '',
      logo2Name: currentData?.logo2Name || '',
      logo2: currentData?.logo2 || '',
      companyName: currentData?.companyName || '',
      footerImage: currentData?.footerImage || '',
      footerImageName: currentData?.footerImageName || '',     
      translates: currentData?.translates?.length > 0 ? currentData?.translates: translates
    }),
    [currentData]
  );

  const videoSchema = Yup.object().shape({   
    
  });

  const methods = useForm({
    resolver: yupResolver(videoSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    control,
    handleSubmit,
    formState: { isSubmitting,errors},
  } = methods;

  const translateMothods = useFieldArray({
    control,
    name: "translates"
  });

  useEffect(() => {
    if (currentData) {
      reset(defaultValues);
    }
  }, [currentData]);

  const translatesField = useWatch({
    control,
    name: "translates",
  });

  useEffect(() => {
    errors.translates && setLng(translatesField[findIndex(errors?.translates,a=>a!=null)].lng) 
  }, [errors]);

  const handleMainImage =
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'mainImage',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    }

    const handleLogo =
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'logo',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    }

    const handleLogo2 =
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'logo2',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    }

  const handleFooterImage = 
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'footerImage',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    }
   
  const onSubmit = async (formData) => {
    console.log(formData, 'submit')
    const res = await SettingService.editSetting(formData)   
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>          
    {console.log(currentData,"bura;")}
      <Card sx={{ p: 3 }}>
        <Box className={styles.Container}>
          <Box className={styles.Inputs}>            
            <RHFTextField name={'companyName'} label={"Şirkət adi"}  />          
            <RHFTextField name={'email'} label={"Email"}  />          
            <RHFTextField name={'phonenumber'} label={"Əlaqə nömrəsi"}  />  
            <RHFTextField name={'emailSendAddress'} label={"Email göndərilmə ünvanı"}  />  
            <RHFTextField name={'facebook'} label={"Facebook"}  />          
            <RHFTextField name={'twitter'} label={"Youtube"}  />          
            <RHFTextField name={'instagram'} label={"Instagram"}  />          
            <RHFTextField name={'linkedin'} label={"Linkedin"}  />       
          </Box>
          <Box className={styles.Others}>
            <Box>
              <RHFUploadAvatar 
                sx={{width: 100, height:100 }}
                name="mainImage"               
                onChange={handleMainImage}
                label="Əsas şəkil"              
              />
              <RHFUploadAvatar 
                sx={{width: 100, height:100 }}
                name="footerImage"               
                onChange={handleFooterImage}   
                label="Footer şəkli"             
              />
               <RHFUploadAvatar 
                sx={{width: 100, height:100 }}
                name="logo"               
                onChange={handleLogo}   
                label="Rəngli Logo"             
              />

              <RHFUploadAvatar 
                sx={{width: 100, height:100 }}
                name="logo2"               
                onChange={handleLogo2}   
                label="Açıq Logo"             
              />
            </Box> 
                   
            {translateMothods.fields.map((item, index) =>
              <Box key={index} sx={{ display: item.lng == lng ? "block" : "none" }} >
                <RHFTextField label={"Ünvan"} 
                  sx={{marginBottom:"10px"}}
                  name={`translates.${index}.address`}
                  multiline rows={2} />

                <RHFEditor name={`translates.${index}.footerDescription`} nameEditor={`translates${index}footerDescription`} />
              </Box>
            )} 
          </Box>     
        </Box>
        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Yadda Saxla
          </LoadingButton>
        </Stack>
      </Card>     
    </FormProvider>
  );
}
