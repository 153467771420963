import PropTypes from 'prop-types';
import * as Yup from 'yup';
import 'highlight.js'
import { useCallback, useEffect, useMemo,useState} from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm, useWatch, Controller,useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button as DefButton, FormControlLabel } from '@mui/material';


// components
import Label from '../../../components/Label';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar, RHFEditor } from '../../../components/hook-form';
import EmployeeService from 'services/employee-service';
import ServiceService from 'services/service-servise';
import { findIndex, negate } from 'lodash';
import FixedMenu from 'components/FixedMenu';
import { margin } from '@mui/system';

// ----------------------------------------------------------------------

ServiceNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};


export default function ServiceNewForm({ isEdit, currentData }) {
  const navigate = useNavigate();

  const [lng,setLng] = useState('az')
  
  const NewUserSchema = Yup.object().shape({
    icon: Yup.mixed().test('required', 'İcon vacibdir', (value) => value !== ''),
    root: Yup.mixed().test('required', 'Root vacibdir', (value) => value !== ''),
    largeImage: Yup.mixed().test('required', 'Şəkil vacibdir', (value) => value !== ''),
    smallImage: Yup.mixed().test('required', 'Şəkil vacibdir', (value) => value !== ''),  
    translates:  Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().nullable()
          .required("Ad vacibdir"),            
      })
    )
  });

  const translates = [
  {
    id : 0,
    lng :'az',
    name :'',
  },{
    id : 0,
    lng :'ru',
    name :'',
  }]

  const defaultValues = useMemo(
    () => ({
      id: currentData?.id || 0,
      icon : currentData?.icon || '',   
      root : currentData?.root || '',   
      iconName : currentData?.iconName || '',   
      largeImage: currentData?.largeImage || '',
      largeImageName: currentData?.largeImageName || '',
      smallImage: currentData?.smallImage || '',      
      smallImageName: currentData?.smallImageName || '',      
      showOnAbout: currentData?.showOnAbout || false,      
      showFooter: currentData?.showFooter || false,    
      showOnHomePage: currentData?.showOnHomePage || false, 
      translates: currentData?.translates?.length > 0 ? currentData?.translates: translates        
    }),
    [currentData]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting ,errors},
  } = methods;

  const {
    fields    
  } = useFieldArray({
    control,
    name: "translates"
  });

  useEffect(() => {
    if (isEdit && currentData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, currentData]);

  const onSubmit = async (formData) => {
    console.log(formData)        
    const res = await ServiceService.edit(formData)
    
    if(!res.hasError && !isEdit) navigate(`/dashboard/services/${res.data}/edit`)
    console.log(res.hasError)    
  };

  const handleLargeImage = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue( 'largeImage',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const translatesField = useWatch({
    control,
    name: "translates",
  });

  useEffect(() => {
    errors.translates && setLng(translatesField[findIndex(errors?.translates,a=>a!=null)].lng) 
  }, [errors]);

  const handleSmallImage = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'smallImage',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleIcon = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'icon',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FixedMenu setLng={setLng} lng={lng}/>  
      <Card sx={{ p: 3 }}>
        <Box >          
          {fields.map((item, index) =>                
            <RHFTextField sx={{ display:item.lng == lng? "inline-flex":"none" }} key={item.id} name={`translates.${index}.name`} label={'Ad'} />                
          )}   
        </Box> 

        <RHFTextField sx={{margin:"10px 0"}} name={`root`} label={'Root'} />

        <Box sx={{display:"flex", margin:"10px 0", justifyContent: "space-around"}}>
          <RHFUploadAvatar 
            sx={{width: 100, height:100 }}
            name="largeImage"               
            onChange={handleLargeImage}
            label="Əsas şəkil"             
          />  

          <RHFUploadAvatar 
            sx={{width: 100, height:100 }}
            name="smallImage"               
            onChange={handleSmallImage}
            label="Kiçik şəkil"                
          />  

          <RHFUploadAvatar 
            sx={{width: 100, height:100 }}
            name="icon"               
            onChange={handleIcon}
            label="Ikon"            
          />               
        
        </Box>      
         
          
        <RHFSwitch
              name="showOnHomePage"
              labelPlacement="start"
              label={
                <>                  
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Əsas səhifədə göstər
                  </Typography>
                </>
              }
              sx={{ mx: 0, marginLeft:"10px"}}
            />
            <RHFSwitch
          name="showOnAbout"
          labelPlacement="start"
          label={
            <>                  
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Haqqimizda səhifəsində göstər
              </Typography>
            </>
          }
          sx={{mx: 0, marginLeft:"10px"}}
        />
         <RHFSwitch
          name="showFooter"
          labelPlacement="start"
          label={
            <>                  
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Footerdə göstər
              </Typography>
            </>
          }
          sx={{ mx: 0, marginLeft:"10px"}}
        />

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {!isEdit ? 'Yadda saxla' : 'Redatə et'}
          </LoadingButton>
        </Stack>        
      </Card>
    </FormProvider>
  );
}
