import { useParams } from 'react-router-dom';
import { useState,useEffect } from 'react';

import { Box, Container } from '@mui/material';

import Page from '../components/Page';
import GalleryCategoryService from 'services/gallery-category-service';
import CategoryNewForm from 'sections/@dashboard/gallery/CategoryNewForm';
import CustomTable from 'components/table/CustomTable';

const INFO = {
  title: "Şəkillər",
  new : "Yeni",
  delete : "Sil",
  rows : [
    { id: 'imageName', label: "  " },
  ],
  avatar : 'image'
}

export default function GalleryCategoryCreate() {
  const { id } = useParams();
  const [currentData,setData] = useState();
  const [images, setImages] = useState([])

  const handleImages=()=>{
    GalleryCategoryService.getEditImages(id).then(d=>{
      if(!d.hasError) {
        setImages(d.data)      } 
    });  
  }

  const handleData=()=>{
    GalleryCategoryService.getEdit(id).then(d=>{
      if(!d.hasError) {
        setData(d.data)       
      } 
    }); 
  }

  const handleAddImages = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.setAttribute('multiple', 'true');
    input.click();

    input.onchange = async () => {
      const files = input.files;
      console.log(files,'files')

      const res = await GalleryCategoryService.addImages(files,id)
      if(!res.hasError) handleImages()
    };
  }

  const handleDeleteImage = async (imageId) => {
    const res = await GalleryCategoryService.delImage(imageId)
    if(!res.hasError) handleImages()
  };

  const isEdit = id != undefined

  useEffect(() => {    
    handleData()
    id && handleImages()    
  }, [id]);

  return (
    <Page title="Kateqoriyalar">
      <Container maxWidth={false}>       
        <CategoryNewForm isEdit={isEdit} currentData={currentData} />
      </Container>
      <Box height={30}></Box>      
      {id && <CustomTable info={INFO} onDelete= {handleDeleteImage} onEdit={handleAddImages} data={images || []} /> }
    </Page>
  );
}

