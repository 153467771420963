import axios from "axios";
import { toast } from 'react-toastify';

const http = axios.create({
  //baseURL: "https://panel.valiyev.dev/api",
   //baseURL: "http://localhost:9974/api", 
    baseURL: "https://panel.azeraudit.com/api",
  headers: {
    "Content-type": "application/json"    
  }
});

http.interceptors.request.use(request=> {
  request.headers.Authorization =  getSession();
  return request;
});

http.interceptors.response.use(tempResponse => {
  const response={}; 

  response.data = tempResponse.data;
  response.hasError = false;
  response.error = {};

  return response;
}, error => {
  const response={};
  response.hasError = true;
  response.error = error;
  response.data = {};

  if(error.request.responseURL.includes('auth/test')) return response;
  if(error.response.status === 401) window.location = "/login";

  return response;
});

function getSession(){
  const token = localStorage.getItem('accessToken');
  if(token) return  `Bearer ${token}`;
  return '';  
}

function getLoading(isLoading){
   const el = document.getElementById("loading-page-hidden")

   el.style.display = isLoading ? "block": "none"
}

const post = async (url, request,headers) => { 

  const id = url == "students/uploadVideo" || toast.loading("Sorğu göndərilir...")  
  const res = await http.post(url, request,headers)
  url != "students/uploadVideo" ? toast.update(id, {render:!res.hasError ? "Əməliyat uğurla başa çatdı" : "Xəta baş verdi", type: res.hasError? "error": "success",autoClose: 1000, isLoading: false}):
  res?.hasError && toast.error({render: "Xəta baş verdi", autoClose: 1000}) 
  return res;
}

const put = async (url, request,headers,config) => {  
  const id = toast.loading("Sorğu göndərilir...")
  const res = await http.put(url, request,headers,config)
  toast.update(id, {render: !res.hasError ? "Əməliyat uğurla başa çatdı" : "Xəta baş verdi", type: res.hasError? "error": "success",autoClose: 1000, isLoading: false});  
  return res;
}

const del = async (url) => {
  const id = toast.loading("Sorğu göndərilir...")
  const res = await http.delete(url);    
  toast.update(id, {render: !res.hasError ? "Əməliyat uğurla başa çatdı" : "Xəta baş verdi", type: res.hasError? "error": "success",autoClose: 1000, isLoading: false});
  return res; 
}

const get = async (url) => {
  getLoading(true)
  const res = await  http.get(url);   
  getLoading(false)
  return res;
}

export default {
  http,
  get,
  post,
  put,
  del   
}