import { forEach } from 'lodash';
import http from '../common/http-common'

const getAll = () => {    
    return http.get("galleryCategories");  
}

const getEdit = (id) => {
    if(id == undefined) return http.get("galleryCategories/add");  
    return http.get(`galleryCategories/${id}/edit`);  
}

const edit =(data)=>{    
    if(data.id == 0) 
        return http.post('galleryCategories',data)
    else
        return http.put('galleryCategories',data)
}

const getEditImages = (id) => {
    console.log(id,'id')
    return http.get(`galleryCategories/${id}/Images`);
}

const addImages = (data,categoryId) => {
    console.log(data,categoryId)
    const form = new FormData();      
    forEach(data,(i, index) => {       
        form.append(`Images[${index}].Id`, 0)
        form.append(`Images[${index}].CategoryId`,categoryId)
        form.append(`Images[${index}].ImageName`, i?.name)
        form.append(`Images[${index}].Image`, i)
    })

    return http.post(`galleryCategories/Images`,form,{ "Content-Type": "multipart/form-data" });
}

const delImage = (id) => {
    return http.del(`galleryCategories/Image/${id}`);
}

const del = (id) => {    
    return http.del("galleryCategories/"+id);  
}

const GalleryCategoryService = {
    getAll,   
    getEdit,
    edit,
    getEditImages,
    delImage,
    addImages,
    del
  };

export default GalleryCategoryService;