import http from '../common/http-common'

const getEdit = () => {
    return http.get("home/edit");        
}

const getPartnerImages = () => {
    return http.get("home/partnerImages");        
}

const getInfo = () => {
    return http.get("home/info");        
}

const edit = (data) => {

    const form = new FormData();

    form.append('Id', data.id || 0) 
    form.append('MainImage', data.mainImage || '') 
    form.append('MainTextColor', data.mainTextColor || '') 
    form.append('MainImageName', data.mainImage?.name || data.mainImageName) 
    
    form.append('AboutImage1', data.aboutImage1 || '') 
    form.append('AboutImageName1', data.aboutImage1?.name || data.aboutImageName1) 

    form.append('AboutImage2', data.aboutImage2 || '') 
    form.append('AboutImageName2', data.aboutImage2?.name || data.aboutImageName2) 

    form.append('AboutImage3', data.aboutImage3 || '') 
    form.append('AboutImageName3', data.aboutImage3?.name || data.aboutImageName3) 

    form.append('About2Image', data.about2Image || '') 
    form.append('About2ImageName', data.about2Image?.name || data.about2ImageName) 

    form.append('ShowTeam', data.showTeam) 
    form.append('ShowProject', data.showProject) 
    form.append('ShowService', data.showService) 
    form.append('ShowStudent', data.showStudent) 
    form.append('ShowInfo', data.showInfo) 
    form.append('ShowAbout1', data.showAbout1) 
    form.append('ShowAbout2', data.showAbout2) 

    if(data.translates) 
    data.translates.map((i, index) => {
        form.append(`Translates[${index}].Id`, i.id || 0)       
        form.append(`Translates[${index}].Lng`, i.lng)
        form.append(`Translates[${index}].MainTitle`, i.mainTitle || "")
        form.append(`Translates[${index}].MainVideo`, i.mainVideo || "")
        form.append(`Translates[${index}].MainDescription`, i.mainDescription || "")
        form.append(`Translates[${index}].AboutTitle1`, i.aboutTitle1 || "")
        form.append(`Translates[${index}].AboutDescription1`, i.aboutDescription1 || "")
        form.append(`Translates[${index}].AboutTitle2`, i.aboutTitle2 || "")
        form.append(`Translates[${index}].AboutDescription2`, i.aboutDescription2 || "")
        form.append(`Translates[${index}].AboutTitle3`, i.aboutTitle3 || "")
        form.append(`Translates[${index}].AboutDescription3`, i.aboutDescription3 || "")
        form.append(`Translates[${index}].About2Description`, i.about2Description || "")
        form.append(`Translates[${index}].TeamDescription`, i.teamDescription || "")
        form.append(`Translates[${index}].ServiceDescription`, i.serviceDescription || "")
        form.append(`Translates[${index}].ProjectDescription`, i.projectDescription || "")
        form.append(`Translates[${index}].StudentDescription`, i.studentDescription || "")      
    })

    return http.put("home", form, { "Content-Type": "multipart/form-data" });       
}

const addPartnerImage = (data) => {

    const form = new FormData();

    form.append('Id', 0)  
    form.append('Image', data || "")  
    form.append('ImageName', data?.name)     

    return http.post("home/partnerImage", form, { "Content-Type": "multipart/form-data" });       
}

const editInfo = (data) => {

    const form = new FormData();

    form.append('Id', data.id || 0)  
    form.append('Image', data?.image || "")  
    form.append('ImageName', data?.image?.name || data.imageName) 

   if(data.translates) 
   data.translates.map((i, index) => {
        form.append(`Translates[${index}].Id`, i.id || 0)
        form.append(`Translates[${index}].Text`, i.text || "")
        form.append(`Translates[${index}].Lng`, i.lng)
    })

    return data.id == 0 ?
        http.post("home/info", form, { "Content-Type": "multipart/form-data" }) :
        http.put("home/info", form, { "Content-Type": "multipart/form-data" })
}

const delInfo = (id) => {    
    return http.del("home/info/"+id);  
}

const delPartnerImage = (id) => {    
    return http.del("home/partnerimage/"+id);  
}

const HomeService = {
    getEdit,
    getPartnerImages,
    getInfo,
    edit,
    addPartnerImage,
    editInfo,
    delInfo,
    delPartnerImage
};

export default HomeService;