import { IconButton } from '@mui/material';
import Iconify from 'components/Iconify';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import ProjectService from 'services/project-service';


import Page from '../components/Page';
import CustomTable from '../components/table/CustomTable';

const INFO = {
  title: "",
  new : "Yeni layihə",
  edit : "Dəyiş",
  delete : "Sil",
  filters : [
    { id: 'notEnded', label: "Davam edən" },  
    { id: 'ended', label: "Bitmiş" },  
    { id: 'showOnHomePage', label: "Ana səhifədə" },
  ],
  rows : [
    { id: 'name', label: "Ad" },
  ],
  actions:[ 
    "",   
    "Davam edən",
    "Bitmiş",   
    "Ana səhifə",
  ],
  avatar : 'image'
}

export default function Project() {
  const [data, setData] = useState([]);
  let navigate = useNavigate();

  const handleData=()=>{
    ProjectService.getAll().then(d=>{
      if(!d.hasError) {
        setData(d.data)
        console.log(d.data)
      } 
    });
  }

  useEffect(() => {
    handleData() 
  }, []);
  
  const handleEdit = (id) => {
    if(id == undefined) navigate(`/dashboard/projects/add`);
    else navigate(`/dashboard/projects/${id}/edit`);
  };

  const handlePosition =  async (id) => {    
    const res = await ProjectService.position(id)
    if(!res.hasError) handleData()
  }

  const handleDelete = (id) => {
    ProjectService.del(id).then(res=>{
      if(!res.hasError) handleData()
    });
  };

  const getData =()=>{
    return map(data,(item,index)=>({...item,
      notEnded: !item.ended,
       _actions:[ 
        index !== 0 ? <IconButton sx={{ color: 'text.secondary' }} onClick={()=>handlePosition(item.id)}> 
        <Iconify icon="akar-icons:arrow-down" rotate={2} width={20} height={20} />   
      </IconButton> : null,    
      !item.ended ? <Iconify sx={{ color: 'text.secondary' }} icon="ep:success-filled" width={20} height={20} />:null,    
      item.ended ? <Iconify sx={{ color: 'text.secondary' }} icon="ep:success-filled" width={20} height={20} />:null,
      
      item.showOnHomePage ? <Iconify sx={{ color: 'text.secondary' }} icon="ep:success-filled" width={20} height={20} />:null 
    ]}))
  }

  return (
    <Page title="Layihələr">
      <CustomTable info={INFO} onDelete= {handleDelete} onEdit={handleEdit} data={getData()} />             
    </Page>
  );
}

