import http from '../common/http-common'

const getAll = () => {
    return http.get("services");
}

const getEdit = (id) => {
    console.log(id,'id')
    return id == undefined ?
        http.get("services/add") :
        http.get(`services/${id}/edit`);
}

const position = (id) => {    
    return http.get(`services/position/${id}`);  
}

const edit = (data) => {

    const form = new FormData();

    form.append('Id', data.id || 0)
    form.append('Root', data.root || "")
    form.append('Icon', data.icon || "")
    form.append('IconName', data.icon?.name || data.iconName)
    form.append('LargeImage', data.largeImage || "")
    form.append('LargeImageName', data.largeImage?.name || data.largeImageName)
    form.append('SmallImage', data.smallImage)
    form.append('SmallImageName', data.smallImage?.name || data.smallImageName)
    form.append('ShowOnAbout', data.showOnAbout)
    form.append('ShowFooter', data.showFooter)
    form.append('ShowOnHomePage',data.showOnHomePage)

    data.translates.map((i, index) => {
        form.append(`Translates[${index}].Id`, i.id)
        form.append(`Translates[${index}].Name`, i.name || "")
        form.append(`Translates[${index}].Lng`, i.lng)
    })

    return data.id == 0 ?
        http.post("services", form, { "Content-Type": "multipart/form-data" }) :
        http.put("services", form, { "Content-Type": "multipart/form-data" })
}

const del = (id) => {    
    return http.del("services/"+id);  
}

const ServiceService = {
    getAll,   
    getEdit,
    edit,
    del,
    position
};

export default ServiceService;