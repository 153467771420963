export const Symbols = [
    {
        category: 'Stars Symbols',
        symbols: [
            {
                title: 'Black Star',
                value: '★'
            },
            {
                title: 'White Star',
                value: '☆'
            },
            {
                title: 'Star of David',
                value: '✡'
            },
            {
                title: 'Black Four Pointed Star',
                value: '✦'
            },
            {
                title: 'White Four Pointed Star',
                value: '✧'
            },
            {
                title: 'Stress Outlined White Star',
                value: '✩'
            },
            {
                title: 'Circled White Star',
                value: '✪'
            },
            {
                title: 'Open Centre Black Star',
                value: '✫'
            },
            {
                title: 'Black Centre White Star',
                value: '✬'
            },
            {
                title: 'Outlined Black Star',
                value: '✭'
            },
            {
                title: 'Heavy Outlined Black Star',
                value: '✮'
            },
            {
                title: 'Pinwheel Star',
                value: '✯'
            },
            {
                title: 'Shadowed White Star',
                value: '✰'
            },
            {
                title: 'Asterism',
                value: '⁂'
            },
            {
                title: 'Low Asterisk',
                value: '⁎'
            },
            {
                title: 'Two Asterisks Aligned Vertically',
                value: '⁑'
            },
            {
                title: 'Four Teardrop-Spoked Asterisk',
                value: '✢'
            },
            {
                title: 'Four Balloon-Spoked Asterisk',
                value: '✣'
            },
            {
                title: 'Heavy Four Balloon-Spoked Asterisk',
                value: '✤'
            },
            {
                title: 'Four Club-Spoked Asterisk',
                value: '✥'
            },
            {
                title: 'Heavy Asterisk',
                value: '✱'
            },
            {
                title: 'Open Centre Asterisk',
                value: '✲'
            },
            {
                title: 'Eight Spoked Asterisk',
                value: '✳'
            },
            {
                title: 'Eight Pointed Black Star',
                value: '✴'
            },
            {
                title: 'Eight Pointed Pinwheel Star',
                value: '✵'
            },
            {
                title: 'Six Pointed Black Star',
                value: '✶'
            },
            {
                title: 'Eight Pointed Rectilinear Black Star',
                value: '✷'
            },
            {
                title: 'Heavy Eight Pointed Rectilinear Black Star',
                value: '✸'
            },
            {
                title: 'Twelve Pointed Black Star',
                value: '✹'
            },
            {
                title: 'Sixteen Pointed Asterisk',
                value: '✺'
            },
            {
                title: 'Teardrop-Spoked Asterisk',
                value: '✻'
            },
            {
                title: 'Open Centre Teardrop-Spoked Asterisk',
                value: '✼'
            },
            {
                title: 'Heavy Teardrop-Spoked Asterisk',
                value: '✽'
            },
            {
                title: 'Six Petalled Black and White Florette',
                value: '✾'
            },
            {
                title: 'Black Florette',
                value: '✿'
            },
            {
                title: 'White Florette',
                value: '❀'
            },
            {
                title: 'Eight Petalled Outlined Black Florette',
                value: '❁'
            },
            {
                title: 'Circled Open Centre Eight Pointed Star',
                value: '❂'
            },
            {
                title: 'Heavy Teardrop-Spoked Pinwheel Asterisk',
                value: '❃'
            },
            {
                title: 'Sparkle',
                value: '❇'
            },
            {
                title: 'Heavy Sparkle',
                value: '❈'
            },
            {
                title: 'Balloon-Spoked Asterisk',
                value: '❉'
            },
            {
                title: 'Eight Teardrop-Spoked Propeller Asterisk',
                value: '❊'
            },
            {
                title: 'Heavy Eight Teardrop-Spoked Propeller Asterisk',
                value: '❋'
            },
            {
                title: 'Snowflake',
                value: '❄'
            },
            {
                title: 'Heavy Chevron Snowflake',
                value: '❆'
            },
            {
                title: 'Tight Trifoliate Snowflake',
                value: '❅'
            },
            {
                title: 'Star Operator',
                value: '⋆'
            },
            {
                title: 'Star Equals',
                value: '≛'
            },
            {
                title: 'Canadian Syllabics Tth',
                value: 'ᕯ'
            },
            {
                title: 'Open Centre Asterisk',
                value: '✲'
            },
            {
                title: 'Tibetan Sign Rdel Nag Gsum',
                value: '࿏'
            },
            {
                title: 'Combining Cyrillic Ten Millions Sign',
                value: '꙰ '
            },
            {
                title: 'Arabic Start of Rub El Hizb',
                value: '۞'
            },
            {
                title: 'White Small Star',
                value: '⭒'
            },
            {
                title: 'Apl Functional Symbol Circle Star',
                value: '⍟'
            },
            {
                title: 'Medium Star Emoji',
                value: '⭐'
            },
            {
                title: 'Shooting Star Emoji',
                value: '🌠'
            },
            {
                title: 'Glowing Star Emoji',
                value: '🌟'
            },
            {
                title: 'Dizzy Symbol Emoji',
                value: '💫'
            },
            {
                title: 'Sparkles Emoji',
                value: '✨'
            },
            {
                title: 'Night with Stars Emoji',
                value: '🌃'
            },
            {
                title: 'Six Pointed Star with Middle Dot Emoji',
                value: '🔯'
            }
        ]
    },
    {
        category: 'Copyright, Trademark, Office & Law Symbols',
        symbols: [
            {
                title: 'Copyright Sign',
                value: '©'
            },
            {
                title: 'Registered Sign',
                value: '®'
            },
            {
                title: 'Trade Mark Sign',
                value: '™'
            },
            {
                title: 'Service Mark',
                value: '℠'
            },
            {
                title: 'Telephone Sign',
                value: '℡'
            },
            {
                title: 'Sound Recording Copyright',
                value: '℗'
            },
            {
                title: 'Per Ten Thousand Sign',
                value: '‱'
            },
            {
                title: 'Numero Sign',
                value: '№'
            },
            {
                title: 'Account Of',
                value: '℀'
            },
            {
                title: 'Addressed To the Subject',
                value: '℁'
            },
            {
                title: 'Care Of',
                value: '℅'
            },
            {
                title: 'Cada Una',
                value: '℆'
            },
            {
                title: 'Aktieselskab',
                value: '⅍'
            },
            {
                title: 'Ascending Node',
                value: '☊'
            },
            {
                title: 'Black Telephone',
                value: '☎'
            },
            {
                title: 'White Telephone',
                value: '☏'
            },
            {
                title: 'Keyboard',
                value: '⌨'
            },
            {
                title: 'Upper Blade Scissors',
                value: '✁'
            },
            {
                title: 'Black Scissors',
                value: '✂'
            },
            {
                title: 'Lower Blade Scissors',
                value: '✃'
            },
            {
                title: 'White Scissors',
                value: '✄'
            },
            {
                title: 'Telephone Location Sign',
                value: '✆'
            },
            {
                title: 'Tape Drive',
                value: '✇'
            },
            {
                title: 'Airplane Emoji',
                value: '✈'
            },
            {
                title: 'Envelope',
                value: '✉'
            },
            {
                title: 'Lower Right Pencil',
                value: '✎'
            },
            {
                title: 'Pencil',
                value: '✏'
            },
            {
                title: 'Upper Right',
                value: '✐'
            },
            {
                title: 'White Nib',
                value: '✑'
            },
            {
                title: 'Black Nib',
                value: '✒'
            },
            {
                title: 'Per Mille Sign',
                value: '‰'
            },
            {
                title: 'Section Sign',
                value: '§'
            },
            {
                title: 'Pilcrow Sign',
                value: '¶'
            },
            {
                title: 'Victory Hand Emoji',
                value: '✌️'
            },
            {
                title: 'Index Pointing Up Emoji',
                value: '☝️'
            },
            {
                title: 'White Right Pointing Index',
                value: '☞'
            },
            {
                title: 'Black Right Pointing Index',
                value: '☛'
            },
            {
                title: 'White Down Pointing Index',
                value: '☟'
            },
            {
                title: 'White Left Pointing Index',
                value: '☜'
            },
            {
                title: 'Black Left Pointing Index',
                value: '☚'
            },
            {
                title: 'Writing Hand Emoji',
                value: '✍️'
            }
        ]
    },
    {
        category: 'Currency Symbols - Cent, Dollar, Euro... Symbols',
        symbols: [
            {
                title: 'Cent Sign',
                value: '¢'
            },
            {
                title: 'Dollar Sign',
                value: '$'
            },
            {
                title: 'Euro Sign',
                value: '€'
            },
            {
                title: 'Pound Sign',
                value: '£'
            },
            {
                title: 'Yen Sign',
                value: '¥'
            },
            {
                title: 'Tugrik Sign',
                value: '₮'
            },
            {
                title: 'Bengali Rupee Mark',
                value: '৲'
            },
            {
                title: 'Bengali Rupee Sign',
                value: '৳'
            },
            {
                title: 'Tamil Rupee Sign',
                value: '௹'
            },
            {
                title: 'Thai Currency Symbol Baht',
                value: '฿'
            },
            {
                title: 'Khmer Currency Symbol Riel',
                value: '៛'
            },
            {
                title: 'Euro-Currency Sign',
                value: '₠'
            },
            {
                title: 'Colon Sign',
                value: '₡'
            },
            {
                title: 'Cruzeiro Sign',
                value: '₢'
            },
            {
                title: 'French Franc Sign',
                value: '₣'
            },
            {
                title: 'Lira Sign',
                value: '₤'
            },
            {
                title: 'Mill Sign',
                value: '₥'
            },
            {
                title: 'Naira Sign',
                value: '₦'
            },
            {
                title: 'Peseta Sign',
                value: '₧'
            },
            {
                title: 'Rupee Sign',
                value: '₨'
            },
            {
                title: 'Won Sign',
                value: '₩'
            },
            {
                title: 'New Sheqel Sign',
                value: '₪'
            },
            {
                title: 'Dong Sign',
                value: '₫'
            },
            {
                title: 'Kip Sign',
                value: '₭'
            },
            {
                title: 'Drachma Sign',
                value: '₯'
            },
            {
                title: 'German Penny Sign',
                value: '₰'
            },
            {
                title: 'Peso Sign',
                value: '₱'
            },
            {
                title: 'Guarani Sign',
                value: '₲'
            },
            {
                title: 'Austral Sign',
                value: '₳'
            },
            {
                title: 'Hryvnia Sign',
                value: '₴'
            },
            {
                title: 'Cedi Sign',
                value: '₵'
            },
            {
                title: 'Fullwidth Yen Sign',
                value: '￥'
            },
            {
                title: 'Rial Sign',
                value: '﷼'
            },
            {
                title: 'Currency Sign',
                value: '¤'
            },
            {
                title: 'Latin Small Letter F with Hook',
                value: 'ƒ'
            }
        ]
    },
    {
        category: 'Heart Symbols',
        symbols: [
            {
                title: 'Black Heart Suit',
                value: '♥'
            },
            {
                title: 'White Heart Suit',
                value: '♡'
            },
            {
                title: 'Rotated Heavy Black Heart Bullet',
                value: '❥'
            },
            {
                title: 'Heavy Heart Exclamation Mark Ornament',
                value: '❣'
            },
            {
                title: 'Floral Heart',
                value: '❦'
            },
            {
                title: 'Rotated Floral Heart Bullet',
                value: '❧'
            },
            {
                title: 'Georgian Letter Don',
                value: 'დ'
            },
            {
                title: 'Georgian Letter Ghan',
                value: 'ღ'
            },
            {
                title: 'Extended Arabic-Indic Digit Five',
                value: '۵'
            },
            {
                title: 'Georgian Letter Las',
                value: 'ლ'
            },
            {
                title: 'Bengali Letter O',
                value: 'ও'
            },
            {
                title: 'Georgian Letter Las',
                value: 'ლ'
            },
            {
                title: 'Red Heart Emoji',
                value: '❤️️'
            },
            {
                title: 'Blue Heart Emoji',
                value: '💙'
            },
            {
                title: 'Orange Heart Emoji',
                value: '🧡'
            },
            {
                title: 'Green Heart Emoji',
                value: '💚'
            },
            {
                title: 'Yellow Heart Emoji',
                value: '💛'
            },
            {
                title: 'Purple Heart Emoji',
                value: '💜'
            },
            {
                title: 'Black Heart Emoji',
                value: '🖤'
            },
            {
                title: 'Growing Heart Emoji',
                value: '💗'
            },
            {
                title: 'Beating Heart Emoji',
                value: '💓'
            },
            {
                title: 'Broken Heart Emoji',
                value: '💔'
            },
            {
                title: 'Heart Decoration Emoji',
                value: '💟'
            },
            {
                title: 'Two Hearts Emoji',
                value: '💕'
            },
            {
                title: 'Sparkling Heart Emoji',
                value: '💖'
            },
            {
                title: 'Heart Exclamation Emoji',
                value: '❣️'
            },
            {
                title: 'Heart with Arrow Emoji',
                value: '💘'
            },
            {
                title: 'Heart with Ribbon Emoji',
                value: '💝'
            },
            {
                title: 'Revolving Hearts Emoji',
                value: '💞'
            }
        ]
    },
    {
        category: 'Check mark & Tick Symbols',
        symbols: [
            {
                title: 'Check Mark',
                value: '✓'
            },
            {
                title: 'Heavy Check Mark',
                value: '✔'
            },
            {
                title: 'Ballot X',
                value: '✗'
            },
            {
                title: 'Heavy Ballot X',
                value: '✘'
            },
            {
                title: 'Saltire',
                value: '☓'
            },
            {
                title: 'Logical Or',
                value: '∨'
            },
            {
                title: 'Square Root',
                value: '√'
            },
            {
                title: 'Check Mark Emoji',
                value: '✔️'
            },
            {
                title: 'Check Mark Button Emoji',
                value: '✅'
            },
            {
                title: 'Cross Mark Button Emoji',
                value: '❎'
            },
            {
                title: 'Cross Mark Emoji',
                value: '❌'
            },
            {
                title: 'Hundred Points Emoji',
                value: '💯'
            },
            {
                title: 'Tape Drive',
                value: '✇'
            },
            {
                title: 'Ballot Box',
                value: '☐'
            },
            {
                title: 'Ballot Box with Check',
                value: '☑'
            },
            {
                title: 'Ballot Box with X',
                value: '☒'
            },
            {
                title: 'Hangzhou Numeral Four',
                value: '〤'
            },
            {
                title: 'Hangzhou Numeral Five',
                value: '〥'
            }
        ]
    },
    {
        category: 'Number Symbols',
        symbols: [
            {
                title: 'Roman Numeral One',
                value: 'Ⅰ'
            },
            {
                title: 'Roman Numeral Two',
                value: 'Ⅱ'
            },
            {
                title: 'Roman Numeral Three',
                value: 'Ⅲ'
            },
            {
                title: 'Roman Numeral Four',
                value: 'Ⅳ'
            },
            {
                title: 'Roman Numeral Five',
                value: 'Ⅴ'
            },
            {
                title: 'Roman Numeral Six',
                value: 'Ⅵ'
            },
            {
                title: 'Roman Numeral Seven',
                value: 'Ⅶ'
            },
            {
                title: 'Roman Numeral Eight',
                value: 'Ⅷ'
            },
            {
                title: 'Roman Numeral Nine',
                value: 'Ⅸ'
            },
            {
                title: 'Roman Numeral Ten',
                value: 'Ⅹ'
            },
            {
                title: 'Roman Numeral Eleven',
                value: 'Ⅺ'
            },
            {
                title: 'Roman Numeral Twelve',
                value: 'Ⅻ'
            },
            {
                title: 'Roman Numeral Fifty',
                value: 'Ⅼ'
            },
            {
                title: 'Roman Numeral One Hundred',
                value: 'Ⅽ'
            },
            {
                title: 'Roman Numeral Five Hundred',
                value: 'Ⅾ'
            },
            {
                title: 'Roman Numeral One Thousand',
                value: 'Ⅿ'
            },
            {
                title: 'Small Roman Numeral One',
                value: 'ⅰ'
            },
            {
                title: 'Small Roman Numeral Two',
                value: 'ⅱ'
            },
            {
                title: 'Small Roman Numeral Three',
                value: 'ⅲ'
            },
            {
                title: 'Small Roman Numeral Four',
                value: 'ⅳ'
            },
            {
                title: 'Small Roman Numeral Five',
                value: 'ⅴ'
            },
            {
                title: 'Small Roman Numeral Six',
                value: 'ⅵ'
            },
            {
                title: 'Small Roman Numeral Seven',
                value: 'ⅶ'
            },
            {
                title: 'Small Roman Numeral Eight',
                value: 'ⅷ'
            },
            {
                title: 'Small Roman Numeral Nine',
                value: 'ⅸ'
            },
            {
                title: 'Small Roman Numeral Ten',
                value: 'ⅹ'
            },
            {
                title: 'Small Roman Numeral Eleven',
                value: 'ⅺ'
            },
            {
                title: 'Small Roman Numeral Twelve',
                value: 'ⅻ'
            },
            {
                title: 'Small Roman Numeral Fifty',
                value: 'ⅼ'
            },
            {
                title: 'Small Roman Numeral One Hundred',
                value: 'ⅽ'
            },
            {
                title: 'Small Roman Numeral Five Hundred',
                value: 'ⅾ'
            },
            {
                title: 'Small Roman Numeral One Thousand',
                value: 'ⅿ'
            },
            {
                title: 'Roman Numeral One Thousand CD',
                value: 'ↀ'
            },
            {
                title: 'Roman Numeral Five Thousand',
                value: 'ↁ'
            },
            {
                title: 'Roman Numeral Ten Thousand',
                value: 'ↂ'
            },
            {
                title: 'Dingbat Circled Sans-Serif Digit One',
                value: '➀'
            },
            {
                title: 'Dingbat Circled Sans-Serif Digit Two',
                value: '➁'
            },
            {
                title: 'Dingbat Circled Sans-Serif Digit Three',
                value: '➂'
            },
            {
                title: 'Dingbat Circled Sans-Serif Digit Four',
                value: '➃'
            },
            {
                title: 'Dingbat Circled Sans-Serif Digit Five',
                value: '➄'
            },
            {
                title: 'Dingbat Circled Sans-Serif Digit Six',
                value: '➅'
            },
            {
                title: 'Dingbat Circled Sans-Serif Digit Seven',
                value: '➆'
            },
            {
                title: 'Dingbat Circled Sans-Serif Digit Eight',
                value: '➇'
            },
            {
                title: 'Dingbat Circled Sans-Serif Digit Nine',
                value: '➈'
            },
            {
                title: 'Dingbat Circled Sans-Serif Number Ten',
                value: '➉'
            },
            {
                title: 'Dingbat Negative Circled Sans-Serif Digit One',
                value: '➊'
            },
            {
                title: 'Dingbat Negative Circled Sans-Serif Digit Two',
                value: '➋'
            },
            {
                title: 'Dingbat Negative Circled Sans-Serif Digit Three',
                value: '➌'
            },
            {
                title: 'Dingbat Negative Circled Sans-Serif Digit Four',
                value: '➍'
            },
            {
                title: 'Dingbat Negative Circled Sans-Serif Digit Five',
                value: '➎'
            },
            {
                title: 'Dingbat Negative Circled Sans-Serif Digit Six',
                value: '➏'
            },
            {
                title: 'Dingbat Negative Circled Sans-Serif Digit Seven',
                value: '➐'
            },
            {
                title: 'Dingbat Negative Circled Sans-Serif Digit Eight',
                value: '➑'
            },
            {
                title: 'Dingbat Negative Circled Sans-Serif Digit Nine',
                value: '➒'
            },
            {
                title: 'Dingbat Negative Circled Sans-Serif Number Ten',
                value: '➓'
            },
            {
                title: 'Double Circled Digit One',
                value: '⓵'
            },
            {
                title: 'Double Circled Digit Two',
                value: '⓶'
            },
            {
                title: 'Double Circled Digit Three',
                value: '⓷'
            },
            {
                title: 'Double Circled Digit Four',
                value: '⓸'
            },
            {
                title: 'Double Circled Digit Five',
                value: '⓹'
            },
            {
                title: 'Double Circled Digit Six',
                value: '⓺'
            },
            {
                title: 'Double Circled Digit Seven',
                value: '⓻'
            },
            {
                title: 'Double Circled Digit Eight',
                value: '⓼'
            },
            {
                title: 'Double Circled Digit Nine',
                value: '⓽'
            },
            {
                title: 'Double Circled Number Ten',
                value: '⓾'
            },
            {
                title: 'Negative Circled Digit Zero',
                value: '⓿'
            },
            {
                title: 'Dingbat Negative Circled Digit One',
                value: '❶'
            },
            {
                title: 'Dingbat Negative Circled Digit Two',
                value: '❷'
            },
            {
                title: 'Dingbat Negative Circled Digit Three',
                value: '❸'
            },
            {
                title: 'Dingbat Negative Circled Digit Four',
                value: '❹'
            },
            {
                title: 'Dingbat Negative Circled Digit Five',
                value: '❺'
            },
            {
                title: 'Dingbat Negative Circled Digit Six',
                value: '❻'
            },
            {
                title: 'Dingbat Negative Circled Digit Seven',
                value: '❼'
            },
            {
                title: 'Dingbat Negative Circled Digit Eight',
                value: '❽'
            },
            {
                title: 'Dingbat Negative Circled Digit Nine',
                value: '❾'
            },
            {
                title: 'Dingbat Negative Circled Number Ten',
                value: '❿'
            },
            {
                title: 'Superscript Zero',
                value: '⁰'
            },
            {
                title: 'Superscript One',
                value: '¹'
            },
            {
                title: 'Superscript Two',
                value: '²'
            },
            {
                title: 'Superscript Three',
                value: '³'
            },
            {
                title: 'Superscript Four',
                value: '⁴'
            },
            {
                title: 'Superscript Five',
                value: '⁵'
            },
            {
                title: 'Superscript Six',
                value: '⁶'
            },
            {
                title: 'Superscript Seven',
                value: '⁷'
            },
            {
                title: 'Superscript Eight',
                value: '⁸'
            },
            {
                title: 'Superscript Nine',
                value: '⁹'
            },
            {
                title: 'Subscript Zero',
                value: '₀'
            },
            {
                title: 'Subscript One',
                value: '₁'
            },
            {
                title: 'Subscript Two',
                value: '₂'
            },
            {
                title: 'Subscript Three',
                value: '₃'
            },
            {
                title: 'Subscript Four',
                value: '₄'
            },
            {
                title: 'Subscript Five',
                value: '₅'
            },
            {
                title: 'Subscript Six',
                value: '₆'
            },
            {
                title: 'Subscript Seven',
                value: '₇'
            },
            {
                title: 'Subscript Eight',
                value: '₈'
            },
            {
                title: 'Subscript Nine',
                value: '₉'
            },
            {
                title: 'Circled Digit Zero',
                value: '⓪'
            },
            {
                title: 'Circled Digit One',
                value: '①'
            },
            {
                title: 'Circled Digit Two',
                value: '②'
            },
            {
                title: 'Circled Digit Three',
                value: '③'
            },
            {
                title: 'Circled Digit Four',
                value: '④'
            },
            {
                title: 'Circled Digit Five',
                value: '⑤'
            },
            {
                title: 'Circled Digit Six',
                value: '⑥'
            },
            {
                title: 'Circled Digit Seven',
                value: '⑦'
            },
            {
                title: 'Circled Digit Eight',
                value: '⑧'
            },
            {
                title: 'Circled Digit Nine',
                value: '⑨'
            },
            {
                title: 'Circled Number Ten',
                value: '⑩'
            },
            {
                title: 'Circled Number Eleven',
                value: '⑪'
            },
            {
                title: 'Circled Number Twelve',
                value: '⑫'
            },
            {
                title: 'Circled Number Thirteen',
                value: '⑬'
            },
            {
                title: 'Circled Number Fourteen',
                value: '⑭'
            },
            {
                title: 'Circled Number Fifteen',
                value: '⑮'
            },
            {
                title: 'Circled Number Sixteen',
                value: '⑯'
            },
            {
                title: 'Circled Number Seventeen',
                value: '⑰'
            },
            {
                title: 'Circled Number Eighteen',
                value: '⑱'
            },
            {
                title: 'Circled Number Nineteen',
                value: '⑲'
            },
            {
                title: 'Circled Number Twenty',
                value: '⑳'
            },
            {
                title: 'Parenthesized Digit One',
                value: '⑴'
            },
            {
                title: 'Parenthesized Digit Two',
                value: '⑵'
            },
            {
                title: 'Parenthesized Digit Three',
                value: '⑶'
            },
            {
                title: 'Parenthesized Digit Four',
                value: '⑷'
            },
            {
                title: 'Parenthesized Digit Five',
                value: '⑸'
            },
            {
                title: 'Parenthesized Digit Six',
                value: '⑹'
            },
            {
                title: 'Parenthesized Digit Seven',
                value: '⑺'
            },
            {
                title: 'Parenthesized Digit Eight',
                value: '⑻'
            },
            {
                title: 'Parenthesized Digit Nine',
                value: '⑼'
            },
            {
                title: 'Parenthesized Number Ten',
                value: '⑽'
            },
            {
                title: 'Parenthesized Number Eleven',
                value: '⑾'
            },
            {
                title: 'Parenthesized Number Twelve',
                value: '⑿'
            },
            {
                title: 'Parenthesized Number Thirteen',
                value: '⒀'
            },
            {
                title: 'Parenthesized Number Fourteen',
                value: '⒁'
            },
            {
                title: 'Parenthesized Number Fifteen',
                value: '⒂'
            },
            {
                title: 'Parenthesized Number Sixteen',
                value: '⒃'
            },
            {
                title: 'Parenthesized Number Seventeen',
                value: '⒄'
            },
            {
                title: 'Parenthesized Number Eighteen',
                value: '⒅'
            },
            {
                title: 'Parenthesized Number Nineteen',
                value: '⒆'
            },
            {
                title: 'Parenthesized Number Twenty',
                value: '⒇'
            },
            {
                title: 'Digit One Full Stop',
                value: '⒈'
            },
            {
                title: 'Digit Two Full Stop',
                value: '⒉'
            },
            {
                title: 'Digit Three Full Stop',
                value: '⒊'
            },
            {
                title: 'Digit Four Full Stop',
                value: '⒋'
            },
            {
                title: 'Digit Five Full Stop',
                value: '⒌'
            },
            {
                title: 'Digit Six Full Stop',
                value: '⒍'
            },
            {
                title: 'Digit Seven Full Stop',
                value: '⒎'
            },
            {
                title: 'Digit Eight Full Stop',
                value: '⒏'
            },
            {
                title: 'Digit Nine Full Stop',
                value: '⒐'
            },
            {
                title: 'Number Ten Full Stop',
                value: '⒑'
            },
            {
                title: 'Number Eleven Full Stop',
                value: '⒒'
            },
            {
                title: 'Number Twelve Full Stop',
                value: '⒓'
            },
            {
                title: 'Number Thirteen Full Stop',
                value: '⒔'
            },
            {
                title: 'Number Fourteen Full Stop',
                value: '⒕'
            },
            {
                title: 'Number Fifteen Full Stop',
                value: '⒖'
            },
            {
                title: 'Number Sixteen Full Stop',
                value: '⒗'
            },
            {
                title: 'Number Seventeen Full Stop',
                value: '⒘'
            },
            {
                title: 'Number Eighteen Full Stop',
                value: '⒙'
            },
            {
                title: 'Number Nineteen Full Stop',
                value: '⒚'
            },
            {
                title: 'Number Twenty Full Stop',
                value: '⒛'
            },
            {
                title: 'Parenthesized Ideograph One',
                value: '㈠'
            },
            {
                title: 'Parenthesized Ideograph Two',
                value: '㈡'
            },
            {
                title: 'Parenthesized Ideograph Three',
                value: '㈢'
            },
            {
                title: 'Parenthesized Ideograph Four',
                value: '㈣'
            },
            {
                title: 'Parenthesized Ideograph Five',
                value: '㈤'
            },
            {
                title: 'Parenthesized Ideograph Six',
                value: '㈥'
            },
            {
                title: 'Parenthesized Ideograph Seven',
                value: '㈦'
            },
            {
                title: 'Parenthesized Ideograph Eight',
                value: '㈧'
            },
            {
                title: 'Parenthesized Ideograph Nine',
                value: '㈨'
            },
            {
                title: 'Parenthesized Ideograph Ten',
                value: '㈩'
            },
            {
                title: 'Circled Ideograph One',
                value: '㊀'
            },
            {
                title: 'Circled Ideograph Two',
                value: '㊁'
            },
            {
                title: 'Circled Ideograph Three',
                value: '㊂'
            },
            {
                title: 'Circled Ideograph Four',
                value: '㊃'
            },
            {
                title: 'Circled Ideograph Five',
                value: '㊄'
            },
            {
                title: 'Circled Ideograph Six',
                value: '㊅'
            },
            {
                title: 'Circled Ideograph Seven',
                value: '㊆'
            },
            {
                title: 'Circled Ideograph Eight',
                value: '㊇'
            },
            {
                title: 'Circled Ideograph Nine',
                value: '㊈'
            },
            {
                title: 'Circled Ideograph Ten',
                value: '㊉'
            },
            {
                title: 'Fullwidth Digit Zero',
                value: '０'
            },
            {
                title: 'Fullwidth Digit One',
                value: '１'
            },
            {
                title: 'Fullwidth Digit Two',
                value: '２'
            },
            {
                title: 'Fullwidth Digit Three',
                value: '３'
            },
            {
                title: 'Fullwidth Digit Four',
                value: '４'
            },
            {
                title: 'Fullwidth Digit Five',
                value: '５'
            },
            {
                title: 'Fullwidth Digit Six',
                value: '６'
            },
            {
                title: 'Fullwidth Digit Seven',
                value: '７'
            },
            {
                title: 'Fullwidth Digit Eight',
                value: '８'
            },
            {
                title: 'Fullwidth Digit Nine',
                value: '９'
            },
            {
                title: 'Superscript Latin Small Letter I',
                value: 'ⁱ'
            },
            {
                title: 'Latin Subscript Small Letter A',
                value: 'ₐ'
            },
            {
                title: 'Latin Subscript Small Letter E',
                value: 'ₑ'
            },
            {
                title: 'Latin Subscript Small Letter O',
                value: 'ₒ'
            },
            {
                title: 'Latin Subscript Small Letter X',
                value: 'ₓ'
            },
            {
                title: 'Latin Subscript Small Letter Schwa',
                value: 'ₔ'
            }
        ]
    },
    {
        category: 'Fraction Symbols',
        symbols: [
            {
                title: 'Fraction Numerator One',
                value: '⅟'
            },
            {
                title: 'Vulgar Fraction One Half',
                value: '½'
            },
            {
                title: 'Vulgar Fraction One Third',
                value: '⅓'
            },
            {
                title: 'Vulgar Fraction One Fifth',
                value: '⅕'
            },
            {
                title: 'Vulgar Fraction One Sixth',
                value: '⅙'
            },
            {
                title: 'Vulgar Fraction One Eighth',
                value: '⅛'
            },
            {
                title: 'Vulgar Fraction Two Thirds',
                value: '⅔'
            },
            {
                title: 'Vulgar Fraction Two Fifths',
                value: '⅖'
            },
            {
                title: 'Vulgar Fraction Five Sixths',
                value: '⅚'
            },
            {
                title: 'Vulgar Fraction Three Eighths',
                value: '⅜'
            },
            {
                title: 'Vulgar Fraction Three Quarters',
                value: '¾'
            },
            {
                title: 'Vulgar Fraction Three Fifths',
                value: '⅗'
            },
            {
                title: 'Vulgar Fraction Five Eighths',
                value: '⅝'
            },
            {
                title: 'Vulgar Fraction Seven Eighths',
                value: '⅞'
            },
            {
                title: 'Vulgar Fraction Four Fifths',
                value: '⅘'
            },
            {
                title: 'Vulgar Fraction One Quarter',
                value: '¼'
            },
            {
                title: 'Vulgar Fraction One Seventh',
                value: '⅐'
            },
            {
                title: 'Vulgar Fraction One Ninth',
                value: '⅑'
            },
            {
                title: 'Vulgar Fraction One Tenth',
                value: '⅒'
            },
            {
                title: 'Vulgar Fraction Zero Thirds',
                value: '↉'
            },
            {
                title: 'Percent Sign',
                value: '%'
            },
            {
                title: 'Care Of',
                value: '℅'
            },
            {
                title: 'Per Mille Sign',
                value: '‰'
            },
            {
                title: 'Per Ten Thousand Sign',
                value: '‱'
            }
        ]
    },   
    {
        category: 'Smileys & Emotion Emoji Symbols',
        symbols: [
            {
                title: 'Face with Tears of Joy',
                value: '😂'
            },
            {
                title: 'Smiling Face with Open Mouth and Smiling Eyes',
                value: '😄'
            },
            {
                title: 'Smiling Face with Open Mouth',
                value: '😃'
            },
            {
                title: 'Grinning Face',
                value: '😀'
            },
            {
                title: 'Smiling Face with Smiling Eyes',
                value: '😊'
            },
            {
                title: 'Winking Face',
                value: '😉'
            },
            {
                title: 'Smiling Face with Heart-Shaped Eyes',
                value: '😍'
            },
            {
                title: 'Face Throwing a Kiss',
                value: '😘'
            },
            {
                title: 'Kissing Face with Closed Eyes',
                value: '😚'
            },
            {
                title: 'Kissing Face',
                value: '😗'
            },
            {
                title: 'Kissing Face with Smiling Eyes',
                value: '😙'
            },
            {
                title: 'Face with Stuck-Out Tongue and Winking Eye',
                value: '😜'
            },
            {
                title: 'Face with Stuck-Out Tongue and Tightly-Closed Eyes',
                value: '😝'
            },
            {
                title: 'Face with Stuck-Out Tongue',
                value: '😛'
            },
            {
                title: 'Flushed Face',
                value: '😳'
            },
            {
                title: 'Grinning Face with Smiling Eyes',
                value: '😁'
            },
            {
                title: 'Pensive Face',
                value: '😔'
            },
            {
                title: 'Relieved Face',
                value: '😌'
            },
            {
                title: 'Unamused Face',
                value: '😒'
            },
            {
                title: 'Disappointed Face',
                value: '😞'
            },
            {
                title: 'Persevering Face',
                value: '😣'
            },
            {
                title: 'Crying Face',
                value: '😢'
            },
            {
                title: 'Loudly Crying Face',
                value: '😭'
            },
            {
                title: 'Sleepy Face',
                value: '😪'
            },
            {
                title: 'Sad but Relieved Face',
                value: '😥'
            },
            {
                title: 'Anxious Face with Sweat',
                value: '😰'
            },
            {
                title: 'Smiling Face with Open Mouth and Cold Sweat',
                value: '😅'
            },
            {
                title: 'Face with Cold Sweat',
                value: '😓'
            },
            {
                title: 'Weary Face',
                value: '😩'
            },
            {
                title: 'Tired Face',
                value: '😫'
            },
            {
                title: 'Fearful Face',
                value: '😨'
            },
            {
                title: 'Face Screaming in Fear',
                value: '😱'
            },
            {
                title: 'Angry Face',
                value: '😠'
            },
            {
                title: 'Pouting Face',
                value: '😡'
            },
            {
                title: 'Face with Steam From Nose',
                value: '😤'
            },
            {
                title: 'Confounded Face',
                value: '😖'
            },
            {
                title: 'Smiling Face with Open Mouth and Tightly-Closed Eyes',
                value: '😆'
            },
            {
                title: 'Face Savouring Delicious Food',
                value: '😋'
            },
            {
                title: 'Face with Medical Mask',
                value: '😷'
            },
            {
                title: 'Smiling Face with Sunglasses',
                value: '😎'
            },
            {
                title: 'Sleeping Face',
                value: '😴'
            },
            {
                title: 'Dizzy Face',
                value: '😵'
            },
            {
                title: 'Astonished Face',
                value: '😲'
            },
            {
                title: 'Worried Face',
                value: '😟'
            },
            {
                title: 'Frowning Face with Open Mouth',
                value: '😦'
            },
            {
                title: 'Anguished Face',
                value: '😧'
            },
            {
                title: 'Smiling Face with Horns',
                value: '😈'
            },
            {
                title: 'Angry Face with Horns',
                value: '👿'
            },
            {
                title: 'Face with Open Mouth',
                value: '😮'
            },
            {
                title: 'Grimacing Face',
                value: '😬'
            },
            {
                title: 'Neutral Face',
                value: '😐'
            },
            {
                title: 'Confused Face',
                value: '😕'
            },
            {
                title: 'Hushed Face',
                value: '😯'
            },
            {
                title: 'Face without Mouth',
                value: '😶'
            },
            {
                title: 'Smiling Face with Halo',
                value: '😇'
            },
            {
                title: 'Smiling Face',
                value: '☺️'
            },
            {
                title: 'Smirking Face',
                value: '😏'
            },
            {
                title: 'Expressionless Face',
                value: '😑'
            },
            {
                title: 'Upside-Down Face',
                value: '🙃'
            },
            {
                title: 'Face with Rolling Eyes',
                value: '🙄'
            },
            {
                title: 'Frowning Face',
                value: '☹️'
            },
            {
                title: 'Zipper-Mouth Face',
                value: '🤐'
            },
            {
                title: 'Money-Mouth Face',
                value: '🤑'
            },
            {
                title: 'Face with Thermometer',
                value: '🤒'
            },
            {
                title: 'Nerd Face',
                value: '🤓'
            },
            {
                title: 'Thinking Face',
                value: '🤔'
            },
            {
                title: 'Face with Head-Bandage',
                value: '🤕'
            },
            {
                title: 'Slightly Frowning Face',
                value: '🙁'
            },
            {
                title: 'Slightly Smiling Face',
                value: '🙂'
            },
            {
                title: 'Hugging Face',
                value: '🤗'
            },
            {
                title: 'Rolling On The Floor Laughing',
                value: '🤣'
            },
            {
                title: 'Face With Cowboy Hat',
                value: '🤠'
            },
            {
                title: 'Lying Face',
                value: '🤥'
            },
            {
                title: 'Drooling Face',
                value: '🤤'
            },
            {
                title: 'Nauseated Face',
                value: '🤢'
            },
            {
                title: 'Sneezing Face',
                value: '🤧'
            },
            {
                title: 'Clown Face',
                value: '🤡'
            },
            {
                title: 'Robot Face',
                value: '🤖'
            },
            {
                title: 'Black Heart',
                value: '🖤'
            },
            {
                title: 'Yellow Heart',
                value: '💛'
            },
            {
                title: 'Blue Heart',
                value: '💙'
            },
            {
                title: 'Purple Heart',
                value: '💜'
            },
            {
                title: 'Green Heart',
                value: '💚'
            },
            {
                title: 'Orange Heart',
                value: '🧡'
            },
            {
                title: 'Red Heart',
                value: '❤️️'
            },
            {
                title: 'Broken Heart',
                value: '💔'
            },
            {
                title: 'Growing Heart',
                value: '💗'
            },
            {
                title: 'Beating Heart',
                value: '💓'
            },
            {
                title: 'Two Hearts',
                value: '💕'
            },
            {
                title: 'Sparkling Heart',
                value: '💖'
            },
            {
                title: 'Revolving Hearts',
                value: '💞'
            },
            {
                title: 'Heart with Arrow',
                value: '💘'
            },
            {
                title: 'Heart with Ribbon',
                value: '💝'
            },
            {
                title: 'Heart Exclamation',
                value: '❣️'
            },
            {
                title: 'Love Letter',
                value: '💌'
            },
            {
                title: 'Kiss Mark',
                value: '💋'
            },
            {
                title: 'Smiling Cat Face with Open Mouth',
                value: '😺'
            },
            {
                title: 'Grinning Cat Face with Smiling Eyes',
                value: '😸'
            },
            {
                title: 'Smiling Cat Face with Heart-Shaped Eyes',
                value: '😻'
            },
            {
                title: 'Kissing Cat Face with Closed Eyes',
                value: '😽'
            },
            {
                title: 'Cat Face with Wry Smile',
                value: '😼'
            },
            {
                title: 'Weary Cat Face',
                value: '🙀'
            },
            {
                title: 'Crying Cat Face',
                value: '😿'
            },
            {
                title: 'Cat Face with Tears of Joy',
                value: '😹'
            },
            {
                title: 'Pouting Cat Face',
                value: '😾'
            },
            {
                title: 'See-No-Evil Monkey',
                value: '🙈'
            },
            {
                title: 'Hear-No-Evil Monkey',
                value: '🙉'
            },
            {
                title: 'Speak-No-Evil Monkey',
                value: '🙊'
            },
            {
                title: 'Skull',
                value: '💀'
            },
            {
                title: 'Extraterrestrial Alien',
                value: '👽'
            },
            {
                title: 'Japanese Ogre',
                value: '👹'
            },
            {
                title: 'Japanese Goblin',
                value: '👺'
            },
            {
                title: 'Star-Struck',
                value: '🤩'
            },
            {
                title: 'Face With Raised Eyebrow',
                value: '🤨'
            },
            {
                title: 'Pleading Face',
                value: '🥺️'
            },
            {
                title: 'Exploding Head',
                value: '🤯'
            },
            {
                title: 'Zany Face',
                value: '🤪'
            },
            {
                title: 'Face With Symbols Over Mouth',
                value: '🤬'
            },
            {
                title: 'Face Vomiting',
                value: '🤮'
            },
            {
                title: 'Shushing Face',
                value: '🤫'
            },
            {
                title: 'Face With Hand Over Mouth',
                value: '🤭'
            },
            {
                title: 'Face With Monocle',
                value: '🧐'
            },
            {
                title: 'Smiling Face With Hearts',
                value: '🥰️'
            },
            {
                title: 'Hot Face',
                value: '🥵️'
            },
            {
                title: 'Cold Face',
                value: '🥶️'
            },
            {
                title: 'Woozy Face',
                value: '🥴️'
            },
            {
                title: 'Partying Face',
                value: '🥳️'
            },
            {
                title: 'Smiling Face with Tear',
                value: '🥲'
            },
            {
                title: 'Disguised Face',
                value: '🥸'
            }
        ]
    },
    {
        category: 'People & Body Emoji Symbols',
        symbols: [
            {
                title: 'Baby',
                value: '👶'
            },
            {
                title: 'Boy',
                value: '👦'
            },
            {
                title: 'Girl',
                value: '👧'
            },
            {
                title: 'Man',
                value: '👨'
            },
            {
                title: 'Woman',
                value: '👩'
            },
            {
                title: 'Baby Angel',
                value: '👼'
            },
            {
                title: 'Pregnant Woman',
                value: '🤰'
            },
            {
                title: 'Selfie',
                value: '🤳'
            },
            {
                title: 'Princess',
                value: '👸'
            },
            {
                title: 'Prince',
                value: '🤴'
            },
            {
                title: 'Bearded Person',
                value: '🧔'
            },
            {
                title: 'Man Dancing',
                value: '🕺'
            },
            {
                title: 'Woman Dancing',
                value: '💃'
            },
            {
                title: 'Ninja',
                value: '🥷'
            },
            {
                title: 'Person in Suit Levitating',
                value: '🕴️'
            },
            {
                title: 'Ghost',
                value: '👻'
            },
            {
                title: 'Japanese Dolls',
                value: '🎎'
            },
            {
                title: 'Snowman',
                value: '☃️'
            },
            {
                title: 'Skull and Crossbones',
                value: '☠️'
            },
            {
                title: 'Eye in Speech Bubble',
                value: '👁\u200d🗨'
            },
            {
                title: 'Moyai',
                value: '🗿'
            },
            {
                title: 'Person With Skullcap',
                value: '👲'
            },
            {
                title: 'Ear',
                value: '👂'
            },
            {
                title: 'Eyes',
                value: '👀'
            },
            {
                title: 'Nose',
                value: '👃'
            },
            {
                title: 'Tongue',
                value: '👅'
            },
            {
                title: 'Mouth',
                value: '👄'
            },
            {
                title: 'Eye',
                value: '👁'
            },
            {
                title: 'Lungs',
                value: '🫁'
            },
            {
                title: 'Anatomical Heart',
                value: '🫀'
            },
            {
                title: 'Bust in Silhouette',
                value: '👤'
            },
            {
                title: 'Busts in Silhouette',
                value: '👥'
            },
            {
                title: 'Speaking Head in Silhouette',
                value: '🗣'
            },
            {
                title: 'People Hugging',
                value: '🫂'
            },
            {
                title: 'Speech Balloon',
                value: '💬'
            },
            {
                title: 'Thought Balloon',
                value: '💭'
            },
            {
                title: 'Left Speech Bubble',
                value: '🗨'
            },
            {
                title: 'Right Anger Bubble',
                value: '🗯'
            },
            {
                title: 'Child',
                value: '🧒'
            },
            {
                title: 'Person',
                value: '🧑'
            },
            {
                title: 'Woman With Headscarf',
                value: '🧕'
            },
            {
                title: 'Breast-Feeding',
                value: '🤱'
            },
            {
                title: 'Leg',
                value: '🦵️'
            },
            {
                title: 'Foot',
                value: '🦶️'
            },
            {
                title: 'Tooth',
                value: '🦷️'
            },
            {
                title: 'Bone',
                value: '🦴️'
            },
            {
                title: 'Family',
                value: '👪'
            },
            {
                title: 'Family: Man, Boy',
                value: '👨\u200d👦'
            },
            {
                title: 'Family: Man, Boy, Boy',
                value: '👨\u200d👦\u200d👦'
            },
            {
                title: 'Family: Man, Girl',
                value: '👨\u200d👧'
            },
            {
                title: 'Family: Man, Girl, Boy',
                value: '👨\u200d👧\u200d👦'
            },
            {
                title: 'Family: Man, Girl, Girl',
                value: '👨\u200d👧\u200d👧'
            },
            {
                title: 'Family: Man, Woman, Boy',
                value: '👨\u200d👩\u200d👦'
            },
            {
                title: 'Family: Woman, Boy',
                value: '👩\u200d👦'
            },
            {
                title: 'Family: Woman, Boy, Boy',
                value: '👩\u200d👦\u200d👦'
            },
            {
                title: 'Family: Woman, Girl',
                value: '👩\u200d👧'
            },
            {
                title: 'Family: Woman, Girl, Boy',
                value: '👩\u200d👧\u200d👦'
            },
            {
                title: 'Family: Woman, Girl, Girl',
                value: '👩\u200d👧\u200d👧'
            },
            {
                title: 'Family: Man, Man, Boy',
                value: '👨\u200d👨\u200d👦'
            },
            {
                title: 'Family: Man, Man, Boy, Boy',
                value: '👨\u200d👨\u200d👦\u200d👦'
            },
            {
                title: 'Family: Man, Man, Girl',
                value: '👨\u200d👨\u200d👧'
            },
            {
                title: 'Family: Man, Man, Girl, Boy',
                value: '👨\u200d👨\u200d👧\u200d👦'
            },
            {
                title: 'Family: Man, Man, Girl, Girl',
                value: '👨\u200d👨\u200d👧\u200d👧'
            },
            {
                title: 'Family: Man, Woman, Boy, Boy',
                value: '👨\u200d👩\u200d👦\u200d👦'
            },
            {
                title: 'Family: Man, Woman, Girl',
                value: '👨\u200d👩\u200d👧'
            },
            {
                title: 'Family: Man, Woman, Girl, Boy',
                value: '👨\u200d👩\u200d👧\u200d👦'
            },
            {
                title: 'Family: Man, Woman, Girl, Girl',
                value: '👨\u200d👩\u200d👧\u200d👧'
            },
            {
                title: 'Family: Woman, Woman, Boy',
                value: '👩\u200d👩\u200d👦'
            },
            {
                title: 'Family: Woman, Woman, Boy, Boy',
                value: '👩\u200d👩\u200d👦\u200d👦'
            },
            {
                title: 'Family: Woman, Woman, Girl',
                value: '👩\u200d👩\u200d👧'
            },
            {
                title: 'Family: Woman, Woman, Girl, Boy',
                value: '👩\u200d👩\u200d👧\u200d👦'
            },
            {
                title: 'Family: Woman, Woman, Girl, Girl',
                value: '👩\u200d👩\u200d👧\u200d👧'
            },
            {
                title: 'Kiss',
                value: '💏'
            },
            {
                title: 'Kiss: Woman, Man',
                value: '👩\u200d❤️\u200d💋\u200d👨'
            },
            {
                title: 'Kiss: Man, Man',
                value: '👨\u200d❤️\u200d💋\u200d👨'
            },
            {
                title: 'Kiss: Woman, Woman',
                value: '👩\u200d❤️\u200d💋\u200d👩'
            },
            {
                title: 'Couple with Heart',
                value: '💑'
            },
            {
                title: 'Couple with Heart: Woman, Man',
                value: '👩\u200d❤️\u200d👨'
            },
            {
                title: 'Couple with Heart: Man, Man',
                value: '👨\u200d❤️\u200d👨'
            },
            {
                title: 'Couple with Heart: Woman, Woman',
                value: '👩\u200d❤️\u200d👩'
            },
            {
                title: 'Person In Tuxedo',
                value: '🤵'
            },
            {
                title: 'Man in Tuxedo',
                value: '🤵\u200d♂️'
            },
            {
                title: 'Woman in Tuxedo',
                value: '🤵\u200d♀️'
            },
            {
                title: 'Person: White Hair',
                value: '🧑\u200d🦳'
            },
            {
                title: 'Man: White Hair',
                value: '👨\u200d🦳️'
            },
            {
                title: 'Woman: White Hair',
                value: '👩\u200d🦳️'
            },
            {
                title: 'Person: Curly Hair',
                value: '🧑\u200d🦱'
            },
            {
                title: 'Man: Curly Hair',
                value: '👨\u200d🦱️'
            },
            {
                title: 'Woman: Curly Hair',
                value: '👩\u200d🦱️'
            },
            {
                title: 'Person: Bald',
                value: '🧑\u200d🦲'
            },
            {
                title: 'Man: Bald',
                value: '👨\u200d🦲️'
            },
            {
                title: 'Woman: Bald',
                value: '👩\u200d🦲️'
            },
            {
                title: 'Older Person',
                value: '🧓'
            },
            {
                title: 'Older Man',
                value: '👴'
            },
            {
                title: 'Older Woman',
                value: '👵'
            },
            {
                title: 'Person: Red Hair',
                value: '🧑\u200d🦰'
            },
            {
                title: 'Man: Red Hair',
                value: '👨\u200d🦰️'
            },
            {
                title: 'Woman: Red Hair',
                value: '👩\u200d🦰️'
            },
            {
                title: 'Person with Blond Hair',
                value: '👱'
            },
            {
                title: 'Man with Blond Hair',
                value: '👱\u200d♂️'
            },
            {
                title: 'Woman with Blond Hair',
                value: '👱\u200d♀️'
            },
            {
                title: 'Person with Veil',
                value: '👰'
            },
            {
                title: 'Man with Veil',
                value: '👰\u200d♂️'
            },
            {
                title: 'Woman with Veil',
                value: '👰\u200d♀️'
            },
            {
                title: 'Mx Claus',
                value: '🧑\u200d🎄'
            },
            {
                title: 'Santa Claus',
                value: '🎅'
            },
            {
                title: 'Mrs. Claus',
                value: '🎅'
            },
            {
                title: 'Person Gesturing OK',
                value: '🙆'
            },
            {
                title: 'Man Gesturing OK',
                value: '🙆\u200d♂️'
            },
            {
                title: 'Woman Gesturing OK',
                value: '🙆\u200d♀️'
            },
            {
                title: 'Person Gesturing No',
                value: '🙅'
            },
            {
                title: 'Man Gesturing No',
                value: '🙅\u200d♂️'
            },
            {
                title: 'Woman Gesturing No',
                value: '🙅\u200d♀️'
            },
            {
                title: 'Person Tipping Hand',
                value: '💁'
            },
            {
                title: 'Man Tipping Hand',
                value: '💁\u200d♂️'
            },
            {
                title: 'Woman Tipping Hand',
                value: '💁\u200d♀️'
            },
            {
                title: 'Person Raising Hand',
                value: '🙋'
            },
            {
                title: 'Man Raising Hand',
                value: '🙋\u200d♂️'
            },
            {
                title: 'Woman Raising Hand',
                value: '🙋\u200d♀️'
            },
            {
                title: 'Person Shrugging',
                value: '🤷'
            },
            {
                title: 'Man Shrugging',
                value: '🤷\u200d♂️'
            },
            {
                title: 'Woman Shrugging',
                value: '🤷\u200d♀️'
            },
            {
                title: 'Person Frowning',
                value: '🙍'
            },
            {
                title: 'Man Frowning',
                value: '🙍\u200d♂️'
            },
            {
                title: 'Woman Frowning',
                value: '🙍\u200d♀️'
            },
            {
                title: 'Person Getting Massage',
                value: '💆'
            },
            {
                title: 'Man Getting Massage',
                value: '💆\u200d♂️'
            },
            {
                title: 'Woman Getting Massage',
                value: '💆\u200d♀️'
            },
            {
                title: 'Person Getting Haircut',
                value: '💇'
            },
            {
                title: 'Man Getting Haircut',
                value: '💇\u200d♂️'
            },
            {
                title: 'Woman Getting Haircut',
                value: '💇\u200d♀️'
            },
            {
                title: 'Person Pouting',
                value: '🙎'
            },
            {
                title: 'Man Pouting',
                value: '🙎\u200d♂️'
            },
            {
                title: 'Woman Pouting',
                value: '🙎\u200d♀️'
            },
            {
                title: 'Person Facepalming',
                value: '🤦'
            },
            {
                title: 'Man Facepalming',
                value: '🤦\u200d♂️'
            },
            {
                title: 'Woman Facepalming',
                value: '🤦\u200d♀️'
            },
            {
                title: 'Police Officer',
                value: '👮'
            },
            {
                title: 'Man Police Officer',
                value: '👮\u200d♂️'
            },
            {
                title: 'Woman Police Officer',
                value: '👮\u200d♀️'
            },
            {
                title: 'Construction Worker',
                value: '👷'
            },
            {
                title: 'Man Construction Worker',
                value: '👷\u200d♂️'
            },
            {
                title: 'Woman Construction Worker',
                value: '👷\u200d♀️'
            },
            {
                title: 'Deaf Person',
                value: '🧏'
            },
            {
                title: 'Deaf Man',
                value: '🧏\u200d♂️'
            },
            {
                title: 'Deaf Woman',
                value: '🧏\u200d♀️'
            },
            {
                title: 'Guard',
                value: '💂'
            },
            {
                title: 'Man Guard',
                value: '💂\u200d♂️'
            },
            {
                title: 'Woman Guard',
                value: '💂\u200d♀️'
            },
            {
                title: 'Detective',
                value: '🕵'
            },
            {
                title: 'Man Detective',
                value: '🕵️\u200d♂️'
            },
            {
                title: 'Woman Detective',
                value: '🕵️\u200d♀️'
            },
            {
                title: 'Person Bowing',
                value: '🙇'
            },
            {
                title: 'Man Bowing',
                value: '🙇\u200d♂️'
            },
            {
                title: 'Woman Bowing',
                value: '🙇\u200d♀️'
            },
            {
                title: 'Person Walking',
                value: '🚶'
            },
            {
                title: 'Man Walking',
                value: '🚶\u200d♂️'
            },
            {
                title: 'Woman Walking',
                value: '🚶\u200d♀️'
            },
            {
                title: 'Person Running',
                value: '🏃'
            },
            {
                title: 'Man Running',
                value: '🏃\u200d♂️'
            },
            {
                title: 'Woman Running',
                value: '🏃\u200d♀️'
            },
            {
                title: 'Mage',
                value: '🧙'
            },
            {
                title: 'Man Mage',
                value: '🧙\u200d♂️'
            },
            {
                title: 'Woman Mage',
                value: '🧙\u200d♀️'
            },
            {
                title: 'Fairy',
                value: '🧚'
            },
            {
                title: 'Man Fairy',
                value: '🧚\u200d♂️'
            },
            {
                title: 'Woman Fairy',
                value: '🧚\u200d♀️'
            },
            {
                title: 'Vampire',
                value: '🧛'
            },
            {
                title: 'Man Vampire',
                value: '🧛\u200d♂️'
            },
            {
                title: 'Woman Vampire',
                value: '🧛\u200d♀️'
            },
            {
                title: 'Merperson',
                value: '🧜'
            },
            {
                title: 'Merman',
                value: '🧜\u200d♂️'
            },
            {
                title: 'Mermaid',
                value: '🧜\u200d♀️'
            },
            {
                title: 'Elf',
                value: '🧝'
            },
            {
                title: 'Man Elf',
                value: '🧝\u200d♂️'
            },
            {
                title: 'Woman Elf',
                value: '🧝\u200d♀️'
            },
            {
                title: 'Genie',
                value: '🧞'
            },
            {
                title: 'Man Genie',
                value: '🧞\u200d♂️'
            },
            {
                title: 'Woman Genie',
                value: '🧞\u200d♀️'
            },
            {
                title: 'Person in Steamy Room',
                value: '🧖'
            },
            {
                title: 'Man in Steamy Room',
                value: '🧖\u200d♂️'
            },
            {
                title: 'Woman in Steamy Room',
                value: '🧖\u200d♀️'
            },
            {
                title: 'Person Wearing Turban',
                value: '👳'
            },
            {
                title: 'Man Wearing Turban',
                value: '👳\u200d♀️'
            },
            {
                title: 'Woman Wearing Turban',
                value: '👳\u200d♀️'
            },
            {
                title: 'Person Climbing',
                value: '🧗'
            },
            {
                title: 'Man Climbing',
                value: '🧗\u200d♂️'
            },
            {
                title: 'Woman Climbing',
                value: '🧗\u200d♀️'
            },
            {
                title: 'Person in Lotus Position',
                value: '🧘'
            },
            {
                title: 'Man in Lotus Position',
                value: '🧘\u200d♂️'
            },
            {
                title: 'Woman in Lotus Position',
                value: '🧘\u200d♀️'
            },
            {
                title: 'Zombie',
                value: '🧟'
            },
            {
                title: 'Man Zombie',
                value: '🧟\u200d♂️'
            },
            {
                title: 'Woman Zombie',
                value: '🧟\u200d♀️'
            },
            {
                title: 'Superhero',
                value: '🦸️'
            },
            {
                title: 'Man Superhero',
                value: '🦸\u200d♂️️'
            },
            {
                title: 'Woman Superhero',
                value: '🦸\u200d♀️️'
            },
            {
                title: 'Supervillain',
                value: '🦹️'
            },
            {
                title: 'Man Supervillain',
                value: '🦹\u200d♂️️'
            },
            {
                title: 'Woman Supervillain',
                value: '🦹\u200d♀️️'
            },
            {
                title: 'People with Bunny Ears',
                value: '👯'
            },
            {
                title: 'Men with Bunny Ears',
                value: '👯\u200d♂️'
            },
            {
                title: 'Women with Bunny Ears',
                value: '👯'
            },
            {
                title: 'Person Mountain Biking',
                value: '🚵'
            },
            {
                title: 'Man Mountain Biking',
                value: '🚵\u200d♂️'
            },
            {
                title: 'Woman Mountain Biking',
                value: '🚵\u200d♀️'
            },
            {
                title: 'Person Biking',
                value: '🚴'
            },
            {
                title: 'Man Biking',
                value: '🚴\u200d♂️'
            },
            {
                title: 'Woman Biking',
                value: '🚴\u200d♀️'
            },
            {
                title: 'Person with Ball',
                value: '⛹'
            },
            {
                title: 'Man with Ball',
                value: '⛹️\u200d♂️'
            },
            {
                title: 'Woman with Ball',
                value: '⛹️\u200d♀️'
            },
            {
                title: 'Person Swimming',
                value: '🏊'
            },
            {
                title: 'Man Swimming',
                value: '🏊\u200d♂️'
            },
            {
                title: 'Woman Swimming',
                value: '🏊\u200d♀️'
            },
            {
                title: 'Person Surfing',
                value: '🏄'
            },
            {
                title: 'Man Surfing',
                value: '🏄\u200d♂️'
            },
            {
                title: 'Woman Surfing',
                value: '🏄\u200d♀️'
            },
            {
                title: 'Person Juggling',
                value: '🤹'
            },
            {
                title: 'Man Juggling',
                value: '🤹\u200d♂️'
            },
            {
                title: 'Woman Juggling',
                value: '🤹\u200d♀️'
            },
            {
                title: 'Person Lifting Weights',
                value: '🏋'
            },
            {
                title: 'Man Lifting Weights',
                value: '🏋️\u200d♂️'
            },
            {
                title: 'Woman Lifting Weights',
                value: '🏋️\u200d♀️'
            },
            {
                title: 'Person Golfing',
                value: '🏌'
            },
            {
                title: 'Man Golfing',
                value: '🏌️\u200d♂️'
            },
            {
                title: 'Woman Golfing',
                value: '🏌️\u200d♀️'
            },
            {
                title: 'Person Cartwheeling',
                value: '🤸'
            },
            {
                title: 'Man Cartwheeling',
                value: '🤸\u200d♂️'
            },
            {
                title: 'Woman Cartwheeling',
                value: '🤸\u200d♀️'
            },
            {
                title: 'Person Rowing Boat',
                value: '🚣'
            },
            {
                title: 'Man Rowing Boat',
                value: '🚣\u200d♂️'
            },
            {
                title: 'Woman Rowing Boat',
                value: '🚣\u200d♀️'
            },
            {
                title: 'Person Playing Water Polo',
                value: '🤽'
            },
            {
                title: 'Man Playing Water Polo',
                value: '🤽\u200d♂️'
            },
            {
                title: 'Woman Playing Water Polo',
                value: '🤽\u200d♀️'
            },
            {
                title: 'Person Playing Handball',
                value: '🤾'
            },
            {
                title: 'Man Playing Handball',
                value: '🤾\u200d♂️'
            },
            {
                title: 'Woman Playing Handball',
                value: '🤾\u200d♀️'
            },
            {
                title: 'Person Feeding Baby',
                value: '🧑\u200d🍼'
            },
            {
                title: 'Man Feeding Baby',
                value: '👨\u200d🍼'
            },
            {
                title: 'Woman Feeding Baby',
                value: '👩\u200d🍼'
            },
            {
                title: 'Student',
                value: '🧑\u200d🎓'
            },
            {
                title: 'Man Student',
                value: '👨\u200d🎓'
            },
            {
                title: 'Woman Student',
                value: '👩\u200d🎓'
            },
            {
                title: 'Singer',
                value: '🧑\u200d🎤'
            },
            {
                title: 'Man Singer',
                value: '👨\u200d🎤'
            },
            {
                title: 'Woman Singer',
                value: '👩\u200d🎤'
            },
            {
                title: 'Cook',
                value: '🧑\u200d🍳'
            },
            {
                title: 'Man Cook',
                value: '👨\u200d🍳'
            },
            {
                title: 'Woman Cook',
                value: '👩\u200d🍳'
            },
            {
                title: 'Firefighter',
                value: '🧑\u200d🚒'
            },
            {
                title: 'Man Firefighter',
                value: '👨\u200d🚒'
            },
            {
                title: 'Woman Firefighter',
                value: '👩\u200d🚒'
            },
            {
                title: 'People Holding Hands',
                value: '🧑\u200d🤝\u200d🧑'
            },
            {
                title: 'Woman and Man Holding Hands',
                value: '👫'
            },
            {
                title: 'Men Holding Hands',
                value: '👬'
            },
            {
                title: 'Women Holding Hands',
                value: '👭'
            },
            {
                title: 'Person in Manual Wheelchair',
                value: '🧑\u200d🦽'
            },
            {
                title: 'Man in Manual Wheelchair',
                value: '👨\u200d🦽'
            },
            {
                title: 'Woman in Manual Wheelchair',
                value: '👩\u200d🦽'
            },
            {
                title: 'Man in Motorized Wheelchair',
                value: '👨\u200d🦼'
            },
            {
                title: 'Woman in Motorized Wheelchair',
                value: '👩\u200d🦼'
            },
            {
                title: 'Person in Motorized Wheelchair',
                value: '🧑\u200d🦼'
            },
            {
                title: 'Artist',
                value: '🧑\u200d🎨'
            },
            {
                title: 'Man Artist',
                value: '👨\u200d🎨'
            },
            {
                title: 'Woman Artist',
                value: '👩\u200d🎨'
            },
            {
                title: 'Person with White Cane',
                value: '🧑\u200d🦯'
            },
            {
                title: 'Man with White Cane',
                value: '👨\u200d🦯'
            },
            {
                title: 'Woman with White Cane',
                value: '👩\u200d🦯'
            },
            {
                title: 'Astronaut',
                value: '🧑\u200d🚀'
            },
            {
                title: 'Man Astronaut',
                value: '👨\u200d🚀'
            },
            {
                title: 'Woman Astronaut',
                value: '👩\u200d🚀'
            },
            {
                title: 'Farmer',
                value: '🧑\u200d🌾'
            },
            {
                title: 'Man Farmer',
                value: '👨\u200d🌾'
            },
            {
                title: 'Woman Farmer',
                value: '👩\u200d🌾'
            },
            {
                title: 'Judge',
                value: '🧑\u200d⚖️'
            },
            {
                title: 'Man Judge',
                value: '👨\u200d⚖️'
            },
            {
                title: 'Woman Judge',
                value: '👩\u200d⚖️'
            },
            {
                title: 'Mechanic',
                value: '🧑\u200d🔧'
            },
            {
                title: 'Man Mechanic',
                value: '👨\u200d🔧'
            },
            {
                title: 'Woman Mechanic',
                value: '👩\u200d🔧'
            },
            {
                title: 'Office Worker',
                value: '🧑\u200d💼'
            },
            {
                title: 'Man Office Worker',
                value: '👨\u200d💼'
            },
            {
                title: 'Woman Office Worker',
                value: '👩\u200d💼'
            },
            {
                title: 'Health Worker',
                value: '🧑\u200d⚕️'
            },
            {
                title: 'Man Health Worker',
                value: '👨\u200d⚕️'
            },
            {
                title: 'Woman Health Worker',
                value: '👩\u200d⚕️'
            },
            {
                title: 'Technologist',
                value: '🧑\u200d💻'
            },
            {
                title: 'Man Technologist',
                value: '👨\u200d💻'
            },
            {
                title: 'Woman Technologist',
                value: '👩\u200d💻'
            },
            {
                title: 'Scientist',
                value: '🧑\u200d🔬'
            },
            {
                title: 'Man Scientist',
                value: '👨\u200d🔬'
            },
            {
                title: 'Woman Scientist',
                value: '👩\u200d🔬'
            },
            {
                title: 'Teacher',
                value: '🧑\u200d🏫'
            },
            {
                title: 'Man Teacher',
                value: '👨\u200d🏫'
            },
            {
                title: 'Woman Teacher',
                value: '👩\u200d🏫'
            },
            {
                title: 'Factory Worker',
                value: '🧑\u200d🏭'
            },
            {
                title: 'Man Factory Worker',
                value: '👨\u200d🏭'
            },
            {
                title: 'Woman Factory Worker',
                value: '👩\u200d🏭'
            },
            {
                title: 'Pilot',
                value: '🧑\u200d✈️'
            },
            {
                title: 'Man Pilot',
                value: '👨\u200d✈️'
            },
            {
                title: 'Woman Pilot',
                value: '👩\u200d✈️'
            }
        ]
    },
    {
        category: 'Objects Emoji Symbols',
        symbols: [
            {
                title: 'Pine Decoration',
                value: '🎍'
            },
            {
                title: 'School Satchel',
                value: '🎒'
            },
            {
                title: 'Graduation Cap',
                value: '🎓'
            },
            {
                title: 'Carp Streamer',
                value: '🎏'
            },
            {
                title: 'Fireworks',
                value: '🎆'
            },
            {
                title: 'Firework Sparkler',
                value: '🎇'
            },
            {
                title: 'Wind Chime',
                value: '🎐'
            },
            {
                title: 'Moon Viewing Ceremony',
                value: '🎑'
            },
            {
                title: 'Jack-o-lantern',
                value: '🎃'
            },
            {
                title: 'Christmas Tree',
                value: '🎄'
            },
            {
                title: 'Wrapped Present',
                value: '🎁'
            },
            {
                title: 'Tanabata Tree',
                value: '🎋'
            },
            {
                title: 'Party Popper',
                value: '🎉'
            },
            {
                title: 'Confetti Ball',
                value: '🎊'
            },
            {
                title: 'Balloon',
                value: '🎈'
            },
            {
                title: 'Crossed Flags',
                value: '🎌'
            },
            {
                title: 'Crystal Ball',
                value: '🔮'
            },
            {
                title: 'Movie Camera',
                value: '🎥'
            },
            {
                title: 'Camera',
                value: '📷'
            },
            {
                title: 'Video Camera',
                value: '📹'
            },
            {
                title: 'Videocassette',
                value: '📼'
            },
            {
                title: 'Optical Disc',
                value: '💿'
            },
            {
                title: 'DVD',
                value: '📀'
            },
            {
                title: 'Minidisc',
                value: '💽'
            },
            {
                title: 'Floppy Disk',
                value: '💾'
            },
            {
                title: 'Personal Computer',
                value: '💻'
            },
            {
                title: 'Mobile Phone',
                value: '📱'
            },
            {
                title: 'Telephone',
                value: '☎️'
            },
            {
                title: 'Telephone Receiver',
                value: '📞'
            },
            {
                title: 'Pager',
                value: '📟'
            },
            {
                title: 'Satellite',
                value: '🛰'
            },
            {
                title: 'Fax Machine',
                value: '📠'
            },
            {
                title: 'Satellite Antenna',
                value: '📡'
            },
            {
                title: 'Television',
                value: '📺'
            },
            {
                title: 'Radio',
                value: '📻'
            },
            {
                title: 'Speaker with Three Sound Waves',
                value: '🔊'
            },
            {
                title: 'Speaker with One Sound Wave',
                value: '🔉'
            },
            {
                title: 'Speaker',
                value: '🔈'
            },
            {
                title: 'Speaker with Cancellation Stroke',
                value: '🔇'
            },
            {
                title: 'Bell',
                value: '🔔'
            },
            {
                title: 'Bell with Cancellation Stroke',
                value: '🔕'
            },
            {
                title: 'Public Address Loudspeaker',
                value: '📢'
            },
            {
                title: 'Cheering Megaphone',
                value: '📣'
            },
            {
                title: 'Hourglass with Flowing Sand',
                value: '⏳'
            },
            {
                title: 'Hourglass',
                value: '⌛️'
            },
            {
                title: 'Alarm Clock',
                value: '⏰'
            },
            {
                title: 'Watch',
                value: '⌚️'
            },
            {
                title: 'Open Lock',
                value: '🔓'
            },
            {
                title: 'Lock',
                value: '🔒'
            },
            {
                title: 'Lock with Ink Pen',
                value: '🔏'
            },
            {
                title: 'Closed Lock with Key',
                value: '🔐'
            },
            {
                title: 'Key',
                value: '🔑'
            },
            {
                title: 'Right-Pointing Magnifying Glass',
                value: '🔎'
            },
            {
                title: 'Electric Light Bulb',
                value: '💡'
            },
            {
                title: 'Electric Torch',
                value: '🔦'
            },
            {
                title: 'High Brightness Symbol',
                value: '🔆'
            },
            {
                title: 'Low Brightness Symbol',
                value: '🔅'
            },
            {
                title: 'Electric Plug',
                value: '🔌'
            },
            {
                title: 'Battery',
                value: '🔋'
            },
            {
                title: 'Left-Pointing Magnifying Glass',
                value: '🔍'
            },
            {
                title: 'Bathtub',
                value: '🛁'
            },
            {
                title: 'Person Taking Bath',
                value: '🛀'
            },
            {
                title: 'Shower',
                value: '🚿'
            },
            {
                title: 'Toilet',
                value: '🚽'
            },
            {
                title: 'Wrench',
                value: '🔧'
            },
            {
                title: 'Nut and Bolt',
                value: '🔩'
            },
            {
                title: 'Hammer',
                value: '🔨'
            },
            {
                title: 'Door',
                value: '🚪'
            },
            {
                title: 'Smoking Symbol',
                value: '🚬'
            },
            {
                title: 'Bomb',
                value: '💣'
            },
            {
                title: 'Pistol',
                value: '🔫'
            },
            {
                title: 'Knife',
                value: '🔪'
            },
            {
                title: 'Pill',
                value: '💊'
            },
            {
                title: 'Syringe',
                value: '💉'
            },
            {
                title: 'Money Bag',
                value: '💰'
            },
            {
                title: 'Banknote with Yen Sign',
                value: '💴'
            },
            {
                title: 'Banknote with Dollar Sign',
                value: '💵'
            },
            {
                title: 'Banknote with Pound Sign',
                value: '💷'
            },
            {
                title: 'Banknote with Euro Sign',
                value: '💶'
            },
            {
                title: 'Credit Card',
                value: '💳'
            },
            {
                title: 'Money with Wings',
                value: '💸'
            },
            {
                title: 'Mobile Phone with Rightwards Arrow at Left',
                value: '📲'
            },
            {
                title: 'E-Mail Symbol',
                value: '📧'
            },
            {
                title: 'Inbox Tray',
                value: '📥'
            },
            {
                title: 'Outbox Tray',
                value: '📤'
            },
            {
                title: 'Envelope',
                value: '✉️'
            },
            {
                title: 'Envelope with Downwards Arrow Above',
                value: '📩'
            },
            {
                title: 'Incoming Envelope',
                value: '📨'
            },
            {
                title: 'Postal Horn',
                value: '📯'
            },
            {
                title: 'Closed Mailbox with Raised Flag',
                value: '📫'
            },
            {
                title: 'Closed Mailbox with Lowered Flag',
                value: '📪'
            },
            {
                title: 'Open Mailbox with Raised Flag',
                value: '📬'
            },
            {
                title: 'Open Mailbox with Lowered Flag',
                value: '📭'
            },
            {
                title: 'Postbox',
                value: '📮'
            },
            {
                title: 'Package',
                value: '📦'
            },
            {
                title: 'Memo',
                value: '📝'
            },
            {
                title: 'Page Facing Up',
                value: '📄'
            },
            {
                title: 'Page with Curl',
                value: '📃'
            },
            {
                title: 'Bookmark Tabs',
                value: '📑'
            },
            {
                title: 'Bar Chart',
                value: '📊'
            },
            {
                title: 'Chart with Upwards Trend',
                value: '📈'
            },
            {
                title: 'Chart with Downwards Trend',
                value: '📉'
            },
            {
                title: 'Scroll',
                value: '📜'
            },
            {
                title: 'Clipboard',
                value: '📋'
            },
            {
                title: 'Calendar',
                value: '📅'
            },
            {
                title: 'Tear-Off Calendar',
                value: '📆'
            },
            {
                title: 'Card Index',
                value: '📇'
            },
            {
                title: 'File Folder',
                value: '📁'
            },
            {
                title: 'Open File Folder',
                value: '📂'
            },
            {
                title: 'Scissors',
                value: '✂️'
            },
            {
                title: 'Pushpin',
                value: '📌'
            },
            {
                title: 'Paperclip',
                value: '📎'
            },
            {
                title: 'Fountain Pen',
                value: '✒️'
            },
            {
                title: 'Pencil',
                value: '✏️'
            },
            {
                title: 'Straight Ruler',
                value: '📏'
            },
            {
                title: 'Triangular Ruler',
                value: '📐'
            },
            {
                title: 'Closed Book',
                value: '📕'
            },
            {
                title: 'Green Book',
                value: '📗'
            },
            {
                title: 'Blue Book',
                value: '📘'
            },
            {
                title: 'Orange Book',
                value: '📙'
            },
            {
                title: 'Notebook',
                value: '📓'
            },
            {
                title: 'Notebook with Decorative Cover',
                value: '📔'
            },
            {
                title: 'Ledger',
                value: '📒'
            },
            {
                title: 'Books',
                value: '📚'
            },
            {
                title: 'Open Book',
                value: '📖'
            },
            {
                title: 'Bookmark',
                value: '🔖'
            },
            {
                title: 'Name Badge',
                value: '📛'
            },
            {
                title: 'Microscope',
                value: '🔬'
            },
            {
                title: 'Telescope',
                value: '🔭'
            },
            {
                title: 'Newspaper',
                value: '📰'
            },
            {
                title: 'Artist Palette',
                value: '🎨'
            },
            {
                title: 'Clapper Board',
                value: '🎬'
            },
            {
                title: 'Microphone',
                value: '🎤'
            },
            {
                title: 'Headphone',
                value: '🎧'
            },
            {
                title: 'Musical Score',
                value: '🎼'
            },
            {
                title: 'Musical Note',
                value: '🎵'
            },
            {
                title: 'Multiple Musical Notes',
                value: '🎶'
            },
            {
                title: 'Musical Keyboard',
                value: '🎹'
            },
            {
                title: 'Violin',
                value: '🎻'
            },
            {
                title: 'Trumpet',
                value: '🎺'
            },
            {
                title: 'Saxophone',
                value: '🎷'
            },
            {
                title: 'Guitar',
                value: '🎸'
            },
            {
                title: 'Alien Monster',
                value: '👾'
            },
            {
                title: 'Video Game',
                value: '🎮'
            },
            {
                title: 'Playing Card Black Joker',
                value: '🃏'
            },
            {
                title: 'Flower Playing Cards',
                value: '🎴'
            },
            {
                title: 'Mahjong Tile Red Dragon',
                value: '️🀄️'
            },
            {
                title: 'Amphora',
                value: '🏺'
            },
            {
                title: 'Prayer Beads',
                value: '📿'
            },
            {
                title: 'Kaaba',
                value: '🕋'
            },
            {
                title: 'Hammer and Pick',
                value: '⚒️'
            },
            {
                title: 'Crossed Swords',
                value: '⚔️'
            },
            {
                title: 'Scales',
                value: '⚖️'
            },
            {
                title: 'Alembic',
                value: '⚗️'
            },
            {
                title: 'Gear',
                value: '⚙️'
            },
            {
                title: 'Keyboard',
                value: '⌨️'
            },
            {
                title: 'Dark Sunglasses',
                value: '🕶'
            },
            {
                title: 'Stopwatch',
                value: '⏱'
            },
            {
                title: 'Timer Clock',
                value: '⏲'
            },
            {
                title: 'Umbrella',
                value: '☂️'
            },
            {
                title: 'Fleur-de-Lis',
                value: '⚜️'
            },
            {
                title: 'Coffin',
                value: '⚰️'
            },
            {
                title: 'Funeral Urn',
                value: '⚱️'
            },
            {
                title: 'Pick',
                value: '⛏'
            },
            {
                title: 'Helmet with White Cross',
                value: '⛑'
            },
            {
                title: 'Chains',
                value: '⛓'
            },
            {
                title: 'Shinto Shrine',
                value: '⛩'
            },
            {
                title: 'Umbrella on Ground',
                value: '⛱'
            },
            {
                title: 'Slot Machine',
                value: '🎰'
            },
            {
                title: 'Top Hat',
                value: '🎩'
            },
            {
                title: 'Crown',
                value: '👑'
            },
            {
                title: 'Woman\'s Hat',
                value: '👒'
            },
            {
                title: 'Briefcase',
                value: '💼'
            },
            {
                title: 'Handbag',
                value: '👜'
            },
            {
                title: 'Pouch',
                value: '👝'
            },
            {
                title: 'Purse',
                value: '👛'
            },
            {
                title: 'Eyeglasses',
                value: '👓'
            },
            {
                title: 'Ribbon',
                value: '🎀'
            },
            {
                title: 'Closed Umbrella',
                value: '🌂'
            },
            {
                title: 'Lipstick',
                value: '💄'
            },
            {
                title: 'Ring',
                value: '💍'
            },
            {
                title: 'Gem Stone',
                value: '💎'
            },
            {
                title: 'Footprints',
                value: '👣'
            },
            {
                title: 'Thermometer',
                value: '🌡'
            },
            {
                title: 'Military Medal',
                value: '🎖'
            },
            {
                title: 'Reminder Ribbon',
                value: '🎗'
            },
            {
                title: 'Studio Microphone',
                value: '🎙'
            },
            {
                title: 'Level Slider',
                value: '🎚'
            },
            {
                title: 'Control Knobs',
                value: '🎛'
            },
            {
                title: 'Film Frames',
                value: '🎞'
            },
            {
                title: 'Admission Tickets',
                value: '🎟'
            },
            {
                title: 'Sports Medal',
                value: '🏅'
            },
            {
                title: 'Snow Capped Mountain',
                value: '🏔'
            },
            {
                title: 'Camping',
                value: '🏕'
            },
            {
                title: 'Beach with Umbrella',
                value: '🏖'
            },
            {
                title: 'Rosette',
                value: '🏵'
            },
            {
                title: 'Label',
                value: '🏷'
            },
            {
                title: 'Camera with Flash',
                value: '📸'
            },
            {
                title: 'Film Projector',
                value: '📽'
            },
            {
                title: 'Om Symbol',
                value: '🕉'
            },
            {
                title: 'Dove of Peace',
                value: '🕊'
            },
            {
                title: 'Candle',
                value: '🕯'
            },
            {
                title: 'Mantelpiece Clock',
                value: '🕰'
            },
            {
                title: 'Hole',
                value: '🕳'
            },
            {
                title: 'Spider Web',
                value: '🕸'
            },
            {
                title: 'Joystick',
                value: '🕹'
            },
            {
                title: 'Pile of Poo',
                value: '💩'
            },
            {
                title: 'Linked Paperclips',
                value: '🖇'
            },
            {
                title: 'Lower Left Ballpoint Pen',
                value: '🖊'
            },
            {
                title: 'Lower Left Fountain Pen',
                value: '🖋'
            },
            {
                title: 'Lower Left Paintbrush',
                value: '🖌'
            },
            {
                title: 'Lower Left Crayon',
                value: '🖍'
            },
            {
                title: 'Desktop Computer',
                value: '🖥'
            },
            {
                title: 'Printer',
                value: '🖨'
            },
            {
                title: 'Three Button Mouse',
                value: '🖱'
            },
            {
                title: 'Trackball',
                value: '🖲'
            },
            {
                title: 'Frame with Picture',
                value: '🖼'
            },
            {
                title: 'Card Index Dividers',
                value: '🗂'
            },
            {
                title: 'Card File Box',
                value: '🗃'
            },
            {
                title: 'File Cabinet',
                value: '🗄'
            },
            {
                title: 'Wastebasket',
                value: '🗑'
            },
            {
                title: 'Spiral Note Pad',
                value: '🗒'
            },
            {
                title: 'Spiral Calendar Pad',
                value: '🗓'
            },
            {
                title: 'Compression',
                value: '🗜'
            },
            {
                title: 'Old Key',
                value: '🗝'
            },
            {
                title: 'Rolled-up Newspaper',
                value: '🗞'
            },
            {
                title: 'Dagger Knife',
                value: '🗡'
            },
            {
                title: 'Ballot Box with Ballot',
                value: '🗳'
            },
            {
                title: 'World Map',
                value: '🗺'
            },
            {
                title: 'Couch and Lamp',
                value: '🛋'
            },
            {
                title: 'Person in Bed',
                value: '🛌'
            },
            {
                title: 'Shopping Bags',
                value: '🛍'
            },
            {
                title: 'Bellhop Bell',
                value: '🛎'
            },
            {
                title: 'Bed',
                value: '🛏'
            },
            {
                title: 'Hammer and Wrench',
                value: '🛠'
            },
            {
                title: 'Shield',
                value: '🛡'
            },
            {
                title: 'Oil Drum',
                value: '🛢'
            },
            {
                title: 'Athletic Shoe',
                value: '👟'
            },
            {
                title: 'Man\'s Shoe',
                value: '👞'
            },
            {
                title: 'Woman\'s Sandal',
                value: '👡'
            },
            {
                title: 'High-Heeled Shoe',
                value: '👠'
            },
            {
                title: 'Woman\'s Boots',
                value: '👢'
            },
            {
                title: 'T-Shirt',
                value: '👕'
            },
            {
                title: 'Necktie',
                value: '👔'
            },
            {
                title: 'Blouse',
                value: '👚'
            },
            {
                title: 'Dress',
                value: '👗'
            },
            {
                title: 'Running Shirt with Sash',
                value: '🎽'
            },
            {
                title: 'Jeans',
                value: '👖'
            },
            {
                title: 'Kimono',
                value: '👘'
            },
            {
                title: 'Bikini',
                value: '👙'
            },
            {
                title: 'Izakaya Lantern',
                value: '🏮'
            },
            {
                title: 'Circus Tent',
                value: '🎪'
            },
            {
                title: 'Performing Arts',
                value: '🎭'
            },
            {
                title: 'Round Pushpin',
                value: '📍'
            },
            {
                title: 'Fuel Pump',
                value: '⛽️'
            },
            {
                title: 'Clinking Glasses',
                value: '🥂'
            },
            {
                title: 'Tumbler Glass',
                value: '🥃'
            },
            {
                title: 'Spoon',
                value: '🥄'
            },
            {
                title: 'Drum With Drumsticks',
                value: '🥁'
            },
            {
                title: 'Shopping Trolley',
                value: '🛒'
            },
            {
                title: 'Brain',
                value: '🧠'
            },
            {
                title: 'Scarf',
                value: '🧣'
            },
            {
                title: 'Gloves',
                value: '🧤'
            },
            {
                title: 'Coat',
                value: '🧥'
            },
            {
                title: 'Socks',
                value: '🧦'
            },
            {
                title: 'Billed Cap',
                value: '🧢'
            },
            {
                title: 'Compass',
                value: '🧭️'
            },
            {
                title: 'Brick',
                value: '🧱️'
            },
            {
                title: 'Luggage',
                value: '🧳️'
            },
            {
                title: 'Firecracker',
                value: '🧨️'
            },
            {
                title: 'Red Envelope',
                value: '🧧️'
            },
            {
                title: 'Softball',
                value: '🥎️'
            },
            {
                title: 'Flying Disc',
                value: '🥏️'
            },
            {
                title: 'Lacrosse',
                value: '🥍️'
            },
            {
                title: 'Nazar amulet',
                value: '🧿️'
            },
            {
                title: 'Puzzle Piece',
                value: '🧩️'
            },
            {
                title: 'Teddy Bear',
                value: '🧸️'
            },
            {
                title: 'Chess Pawn',
                value: '♟️'
            },
            {
                title: 'Thread',
                value: '🧵'
            },
            {
                title: 'Yarn',
                value: '🧶'
            },
            {
                title: 'Goggles',
                value: '🥽'
            },
            {
                title: 'Lab Coat',
                value: '🥼'
            },
            {
                title: 'Hiking Boot',
                value: '🥾'
            },
            {
                title: 'Flat Shoe',
                value: '🥿️'
            },
            {
                title: 'Abacus',
                value: '🧮️'
            },
            {
                title: 'Receipt',
                value: '🧾️'
            },
            {
                title: 'Toolbox',
                value: '🧰️️'
            },
            {
                title: 'Magnet',
                value: '🧲️️'
            },
            {
                title: 'Test Tube',
                value: '🧪️️'
            },
            {
                title: 'Petri Dish',
                value: '🧫️️'
            },
            {
                title: 'DNA',
                value: '🧬️️'
            },
            {
                title: 'Lotion Bottle',
                value: '🧴️️'
            },
            {
                title: 'Safety Pin',
                value: '🧷️️'
            },
            {
                title: 'Broom',
                value: '🧹️️'
            },
            {
                title: 'Basket',
                value: '🧺️️'
            },
            {
                title: 'Roll of Paper',
                value: '🧻️️'
            },
            {
                title: 'Soap',
                value: '🧼️️'
            },
            {
                title: 'Sponge',
                value: '🧽️️'
            },
            {
                title: 'Fire Extinguisher',
                value: '🧯️️'
            },
            {
                title: 'Barber Pole',
                value: '💈'
            },
            {
                title: 'Baby Bottle',
                value: '🍼'
            },
            {
                title: 'Roller Skate',
                value: '🛼'
            },
            {
                title: 'Teapot',
                value: '🫖'
            },
            {
                title: 'Magic Wand',
                value: '🪄'
            },
            {
                title: 'Military Helmet',
                value: '🪖'
            },
            {
                title: 'Mirror',
                value: '🪞'
            },
            {
                title: 'Mouse Trap',
                value: '🪤'
            },
            {
                title: 'Nesting Dolls',
                value: '🪆'
            },
            {
                title: 'Knot',
                value: '🪢'
            },
            {
                title: 'Ladder',
                value: '🪜'
            },
            {
                title: 'Long Drum',
                value: '🪘'
            },
            {
                title: 'Hook',
                value: '🪝'
            },
            {
                title: 'Window',
                value: '🪟'
            },
            {
                title: 'Thong Sandal',
                value: '🩴'
            },
            {
                title: 'Toothbrush',
                value: '🪥'
            },
            {
                title: 'Screwdriver',
                value: '🪛'
            },
            {
                title: 'Piñata',
                value: '🪅'
            },
            {
                title: 'Placard',
                value: '🪧'
            },
            {
                title: 'Plunger',
                value: '🪠'
            },
            {
                title: 'Sewing Needle',
                value: '🪡'
            },
            {
                title: 'Feather',
                value: '🪶'
            },
            {
                title: 'Boomerang',
                value: '🪃'
            },
            {
                title: 'Bucket',
                value: '🪣'
            },
            {
                title: 'Accordion',
                value: '🪗'
            },
            {
                title: 'Carpentry Saw',
                value: '🪚'
            },
            {
                title: 'Coin',
                value: '🪙'
            }
        ]
    },
    {
        category: 'Nature Emoji Symbols',
        symbols: [
            {
                title: 'Bouquet',
                value: '💐'
            },
            {
                title: 'Cherry Blossom',
                value: '🌸'
            },
            {
                title: 'Tulip',
                value: '🌷'
            },
            {
                title: 'Four Leaf Clover',
                value: '🍀'
            },
            {
                title: 'Rose',
                value: '🌹'
            },
            {
                title: 'Wilted Flower',
                value: '🥀'
            },
            {
                title: 'Sunflower',
                value: '🌻'
            },
            {
                title: 'Hibiscus',
                value: '🌺'
            },
            {
                title: 'Maple Leaf',
                value: '🍁'
            },
            {
                title: 'Leaf Fluttering in Wind',
                value: '🍃'
            },
            {
                title: 'Fallen Leaf',
                value: '🍂'
            },
            {
                title: 'Herb',
                value: '🌿'
            },
            {
                title: 'Ear of Rice',
                value: '🌾'
            },
            {
                title: 'Mushroom',
                value: '🍄'
            },
            {
                title: 'Cactus',
                value: '🌵'
            },
            {
                title: 'Palm Tree',
                value: '🌴'
            },
            {
                title: 'Evergreen Tree',
                value: '🌲'
            },
            {
                title: 'Deciduous Tree',
                value: '🌳'
            },
            {
                title: 'Chestnut',
                value: '🌰'
            },
            {
                title: 'Seedling',
                value: '🌱'
            },
            {
                title: 'Blossom',
                value: '🌼'
            },
            {
                title: 'Globe with Meridians',
                value: '🌐'
            },
            {
                title: 'Sun with Face',
                value: '🌞'
            },
            {
                title: 'Full Moon with Face',
                value: '🌝'
            },
            {
                title: 'New Moon with Face',
                value: '🌚'
            },
            {
                title: 'New Moon Symbol',
                value: '🌑'
            },
            {
                title: 'Waxing Crescent Moon Symbol',
                value: '🌒'
            },
            {
                title: 'First Quarter Moon Symbol',
                value: '🌓'
            },
            {
                title: 'First Quarter Moon Symbol',
                value: '🌓'
            },
            {
                title: 'Waxing Gibbous Moon Symbol',
                value: '🌔'
            },
            {
                title: 'Full Moon Symbol',
                value: '🌕'
            },
            {
                title: 'Waning Gibbous Moon',
                value: '🌖'
            },
            {
                title: 'Last Quarter Moon Symbol',
                value: '🌗'
            },
            {
                title: 'Waning Crescent Moon Symbol',
                value: '🌘'
            },
            {
                title: 'Last Quarter Moon with Face',
                value: '🌜'
            },
            {
                title: 'First Quarter Moon with Face',
                value: '🌛'
            },
            {
                title: 'Crescent Moon',
                value: '🌙'
            },
            {
                title: 'Fire',
                value: '🔥'
            },
            {
                title: 'Droplet',
                value: '💧'
            }
        ]
    },
    {
        category: 'Symbols Emoji',
        symbols: [
            {
                title: 'Infinity',
                value: '♾️'
            },
            {
                title: 'Female Sign',
                value: '♀️'
            },
            {
                title: 'Male Sign',
                value: '♂️'
            },
            {
                title: 'Transgender Symbol',
                value: '⚧️'
            },
            {
                title: 'Medical Symbol',
                value: '⚕️'
            },
            {
                title: 'Curly Loop',
                value: '➰'
            },
            {
                title: 'Double Curly Loop',
                value: '➿'
            },
            {
                title: 'Dollar Sign',
                value: '💲'
            },
            {
                title: 'Currency Exchange',
                value: '💱'
            },
            {
                title: 'Copyright Sign',
                value: '©️'
            },
            {
                title: 'Registered Sign',
                value: '®️'
            },
            {
                title: 'Trade Mark Sign',
                value: '™️'
            },
            {
                title: 'Wavy Dash',
                value: '〰️'
            },
            {
                title: 'Input Symbol for Uppercase Letters',
                value: '🔠'
            },
            {
                title: 'Input Symbol for Lowercase Letters',
                value: '🔡'
            },
            {
                title: 'Input Symbol for Letters',
                value: '🔤'
            },
            {
                title: 'Information Source',
                value: 'ℹ️'
            },
            {
                title: 'OK Sign',
                value: '🆗'
            },
            {
                title: 'New Sign',
                value: '🆕'
            },
            {
                title: 'Up Sign',
                value: '🆙'
            },
            {
                title: 'Cool Sign',
                value: '🆒'
            },
            {
                title: 'Free Sign',
                value: '🆓'
            },
            {
                title: 'No Good Sign',
                value: '🆖'
            },
            {
                title: 'Cellular Signal Bars',
                value: '📶'
            },
            {
                title: 'Cinema',
                value: '🎦'
            },
            {
                title: 'Restroom',
                value: '🚻'
            },
            {
                title: 'Men\'s Symbol',
                value: '🚹'
            },
            {
                title: 'Women\'s Symbol',
                value: '🚺'
            },
            {
                title: 'Baby Symbol',
                value: '🚼'
            },
            {
                title: 'Water Closet',
                value: '🚾'
            },
            {
                title: 'Put Litter in its Place Symbol',
                value: '🚮'
            },
            {
                title: 'Parking Sign',
                value: '🅿️'
            },
            {
                title: 'Wheelchair Symbol',
                value: '♿️'
            },
            {
                title: 'Circled M',
                value: 'Ⓜ️'
            },
            {
                title: 'Passport Control',
                value: '🛂'
            },
            {
                title: 'Baggage Claim',
                value: '🛄'
            },
            {
                title: 'Left Luggage',
                value: '🛅'
            },
            {
                title: 'Customs',
                value: '🛃'
            },
            {
                title: 'CL Sign',
                value: '🆑'
            },
            {
                title: 'SOS Sign',
                value: '🆘'
            },
            {
                title: 'Squared VS',
                value: '🆚'
            },
            {
                title: 'No Entry Sign',
                value: '🚫'
            },
            {
                title: 'No Smoking Sign',
                value: '🚭'
            },
            {
                title: 'No One Under Eighteen Symbol',
                value: '🔞'
            },
            {
                title: 'No Mobile Phones',
                value: '📵'
            },
            {
                title: 'Do Not Litter Symbol',
                value: '🚯'
            },
            {
                title: 'Non-Potable Water Symbol',
                value: '🚱'
            },
            {
                title: 'No Bicycles',
                value: '🚳'
            },
            {
                title: 'No Pedestrians',
                value: '🚷'
            },
            {
                title: 'Children Crossing',
                value: '🚸'
            },
            {
                title: 'No Entry',
                value: '⛔️'
            },
            {
                title: 'Warning Sign',
                value: '⚠️'
            },
            {
                title: 'Octagonal Sign',
                value: '🛑'
            },
            {
                title: 'Construction Sign',
                value: '🚧'
            },
            {
                title: 'Eight Spoked Asterisk',
                value: '✳️'
            },
            {
                title: 'Sparkle',
                value: '❇️'
            },
            {
                title: 'X Mark',
                value: '❎'
            },
            {
                title: 'Check Mark Button',
                value: '✅'
            },
            {
                title: 'Eight Pointed Star',
                value: '✴️'
            },
            {
                title: 'Heart Decoration',
                value: '💟'
            },
            {
                title: 'Vibration Mode',
                value: '📳'
            },
            {
                title: 'Mobile Phone Off',
                value: '📴'
            },
            {
                title: 'Blood Type A',
                value: '🅰️'
            },
            {
                title: 'Blood Type B',
                value: '🅱️'
            },
            {
                title: 'Blood Type AB',
                value: '🆎'
            },
            {
                title: 'Blood Type O',
                value: '🅾️'
            },
            {
                title: 'Diamond Shape with a Dot Inside',
                value: '💠'
            },
            {
                title: 'Recycling Symbol',
                value: '♻️'
            },
            {
                title: 'ATM Sign',
                value: '🏧'
            },
            {
                title: 'Potable Water Symbol',
                value: '🚰'
            },
            {
                title: 'Chart with Upwards Trend and Yen Sign',
                value: '💹'
            },
            {
                title: 'Part Alternation Mark',
                value: '〽️'
            },
            {
                title: 'Cross Mark',
                value: '❌'
            },
            {
                title: 'Circle Mark',
                value: '⭕️'
            },
            {
                title: 'Exclamation Mark',
                value: '❗️'
            },
            {
                title: 'Question Mark',
                value: '❓'
            },
            {
                title: 'White Exclamation Mark',
                value: '❕'
            },
            {
                title: 'White Question Mark',
                value: '❔'
            },
            {
                title: 'Twelve O\'Clock',
                value: '🕛'
            },
            {
                title: 'Twelve-Thirty',
                value: '🕧'
            },
            {
                title: 'One O\'Clock',
                value: '🕐'
            },
            {
                title: 'One-Thirty',
                value: '🕜'
            },
            {
                title: 'Two O\'Clock',
                value: '🕑'
            },
            {
                title: 'Two-Thirty',
                value: '🕝'
            },
            {
                title: 'Three O\'Clock',
                value: '🕒'
            },
            {
                title: 'Three-Thirty',
                value: '🕞'
            },
            {
                title: 'Four O\'Clock',
                value: '🕓'
            },
            {
                title: 'Four-Thirty',
                value: '🕟'
            },
            {
                title: 'Five O\'Clock',
                value: '🕔'
            },
            {
                title: 'Five-Thirty',
                value: '🕠'
            },
            {
                title: 'Six O\'Clock',
                value: '🕕'
            },
            {
                title: 'Seven O\'Clock',
                value: '🕖'
            },
            {
                title: 'Eight O\'Clock',
                value: '🕗'
            },
            {
                title: 'Nine O\'Clock',
                value: '🕘'
            },
            {
                title: 'Ten O\'Clock',
                value: '🕙'
            },
            {
                title: 'Eleven O\'Clock',
                value: '🕚'
            },
            {
                title: 'Six-Thirty',
                value: '🕡'
            },
            {
                title: 'Seven-Thirty',
                value: '🕢'
            },
            {
                title: 'Eight-Thirty',
                value: '🕣'
            },
            {
                title: 'Nine-Thirty',
                value: '🕤'
            },
            {
                title: 'Ten-Thirty',
                value: '🕥'
            },
            {
                title: 'Eleven-Thirty',
                value: '🕦'
            },
            {
                title: 'Keycap Digit One',
                value: '1⃣'
            },
            {
                title: 'Keycap Digit Two',
                value: '2⃣'
            },
            {
                title: 'Keycap Digit Three',
                value: '3⃣'
            },
            {
                title: 'Keycap Digit Four',
                value: '4⃣'
            },
            {
                title: 'Keycap Digit Five',
                value: '5⃣'
            },
            {
                title: 'Keycap Digit Six',
                value: '6⃣'
            },
            {
                title: 'Keycap Digit Seven',
                value: '7⃣'
            },
            {
                title: 'Keycap Digit Eight',
                value: '8⃣'
            },
            {
                title: 'Keycap Digit Nine',
                value: '9⃣'
            },
            {
                title: 'Keycap Digit Zero',
                value: '0⃣'
            },
            {
                title: 'Keycap Digit Ten',
                value: '🔟'
            },
            {
                title: 'Input Symbol for Numbers',
                value: '🔢'
            },
            {
                title: 'Keycap Number Sign',
                value: '#️⃣'
            },
            {
                title: 'Input Symbol for Symbols',
                value: '🔣'
            },
            {
                title: 'Skip Forward Symbol',
                value: '⏭'
            },
            {
                title: 'Skip Backward Symbol',
                value: '⏮'
            },
            {
                title: 'Play/Pause Symbol',
                value: '⏯'
            },
            {
                title: 'Keycap Asterisk',
                value: '*️⃣'
            },
            {
                title: 'Pause Symbol',
                value: '⏸'
            },
            {
                title: 'Stop Symbol',
                value: '⏹'
            },
            {
                title: 'Record Symbol',
                value: '⏺'
            },
            {
                title: 'Eject Symbol',
                value: '⏏️'
            },
            {
                title: 'Radioactive Sign',
                value: '☢️'
            },
            {
                title: 'Biohazard Sign',
                value: '☣️'
            },
            {
                title: 'Plus Symbol',
                value: '➕'
            },
            {
                title: 'Minus Symbol',
                value: '➖'
            },
            {
                title: 'Multiplication Symbol',
                value: '✖️'
            },
            {
                title: 'Division Sign',
                value: '➗'
            },
            {
                title: 'Heart Suit',
                value: '♥️'
            },
            {
                title: 'Diamonds Suit',
                value: '♦️'
            },
            {
                title: 'Clubs Suit',
                value: '♣️'
            },
            {
                title: 'Spades Suit',
                value: '♠️'
            },
            {
                title: 'White Flower',
                value: '💮'
            },
            {
                title: 'Hundred Points Symbol',
                value: '💯'
            },
            {
                title: 'Dizzy Symbol',
                value: '💫'
            },
            {
                title: 'Collision Symbol',
                value: '💥'
            },
            {
                title: 'Anger Symbol',
                value: '💢'
            },
            {
                title: 'Splashing Sweat Symbol',
                value: '💦'
            },
            {
                title: 'Sleeping Symbol',
                value: '💤'
            },
            {
                title: 'Dash Symbol',
                value: '💨'
            },
            {
                title: 'Check Mark',
                value: '✔️'
            },
            {
                title: 'Ballot Box with Check',
                value: '☑️'
            },
            {
                title: 'Radio Button',
                value: '🔘'
            },
            {
                title: 'Link Symbol',
                value: '🔗'
            },
            {
                title: 'Trident Emblem',
                value: '🔱'
            },
            {
                title: 'Black Square Button',
                value: '🔲'
            },
            {
                title: 'White Square Button',
                value: '🔳'
            },
            {
                title: 'White Large Square',
                value: '⬜️'
            },
            {
                title: 'Black Large Square',
                value: '⬛️'
            },
            {
                title: 'Black Medium Square',
                value: '◼️'
            },
            {
                title: 'White Medium Square',
                value: '◻️'
            },
            {
                title: 'Black Medium Small Square',
                value: '◾️'
            },
            {
                title: 'White Medium Small Square',
                value: '◽️'
            },
            {
                title: 'Black Small Square',
                value: '▪️'
            },
            {
                title: 'White Small Square',
                value: '▫️'
            },
            {
                title: 'Red Triangle Pointed Up',
                value: '🔺'
            },
            {
                title: 'Medium Black Circle',
                value: '⚫️'
            },
            {
                title: 'Medium White Circle',
                value: '⚪️'
            },
            {
                title: 'Red Circle',
                value: '🔴'
            },
            {
                title: 'Blue Circle',
                value: '🔵'
            },
            {
                title: 'Down-Pointing Red Triangle',
                value: '🔻'
            },
            {
                title: 'Large Orange Diamond',
                value: '🔶'
            },
            {
                title: 'Large Blue Diamond',
                value: '🔷'
            },
            {
                title: 'Small Orange Diamond',
                value: '🔸'
            },
            {
                title: 'Small Blue Diamond',
                value: '🔹'
            },
            {
                title: 'Exclamation Question Mark',
                value: '⁉️'
            },
            {
                title: 'Double Exclamation Mark',
                value: '‼️'
            },
            {
                title: 'Japanese Symbol for Beginner',
                value: '🔰'
            },
            {
                title: 'Identification Sign',
                value: '🆔'
            },
            {
                title: 'Keycap Asterisk',
                value: '*️⃣'
            },
            {
                title: 'Elevator',
                value: '🛗'
            },
            {
                title: 'Peace Symbol',
                value: '☮️'
            },
            {
                title: 'Place of Worship',
                value: '🛐'
            },
            {
                title: 'Menorah with Nine Branches',
                value: '🕎'
            },
            {
                title: 'Latin Cross',
                value: '✝️'
            },
            {
                title: 'Star of David',
                value: '✡️'
            },
            {
                title: 'Orthodox Cross',
                value: '☦️'
            },
            {
                title: 'Star and Crescent',
                value: '☪️'
            },
            {
                title: 'Yin Yang',
                value: '☯️'
            },
            {
                title: 'Wheel of Dharma',
                value: '☸️'
            },
            {
                title: 'Atom Symbol',
                value: '⚛️'
            },
            {
                title: 'Aries',
                value: '♈️'
            },
            {
                title: 'Taurus',
                value: '♉️'
            },
            {
                title: 'Gemini',
                value: '♊️'
            },
            {
                title: 'Cancer',
                value: '♋️'
            },
            {
                title: 'Leo',
                value: '♌️'
            },
            {
                title: 'Virgo',
                value: '♍️'
            },
            {
                title: 'Libra',
                value: '♎️'
            },
            {
                title: 'Scorpius',
                value: '♏️'
            },
            {
                title: 'Sagittarius',
                value: '♐️'
            },
            {
                title: 'Capricorn',
                value: '♑️'
            },
            {
                title: 'Aquarius',
                value: '♒️'
            },
            {
                title: 'Pisces',
                value: '♓️'
            },
            {
                title: 'Ophiuchus',
                value: '⛎'
            },
            {
                title: 'Six Pointed Star with Middle Dot',
                value: '🔯'
            },
            {
                title: 'Regional Indicator Symbol Letter A',
                value: '🇦'
            },
            {
                title: 'Regional Indicator Symbol Letter B',
                value: '🇧'
            },
            {
                title: 'Regional Indicator Symbol Letter C',
                value: '🇨'
            },
            {
                title: 'Regional Indicator Symbol Letter D',
                value: '🇩'
            },
            {
                title: 'Regional Indicator Symbol Letter E',
                value: '🇪'
            },
            {
                title: 'Regional Indicator Symbol Letter F',
                value: '🇫'
            },
            {
                title: 'Regional Indicator Symbol Letter G',
                value: '🇬'
            },
            {
                title: 'Regional Indicator Symbol Letter H',
                value: '🇭'
            },
            {
                title: 'Regional Indicator Symbol Letter I',
                value: '🇮'
            },
            {
                title: 'Regional Indicator Symbol Letter J',
                value: '🇯'
            },
            {
                title: 'Regional Indicator Symbol Letter K',
                value: '🇰'
            },
            {
                title: 'Regional Indicator Symbol Letter L',
                value: '🇱'
            },
            {
                title: 'Regional Indicator Symbol Letter M',
                value: '🇲'
            },
            {
                title: 'Regional Indicator Symbol Letter N',
                value: '🇳'
            },
            {
                title: 'Regional Indicator Symbol Letter O',
                value: '🇴'
            },
            {
                title: 'Regional Indicator Symbol Letter P',
                value: '🇵'
            },
            {
                title: 'Regional Indicator Symbol Letter Q',
                value: '🇶'
            },
            {
                title: 'Regional Indicator Symbol Letter R',
                value: '🇷'
            },
            {
                title: 'Regional Indicator Symbol Letter S',
                value: '🇸'
            },
            {
                title: 'Regional Indicator Symbol Letter T',
                value: '🇹'
            },
            {
                title: 'Regional Indicator Symbol Letter U',
                value: '🇺'
            },
            {
                title: 'Regional Indicator Symbol Letter V',
                value: '🇻'
            },
            {
                title: 'Regional Indicator Symbol Letter W',
                value: '🇼'
            },
            {
                title: 'Regional Indicator Symbol Letter X',
                value: '🇽'
            },
            {
                title: 'Regional Indicator Symbol Letter Y',
                value: '🇾'
            },
            {
                title: 'Regional Indicator Symbol Letter Z',
                value: '🇿'
            },
            {
                title: 'Japanese Word Sign Meaning “Here”',
                value: '🈁'
            },
            {
                title: 'Japanese Sign Meaning “Reserved”',
                value: '🈯️'
            },
            {
                title: 'Japanese Sign Meaning “Vacancy”',
                value: '🈳'
            },
            {
                title: 'Japanese Sign Meaning “Full; No Vacancy”',
                value: '🈵'
            },
            {
                title: 'Japanese Sign Meaning “Passing (Grade)”',
                value: '🈴'
            },
            {
                title: 'Japanese Sign Meaning “Prohibited”',
                value: '🈲'
            },
            {
                title: 'Japanese Sign Meaning “Bargain”',
                value: '🉐'
            },
            {
                title: 'Japanese Sign Meaning “Discount”',
                value: '🈹'
            },
            {
                title: 'Japanese Sign Meaning “Open for Business”',
                value: '🈺'
            },
            {
                title: 'Japanese Sign Meaning “Not Free of Charge”',
                value: '🈶'
            },
            {
                title: 'Japanese Sign Meaning “Free of Charge”',
                value: '🈚️'
            },
            {
                title: 'Japanese Sign Meaning “Monthly Amount”',
                value: '🈷️'
            },
            {
                title: 'Japanese Sign Meaning “Application”',
                value: '🈸'
            },
            {
                title: 'Japanese Sign Meaning “Service” or “Service Charge”',
                value: '🈂️'
            },
            {
                title: 'Japanese Sign Meaning “Acceptable”',
                value: '🉑'
            },
            {
                title: 'Japanese Sign Meaning “Secret”',
                value: '㊙️'
            },
            {
                title: 'Japanese Sign Meaning “Congratulations”',
                value: '㊗️'
            }
        ]
    },
    {
        category: 'Food & Drink Emoji Symbols',
        symbols: [
            {
                title: 'Hot Beverage',
                value: '☕️'
            },
            {
                title: 'Teacup without Handle',
                value: '🍵'
            },
            {
                title: 'Sake Bottle and Cup',
                value: '🍶'
            },
            {
                title: 'Beer Mug',
                value: '🍺'
            },
            {
                title: 'Clinking Beer Mugs',
                value: '🍻'
            },
            {
                title: 'Cocktail Glass',
                value: '🍸'
            },
            {
                title: 'Tropical Drink',
                value: '🍹'
            },
            {
                title: 'Wine Glass',
                value: '🍷'
            },
            {
                title: 'Fork and Knife',
                value: '🍴'
            },
            {
                title: 'Pizza',
                value: '🍕'
            },
            {
                title: 'Hamburger',
                value: '🍔'
            },
            {
                title: 'French Fries',
                value: '🍟'
            },
            {
                title: 'Poultry Leg',
                value: '🍗'
            },
            {
                title: 'Meat on Bone',
                value: '🍖'
            },
            {
                title: 'Spaghetti',
                value: '🍝'
            },
            {
                title: 'Curry and Rice',
                value: '🍛'
            },
            {
                title: 'Fried Shrimp',
                value: '🍤'
            },
            {
                title: 'Bento Box',
                value: '🍱'
            },
            {
                title: 'Sushi',
                value: '🍣'
            },
            {
                title: 'Fish Cake with Swirl Design',
                value: '🍥'
            },
            {
                title: 'Rice Ball',
                value: '🍙'
            },
            {
                title: 'Rice Cracker',
                value: '🍘'
            },
            {
                title: 'Cooked Rice',
                value: '🍚'
            },
            {
                title: 'Steaming Bowl',
                value: '🍜'
            },
            {
                title: 'Pot of Food',
                value: '🍲'
            },
            {
                title: 'Oden',
                value: '🍢'
            },
            {
                title: 'Dango',
                value: '🍡'
            },
            {
                title: 'Cooking',
                value: '🍳'
            },
            {
                title: 'Bread',
                value: '🍞'
            },
            {
                title: 'Doughnut',
                value: '🍩'
            },
            {
                title: 'Custard',
                value: '🍮'
            },
            {
                title: 'Soft Ice Cream',
                value: '🍦'
            },
            {
                title: 'Ice Cream',
                value: '🍨'
            },
            {
                title: 'Shaved Ice',
                value: '🍧'
            },
            {
                title: 'Birthday Cake',
                value: '🎂'
            },
            {
                title: 'Shortcake',
                value: '🍰'
            },
            {
                title: 'Cookie',
                value: '🍪'
            },
            {
                title: 'Chocolate Bar',
                value: '🍫'
            },
            {
                title: 'Candy',
                value: '🍬'
            },
            {
                title: 'Lollipop',
                value: '🍭'
            },
            {
                title: 'Honey Pot',
                value: '🍯'
            },
            {
                title: 'Red Apple',
                value: '🍎'
            },
            {
                title: 'Green Apple',
                value: '🍏'
            },
            {
                title: 'Tangerine',
                value: '🍊'
            },
            {
                title: 'Lemon',
                value: '🍋'
            },
            {
                title: 'Cherries',
                value: '🍒'
            },
            {
                title: 'Grapes',
                value: '🍇'
            },
            {
                title: 'Watermelon',
                value: '🍉'
            },
            {
                title: 'Strawberry',
                value: '🍓'
            },
            {
                title: 'Peach',
                value: '🍑'
            },
            {
                title: 'Melon',
                value: '🍈'
            },
            {
                title: 'Banana',
                value: '🍌'
            },
            {
                title: 'Pear',
                value: '🍐'
            },
            {
                title: 'Pineapple',
                value: '🍍'
            },
            {
                title: 'Roasted Sweet Potato',
                value: '🍠'
            },
            {
                title: 'Eggplant',
                value: '🍆'
            },
            {
                title: 'Tomato',
                value: '🍅'
            },
            {
                title: 'Ear of Maize',
                value: '🌽'
            },
            {
                title: 'Hot Pepper',
                value: '🌶'
            },
            {
                title: 'Hot Dog',
                value: '🌭'
            },
            {
                title: 'Taco',
                value: '🌮'
            },
            {
                title: 'Burrito',
                value: '🌯'
            },
            {
                title: 'Bottle with Popping Cork',
                value: '🍾'
            },
            {
                title: 'Popcorn',
                value: '🍿'
            },
            {
                title: 'Kiwifruit',
                value: '🥝'
            },
            {
                title: 'Avocado',
                value: '🥑'
            },
            {
                title: 'Potato',
                value: '🥔'
            },
            {
                title: 'Carrot',
                value: '🥕'
            },
            {
                title: 'Cucumber',
                value: '🥒'
            },
            {
                title: 'Peanuts',
                value: '🥜'
            },
            {
                title: 'Croissant',
                value: '🥐'
            },
            {
                title: 'Baguette Bread',
                value: '🥖'
            },
            {
                title: 'Pancakes',
                value: '🥞'
            },
            {
                title: 'Bacon',
                value: '🥓'
            },
            {
                title: 'Stuffed Flatbread',
                value: '🥙'
            },
            {
                title: 'Egg',
                value: '🥚'
            },
            {
                title: 'Shallow Pan Of Food',
                value: '🥘'
            },
            {
                title: 'Green Salad',
                value: '🥗'
            },
            {
                title: 'Glass of Milk',
                value: '🥛'
            },
            {
                title: 'Coconut',
                value: '🥥'
            },
            {
                title: 'Broccoli',
                value: '🥦'
            },
            {
                title: 'Pretzel',
                value: '🥨'
            },
            {
                title: 'Cut of Meat',
                value: '🥩'
            },
            {
                title: 'Sandwich',
                value: '🥪'
            },
            {
                title: 'Bowl With Spoon',
                value: '🥣'
            },
            {
                title: 'Canned Food',
                value: '🥫'
            },
            {
                title: 'Dumpling',
                value: '🥟'
            },
            {
                title: 'Fortune Cookie',
                value: '🥠'
            },
            {
                title: 'Takeout Box',
                value: '🥡'
            },
            {
                title: 'Pie',
                value: '🥧'
            },
            {
                title: 'Cup With Straw',
                value: '🥤'
            },
            {
                title: 'Chopsticks',
                value: '🥢'
            },
            {
                title: 'Mango',
                value: '🥭️'
            },
            {
                title: 'Leafy Green',
                value: '🥬️'
            },
            {
                title: 'Bagel',
                value: '🥯️'
            },
            {
                title: 'Salt',
                value: '🧂️'
            },
            {
                title: 'Moon Cake',
                value: '🥮️'
            },
            {
                title: 'Lobster',
                value: '🦞️'
            },
            {
                title: 'Cupcake',
                value: '🧁️'
            },
            {
                title: 'Fondue',
                value: '🫕'
            },
            {
                title: 'Olive',
                value: '🫒'
            },
            {
                title: 'Flatbread',
                value: '🫓'
            },
            {
                title: 'Tamale',
                value: '🫔'
            },
            {
                title: 'Bell Pepper',
                value: '🫑'
            },
            {
                title: 'Blueberries',
                value: '🫐'
            },
            {
                title: 'Bubble Tea',
                value: '🧋'
            }
        ]
    },
    {
        category: 'Transportation Emoji Symbols',
        symbols: [
            {
                title: 'Ship',
                value: '🚢'
            },
            {
                title: 'Sailboat',
                value: '⛵️'
            },
            {
                title: 'Speedboat',
                value: '🚤'
            },
            {
                title: 'Anchor',
                value: '⚓️'
            },
            {
                title: 'Rocket',
                value: '🚀'
            },
            {
                title: 'Airplane',
                value: '✈️'
            },
            {
                title: 'Seat',
                value: '💺'
            },
            {
                title: 'Helicopter',
                value: '🚁'
            },
            {
                title: 'Steam Locomotive',
                value: '🚂'
            },
            {
                title: 'Tram',
                value: '🚊'
            },
            {
                title: 'Station',
                value: '🚉'
            },
            {
                title: 'Mountain Railway',
                value: '🚞'
            },
            {
                title: 'Train',
                value: '🚆'
            },
            {
                title: 'High-Speed Train',
                value: '🚄'
            },
            {
                title: 'High-Speed Train with Bullet Nose',
                value: '🚅'
            },
            {
                title: 'Light Rail',
                value: '🚈'
            },
            {
                title: 'Metro',
                value: '🚇'
            },
            {
                title: 'Monorail',
                value: '🚝'
            },
            {
                title: 'Tram Car',
                value: '🚋'
            },
            {
                title: 'Railway Car',
                value: '🚃'
            },
            {
                title: 'Trolleybus',
                value: '🚎'
            },
            {
                title: 'Bus',
                value: '🚌'
            },
            {
                title: 'Oncoming Bus',
                value: '🚍'
            },
            {
                title: 'Recreational Vehicle',
                value: '🚙'
            },
            {
                title: 'Oncoming Automobile',
                value: '🚘'
            },
            {
                title: 'Automobile',
                value: '🚗'
            },
            {
                title: 'Taxi',
                value: '🚕'
            },
            {
                title: 'Oncoming Taxi',
                value: '🚖'
            },
            {
                title: 'Articulated Lorry',
                value: '🚛'
            },
            {
                title: 'Delivery Truck',
                value: '🚚'
            },
            {
                title: 'Police Cars Revolving Light',
                value: '🚨'
            },
            {
                title: 'Police Car',
                value: '🚓'
            },
            {
                title: 'Oncoming Police Car',
                value: '🚔'
            },
            {
                title: 'Fire Engine',
                value: '🚒'
            },
            {
                title: 'Ambulance',
                value: '🚑'
            },
            {
                title: 'Minibus',
                value: '🚐'
            },
            {
                title: 'Bicycle',
                value: '🚲'
            },
            {
                title: 'Aerial Tramway',
                value: '🚡'
            },
            {
                title: 'Suspension Railway',
                value: '🚟'
            },
            {
                title: 'Mountain Cableway',
                value: '🚠'
            },
            {
                title: 'Ferry',
                value: '⛴'
            },
            {
                title: 'Tractor',
                value: '🚜'
            },
            {
                title: 'Bus Stop',
                value: '🚏'
            },
            {
                title: 'Motorway',
                value: '🛣'
            },
            {
                title: 'Railway Track',
                value: '🛤'
            },
            {
                title: 'Motor Boat',
                value: '🛥'
            },
            {
                title: 'Small Airplane',
                value: '🛩'
            },
            {
                title: 'Airplane Departure',
                value: '🛫'
            },
            {
                title: 'Airplane Arriving',
                value: '🛬'
            },
            {
                title: 'Passenger Ship',
                value: '🛳'
            },
            {
                title: 'Ticket',
                value: '🎫'
            },
            {
                title: 'Vertical Traffic Light',
                value: '🚦'
            },
            {
                title: 'Horizontal Traffic Light',
                value: '🚥'
            },
            {
                title: 'Carousel Horse',
                value: '🎠'
            },
            {
                title: 'Ferris Wheel',
                value: '🎡'
            },
            {
                title: 'Roller Coaster',
                value: '🎢'
            },
            {
                title: 'Scooter',
                value: '🛴'
            },
            {
                title: 'Skateboard',
                value: '🛹️'
            },
            {
                title: 'Motor Scooter',
                value: '🛵'
            },
            {
                title: 'Canoe',
                value: '🛶'
            },
            {
                title: 'Sled',
                value: '🛷'
            },
            {
                title: 'Flying Saucer',
                value: '🛸'
            },
            {
                title: 'Pickup Truck',
                value: '🛻'
            }
        ]
    },
    {
        category: 'Animals Emoji Symbols',
        symbols: [
            {
                title: 'Dog Face',
                value: '🐶'
            },
            {
                title: 'Wolf Face',
                value: '🐺'
            },
            {
                title: 'Cat Face',
                value: '🐱'
            },
            {
                title: 'Mouse Face',
                value: '🐭'
            },
            {
                title: 'Hamster Face',
                value: '🐹'
            },
            {
                title: 'Rabbit Face',
                value: '🐰'
            },
            {
                title: 'Frog Face',
                value: '🐸'
            },
            {
                title: 'Tiger Face',
                value: '🐯'
            },
            {
                title: 'Koala',
                value: '🐨'
            },
            {
                title: 'Bear',
                value: '🐻'
            },
            {
                title: 'Pig Face',
                value: '🐷'
            },
            {
                title: 'Pig Nose',
                value: '🐽'
            },
            {
                title: 'Cow Face',
                value: '🐮'
            },
            {
                title: 'Boar',
                value: '🐗'
            },
            {
                title: 'Monkey Face',
                value: '🐵'
            },
            {
                title: 'Monkey',
                value: '🐒'
            },
            {
                title: 'Horse Face',
                value: '🐴'
            },
            {
                title: 'Sheep',
                value: '🐑'
            },
            {
                title: 'Elephant',
                value: '🐘'
            },
            {
                title: 'Panda Face',
                value: '🐼'
            },
            {
                title: 'Penguin',
                value: '🐧'
            },
            {
                title: 'Bird',
                value: '🐦'
            },
            {
                title: 'Baby Chick',
                value: '🐤'
            },
            {
                title: 'Front-Facing Baby Chick',
                value: '🐥'
            },
            {
                title: 'Hatching Chick',
                value: '🐣'
            },
            {
                title: 'Chicken',
                value: '🐔'
            },
            {
                title: 'Snake',
                value: '🐍'
            },
            {
                title: 'Turtle',
                value: '🐢'
            },
            {
                title: 'Bug',
                value: '🐛'
            },
            {
                title: 'Honeybee',
                value: '🐝'
            },
            {
                title: 'Ant',
                value: '🐜'
            },
            {
                title: 'Chipmunk',
                value: '🐿'
            },
            {
                title: 'Lady Beetle',
                value: '🐞'
            },
            {
                title: 'Snail',
                value: '🐌'
            },
            {
                title: 'Octopus',
                value: '🐙'
            },
            {
                title: 'Spiral Shell',
                value: '🐚'
            },
            {
                title: 'Tropical Fish',
                value: '🐠'
            },
            {
                title: 'Fish',
                value: '🐟'
            },
            {
                title: 'Dolphin',
                value: '🐬'
            },
            {
                title: 'Spouting Whale',
                value: '🐳'
            },
            {
                title: 'Whale',
                value: '🐋'
            },
            {
                title: 'Cow',
                value: '🐄'
            },
            {
                title: 'Ram',
                value: '🐏'
            },
            {
                title: 'Rat',
                value: '🐀'
            },
            {
                title: 'Water Buffalo',
                value: '🐃'
            },
            {
                title: 'Tiger',
                value: '🐅'
            },
            {
                title: 'Rabbit',
                value: '🐇'
            },
            {
                title: 'Dragon',
                value: '🐉'
            },
            {
                title: 'Horse',
                value: '🐎'
            },
            {
                title: 'Goat',
                value: '🐐'
            },
            {
                title: 'Rooster',
                value: '🐓'
            },
            {
                title: 'Dog',
                value: '🐕'
            },
            {
                title: 'Pig',
                value: '🐖'
            },
            {
                title: 'Mouse',
                value: '🐁'
            },
            {
                title: 'Ox',
                value: '🐂'
            },
            {
                title: 'Dragon Face',
                value: '🐲'
            },
            {
                title: 'Blowfish',
                value: '🐡'
            },
            {
                title: 'Crocodile',
                value: '🐊'
            },
            {
                title: 'Bactrian Camel',
                value: '🐫'
            },
            {
                title: 'Dromedary Camel',
                value: '🐪'
            },
            {
                title: 'Leopard',
                value: '🐆'
            },
            {
                title: 'Cat',
                value: '🐈'
            },
            {
                title: 'Poodle',
                value: '🐩'
            },
            {
                title: 'Paw Prints',
                value: '🐾'
            },
            {
                title: 'Crab',
                value: '🦀'
            },
            {
                title: 'Lion Face',
                value: '🦁'
            },
            {
                title: 'Scorpion',
                value: '🦂'
            },
            {
                title: 'Spider',
                value: '🕷'
            },
            {
                title: 'Turkey',
                value: '🦃'
            },
            {
                title: 'Unicorn Face',
                value: '🦄'
            },
            {
                title: 'Shrimp',
                value: '🦐'
            },
            {
                title: 'Squid',
                value: '🦑'
            },
            {
                title: 'Butterfly',
                value: '🦋'
            },
            {
                title: 'Gorilla',
                value: '🦍'
            },
            {
                title: 'Fox Face',
                value: '🦊'
            },
            {
                title: 'Deer',
                value: '🦌'
            },
            {
                title: 'Rhinoceros',
                value: '🦏'
            },
            {
                title: 'Bat',
                value: '🦇'
            },
            {
                title: 'Eagle',
                value: '🦅'
            },
            {
                title: 'Duck',
                value: '🦆'
            },
            {
                title: 'Owl',
                value: '🦉'
            },
            {
                title: 'Lizard',
                value: '🦎'
            },
            {
                title: 'Shark',
                value: '🦈'
            },
            {
                title: 'Zebra',
                value: '🦓'
            },
            {
                title: 'Giraffe',
                value: '🦒'
            },
            {
                title: 'Hedgehog',
                value: '🦔'
            },
            {
                title: 'Sauropod',
                value: '🦕'
            },
            {
                title: 'T-Rex',
                value: '🦖'
            },
            {
                title: 'Raccoon',
                value: '🦝️'
            },
            {
                title: 'Llama',
                value: '🦙️'
            },
            {
                title: 'Hippopotamus',
                value: '🦛️'
            },
            {
                title: 'Kangaroo',
                value: '🦘️'
            },
            {
                title: 'Badger',
                value: '🦡️'
            },
            {
                title: 'Swan',
                value: '🦢️'
            },
            {
                title: 'Peacock',
                value: '🦚️'
            },
            {
                title: 'Parrot',
                value: '🦜️'
            },
            {
                title: 'Mosquito',
                value: '🦟️'
            },
            {
                title: 'Polar Bear',
                value: '🐻\u200d❄️'
            },
            {
                title: 'Seal',
                value: '🦭'
            },
            {
                title: 'Water Buffalo',
                value: '🐃'
            },
            {
                title: 'Worm',
                value: '🪱'
            },
            {
                title: 'Bison',
                value: '🦬'
            },
            {
                title: 'Cockroach',
                value: '🪳'
            },
            {
                title: 'Mammoth',
                value: '🦣'
            },
            {
                title: 'Dodo',
                value: '🦤'
            },
            {
                title: 'Black Cat',
                value: '🐈\u200d⬛'
            },
            {
                title: 'Cricket',
                value: '🦗'
            },
            {
                title: 'Beaver',
                value: '🦫'
            },
            {
                title: 'Beetle',
                value: '🪲'
            },
            {
                title: 'Fly',
                value: '🪰'
            }
        ]
    },
    {
        category: 'Hands Emoji Symbols',
        symbols: [
            {
                title: 'Thumbs Up',
                value: '👍'
            },
            {
                title: 'Thumbs Down',
                value: '👎'
            },
            {
                title: 'OK Hand',
                value: '👌'
            },
            {
                title: 'Oncoming Fist',
                value: '👊'
            },
            {
                title: 'Raised Fist',
                value: '✊'
            },
            {
                title: 'Victory Hand',
                value: '✌️'
            },
            {
                title: 'Waving Hand',
                value: '👋'
            },
            {
                title: 'Raised Hand',
                value: '✋'
            },
            {
                title: 'Open Hands',
                value: '👐'
            },
            {
                title: 'Backhand Index Pointing Up',
                value: '👆'
            },
            {
                title: 'Backhand Index Pointing Down',
                value: '👇'
            },
            {
                title: 'Backhand Index Pointing Right',
                value: '👉'
            },
            {
                title: 'Backhand Index Pointing Left',
                value: '👈'
            },
            {
                title: 'Raising Hands',
                value: '🙌'
            },
            {
                title: 'Folded Hands',
                value: '🙏'
            },
            {
                title: 'Flexed Biceps',
                value: '💪'
            },
            {
                title: 'Vulcan Salute',
                value: '🖖'
            },
            {
                title: 'Hand with Fingers Splayed',
                value: '🖐'
            },
            {
                title: 'Index Pointing Up',
                value: '☝️'
            },
            {
                title: 'Clapping Hands',
                value: '👏'
            },
            {
                title: 'Writing Hand',
                value: '✍️'
            },
            {
                title: 'Sign of the Horns',
                value: '🤘'
            },
            {
                title: 'Middle Finger',
                value: '🖕'
            },
            {
                title: 'Crossed Fingers',
                value: '🤞'
            },
            {
                title: 'Call Me Hand',
                value: '🤙'
            },
            {
                title: 'Left-Facing Fist',
                value: '🤛'
            },
            {
                title: 'Right-Facing Fist',
                value: '🤜'
            },
            {
                title: 'Back Of Hand',
                value: '🤚'
            },
            {
                title: 'Handshake',
                value: '🤝'
            },
            {
                title: 'Love-You Gesture',
                value: '🤟'
            },
            {
                title: 'Palms Up Together',
                value: '🤲'
            },
            {
                title: 'Pinched Fingers',
                value: '🤌'
            },
            {
                title: 'Nail Polish',
                value: '💅'
            }
        ]
    },
    {
        category: 'Arrows Emoji Symbols',
        symbols: [
            {
                title: 'Up Arrow',
                value: '⬆️'
            },
            {
                title: 'Down Arrow',
                value: '⬇️'
            },
            {
                title: 'Left Arrow',
                value: '⬅️'
            },
            {
                title: 'Right Arrow',
                value: '➡️'
            },
            {
                title: 'Up-Right Arrow',
                value: '↗️'
            },
            {
                title: 'Up-Left Arrow',
                value: '↖️'
            },
            {
                title: 'Down-Right Arrow',
                value: '↘️'
            },
            {
                title: 'Down-Left Arrow',
                value: '↙️'
            },
            {
                title: 'Left Right Arrow',
                value: '↔️'
            },
            {
                title: 'Up Down Arrow',
                value: '↕️'
            },
            {
                title: 'Counterclockwise Arrows',
                value: '🔄'
            },
            {
                title: 'Left-Pointing Triangle',
                value: '◀️'
            },
            {
                title: 'Right-Pointing Triangle',
                value: '▶️'
            },
            {
                title: 'Up-Pointing Triangle',
                value: '🔼'
            },
            {
                title: 'Down-Pointing Triangle',
                value: '🔽'
            },
            {
                title: 'Right Arrow Curving Left',
                value: '↩️'
            },
            {
                title: 'Left Arrow Curving Right',
                value: '↪️'
            },
            {
                title: 'Rewind Symbol',
                value: '⏪'
            },
            {
                title: 'Fast Forward Symbol',
                value: '⏩'
            },
            {
                title: 'Up-Pointing Double Triangle',
                value: '⏫'
            },
            {
                title: 'Down-Pointing Double Triangle',
                value: '⏬'
            },
            {
                title: 'Right Arrow Curving Down',
                value: '⤵️'
            },
            {
                title: 'Right Arrow Curving Up',
                value: '⤴️'
            },
            {
                title: 'Shuffle Tracks Symbol',
                value: '🔀'
            },
            {
                title: 'Repeat Symbol',
                value: '🔁'
            },
            {
                title: 'Repeat Single Track Symbol',
                value: '🔂'
            },
            {
                title: 'Clockwise Vertical Arrows',
                value: '🔃'
            },
            {
                title: 'Top Arrow',
                value: '🔝'
            },
            {
                title: 'End Arrow',
                value: '🔚'
            },
            {
                title: 'Back Arrow',
                value: '🔙'
            },
            {
                title: 'On! Arrow',
                value: '🔛'
            },
            {
                title: 'Soon Arrow',
                value: '🔜'
            }
        ]
    },
    {
        category: 'Buildings Emoji Symbols',
        symbols: [
            {
                title: 'House Building',
                value: '🏠'
            },
            {
                title: 'House with Garden',
                value: '🏡'
            },
            {
                title: 'School',
                value: '🏫'
            },
            {
                title: 'Office Building',
                value: '🏢'
            },
            {
                title: 'Japanese Post Office',
                value: '🏣'
            },
            {
                title: 'Hospital',
                value: '🏥'
            },
            {
                title: 'Bank',
                value: '🏦'
            },
            {
                title: 'Convenience Store',
                value: '🏪'
            },
            {
                title: 'Love Hotel',
                value: '🏩'
            },
            {
                title: 'Hotel',
                value: '🏨'
            },
            {
                title: 'Wedding',
                value: '💒'
            },
            {
                title: 'Church',
                value: '⛪️'
            },
            {
                title: 'Department Store',
                value: '🏬'
            },
            {
                title: 'European Post Office',
                value: '🏤'
            },
            {
                title: 'Sunset over Buildings',
                value: '🌇'
            },
            {
                title: 'Mosque',
                value: '🕌'
            },
            {
                title: 'Synagogue',
                value: '🕍'
            },
            {
                title: 'Cityscape at Dusk',
                value: '🌆'
            },
            {
                title: 'Japanese Castle',
                value: '🏯'
            },
            {
                title: 'Castle',
                value: '🏰'
            },
            {
                title: 'Tent',
                value: '⛺️'
            },
            {
                title: 'Factory',
                value: '🏭'
            },
            {
                title: 'Tokyo Tower',
                value: '🗼'
            },
            {
                title: 'House Buildings',
                value: '🏘'
            },
            {
                title: 'National Park',
                value: '🏞'
            },
            {
                title: 'Stadium',
                value: '🏟'
            },
            {
                title: 'Cityscape',
                value: '🏙'
            },
            {
                title: 'Derelict House Building',
                value: '🏚'
            },
            {
                title: 'Classical Building',
                value: '🏛'
            },
            {
                title: 'Building Construction',
                value: '🏗'
            },
            {
                title: 'Hut',
                value: '🛖'
            },
            {
                title: 'Headstone',
                value: '🪦'
            }
        ]
    },
    {
        category: 'Sport Emoji Symbols',
        symbols: [
            {
                title: 'Game Die',
                value: '🎲'
            },
            {
                title: 'Direct Hit',
                value: '🎯'
            },
            {
                title: 'American Football',
                value: '🏈'
            },
            {
                title: 'Basketball and Hoop',
                value: '🏀'
            },
            {
                title: 'Soccer Ball',
                value: '️⚽️'
            },
            {
                title: 'Baseball',
                value: '⚾️'
            },
            {
                title: 'Tennis Racquet and Ball',
                value: '🎾'
            },
            {
                title: 'Billiards',
                value: '🎱'
            },
            {
                title: 'Rugby Football',
                value: '🏉'
            },
            {
                title: 'Bowling',
                value: '🎳'
            },
            {
                title: 'Flag in Hole',
                value: '️⛳️'
            },
            {
                title: 'Chequered Flag',
                value: '🏁'
            },
            {
                title: 'Horse Racing',
                value: '🏇'
            },
            {
                title: 'Trophy',
                value: '🏆'
            },
            {
                title: 'Skier',
                value: '⛷'
            },
            {
                title: 'Ice Skate',
                value: '⛸'
            },
            {
                title: 'Cricket Bat and Ball',
                value: '🏏'
            },
            {
                title: 'Volleyball',
                value: '🏐'
            },
            {
                title: 'Field Hockey Stick and Ball',
                value: '🏑'
            },
            {
                title: 'Ice Hockey Stick and Puck',
                value: '🏒'
            },
            {
                title: 'Table Tennis Paddle and Ball',
                value: '🏓'
            },
            {
                title: 'Badminton Racquet and Shuttlecock',
                value: '🏸'
            },
            {
                title: 'Bow and Arrow',
                value: '🏹'
            },
            {
                title: 'Snowboarder',
                value: '🏂'
            },
            {
                title: 'Racing Motorcycle',
                value: '🏍'
            },
            {
                title: 'Racing Car',
                value: '🏎'
            },
            {
                title: 'Person Fencing',
                value: '🤺'
            },
            {
                title: 'Goal Net',
                value: '🥅'
            },
            {
                title: 'First Place Medal',
                value: '🥇'
            },
            {
                title: 'Second Place Medal',
                value: '🥈'
            },
            {
                title: 'Third Place Medal',
                value: '🥉'
            },
            {
                title: 'Boxing Glove',
                value: '🥊'
            },
            {
                title: 'Martial Arts Uniform',
                value: '🥋'
            },
            {
                title: 'People Wrestling',
                value: '🤼'
            },
            {
                title: 'Men Wrestling',
                value: '🤼\u200d♂️'
            },
            {
                title: 'Fishing Pole and Fish',
                value: '🎣'
            },
            {
                title: 'Curling Stone',
                value: '🥌'
            }
        ]
    }    
];