import PropTypes from 'prop-types';
import * as Yup from 'yup';
import "../../../modules/custom-validation/EditorValidation"

import 'highlight.js'
import {  useEffect, useMemo,useState} from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import Slider from '@mui/material/Slider';
import { Box, Card,  Stack, Button as DefButton } from '@mui/material';


// components
import { FormProvider, RHFTextField, RHFEditor } from '../../../components/hook-form';

import './AboutForm.css'; 
import AboutService from 'services/about-service';
import RHFSlider from 'components/hook-form/RHFSlider';
import FixedMenu from 'components/FixedMenu';
import { findIndex } from 'lodash';

// ----------------------------------------------------------------------

AboutForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.array,
};


export default function AboutForm({ isEdit, currentData }) {
  const navigate = useNavigate();

  const [lng,setLng] = useState('az')

  const aboutSchema = Yup.object().shape({
    chartValue1: Yup.number().nullable(),
    chartValue2: Yup.number().nullable(),
    chartValue3: Yup.number().nullable(),
    chartValue4: Yup.number().nullable(),
    maxChartValue1: Yup.number().nullable().min(Yup.ref("chartValue1"),v=> `${v.min} dan boyuk olmalıdı`),
    maxChartValue2: Yup.number().nullable().min(Yup.ref("chartValue2"),v=> `${v.min} dan boyuk olmalıdı`),
    maxChartValue3: Yup.number().nullable().min(Yup.ref("chartValue3"),v=> `${v.min} dan boyuk olmalıdı`),
    maxChartValue4: Yup.number().nullable().min(Yup.ref("chartValue4"),v=> `${v.min} dan boyuk olmalıdı`),
    translates: Yup.array()
    .of(
      Yup.object().shape({       
        chartText1: Yup.string().nullable().required("Xana doldurulmalıdır"),
        chartText2: Yup.string().nullable().required("Xana doldurulmalıdır"),
        chartText3: Yup.string().nullable().required("Xana doldurulmalıdır"),
        chartText4: Yup.string().nullable().required("Xana doldurulmalıdır"),
        text: Yup.string().nullable()
          .requiredEditor("Mətni doldurmaq vacibdir")
      })
    )
  });

  const translates = [
    {
      id: 0,
      lng :'az',
      chartText1:'',
      chartText2:'',
      chartText3:'',
      chartText4:'',
      text:''
    },{
      id: 0,  
      lng :'ru',
      chartText1:'',
      chartText2:'',
      chartText3:'',
      chartText4:'',
      text:''
    }]

  const defaultValues = useMemo(    
    () => ({
      chartValue1 : currentData.length > 0 ? currentData[0].chartValue1 : 0,
      chartValue2 : currentData.length > 0 ? currentData[0].chartValue2 : 0,
      chartValue3 : currentData.length > 0 ? currentData[0].chartValue3 : 0,
      chartValue4 : currentData.length > 0 ? currentData[0].chartValue4 : 0,
      maxChartValue1 : currentData.length > 0 ? currentData[0].maxChartValue1 : 0,
      maxChartValue2 : currentData.length > 0 ? currentData[0].maxChartValue2 : 0,
      maxChartValue3 : currentData.length > 0 ? currentData[0].maxChartValue3 : 0,
      maxChartValue4 : currentData.length > 0 ? currentData[0].maxChartValue4 : 0,
      translates: currentData.length > 0 ? [...currentData]: translates
    }),
    [currentData]
  );

  const methods = useForm({    
    resolver: yupResolver(aboutSchema),
    defaultValues,
  });

  const {
    register,
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const {
    fields    
  } = useFieldArray({
    control,
    name: "translates"
  });

  useEffect(() => {
    if (isEdit && currentData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, currentData]);

  const translatesField = useWatch({
    control,
    name: "translates",
  });
  

  useEffect(() => {
    errors.translates && setLng(translatesField[findIndex(errors?.translates,a=>a!=null)].lng) 
  }, [errors]);

  const onSubmit = async (formData) => {
    console.log(formData)        
    AboutService.edit(formData)
    .then(a=>
      console.log(a.hasError)
    )
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>   
    <FixedMenu setLng={setLng} lng={lng}/>  
      <Card sx={{ p: 3 }}>
        <Box >

          {fields.map((item, index) => 
            <Box  key={item.id}  sx={{ display:item.lng == lng? "block":"none"}}>            
              <RHFEditor name={`translates.${index}.text`} nameEditor={`translates${index}text`} />
            </Box>
          )}

          <Box className='chart'>
            {fields.map((item, index) =>                
              <RHFTextField sx={{ display:item.lng == lng? "inline-flex":"none" }} key={item.id} 
              name={`translates.${index}.chartText1`} label={'Qrafik 1'} />                
            )} 
            <Box className='chart-input'>
              <RHFTextField type="number" name="chartValue1" /><RHFTextField label='Max' type="number" name="maxChartValue1" />
            </Box>

            {fields.map((item, index) =>                
              <RHFTextField sx={{ display:item.lng == lng? "inline-flex":"none" }} key={item.id} 
              name={`translates.${index}.chartText2`} label={'Qrafik 2'} />                
            )} 
            <Box className='chart-input'>
              <RHFTextField type="number" name="chartValue2" /><RHFTextField label='Max' type="number" name="maxChartValue2" />
            </Box>
           
            {fields.map((item, index) =>                
              <RHFTextField sx={{ display:item.lng == lng? "inline-flex":"none" }} key={item.id} 
              name={`translates.${index}.chartText3`} label={'Qrafik 3'} />                
            )} 
            <Box className='chart-input'>
            <RHFTextField type="number" name="chartValue3" /><RHFTextField label='Max' type="number" name="maxChartValue3" />
            </Box>
            
            {fields.map((item, index) =>                
              <RHFTextField sx={{ display:item.lng == lng? "inline-flex":"none" }} key={item.id} 
              name={`translates.${index}.chartText4`} label={'Qrafik 4'} />                
            )} 
            <Box className='chart-input'>
            <RHFTextField type="number" name="chartValue4" /><RHFTextField label='Max' type="number" name="maxChartValue4" />           
            </Box>
          </Box>          
            
        </Box> 
        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
             Dəyiş
          </LoadingButton>
        </Stack>       
      </Card>
    </FormProvider>
  );
}
