import { useEffect,useState } from 'react';
import styles from './styles.module.css';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Box, Button, Card, Container, IconButton } from '@mui/material';

import Page from '../../components/Page';
import SettingService from 'services/setting-service';
import EmailForm from 'sections/@dashboard/setting/EmailForm';
import SettingForm from 'sections/@dashboard/setting/SettingForm';
import { find, map } from 'lodash';
import Iconify from 'components/Iconify';
import FixedMenu from 'components/FixedMenu';
import DeleteDialog from 'components/DeleteDialog';

const Settings=()=>{
  const [data, setData] = useState({});
  const [open,setOpen] = useState(false)
  const [parentId, setParentId] = useState()
  const [emailForm, setEmailFrom] = useState()
  const [emailForms, setEmailFroms] = useState()
  const [expanded, setExpanded] = useState(false);
  const [lng, setLng] = useState('az')
  const [deleteId, setDeleteId] = useState()
  const [openDialog, setOpenDialog] = useState(false)


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  const handleData=()=>{
    SettingService.getSetting().then(d=>{
      if(!d.hasError) {
        setData(d.data)
        console.log(d.data,"setting")      
      } 
    });  
  }

  const handleEmailForms=()=>{
    SettingService.getEmailForms().then(d=>{
      if(!d.hasError) {
        setEmailFroms(d.data)
        console.log(d.data,"emailForms")      
      } 
    });  
  }

  const handleEdit = (form,id) => {   
    setEmailFrom(form || null)
    setParentId(id || null)
    setOpen(true)
  };

  useEffect(() => {
    handleData()
    handleEmailForms()
  }, []);

  const handleClose =()=>{
    setOpen(false);
    handleEmailForms()
  }

  const handleDelete = () => {
    SettingService.delEmailForm(deleteId).then(res=>{
      if(!res.hasError) {
        handleEmailForms()
        setOpenDialog(false);
      }
    });
  };

  const handleCloseDialog =()=>{
    setDeleteId()
    setOpenDialog(false);
  }

  const handleDeleteDialog =(id)=>{    
    setDeleteId(id)
    setOpenDialog(true);
  }

  return (
    <Page title="User: Create a new user">
      <DeleteDialog open={openDialog} handleClose={handleCloseDialog} handleDelete={handleDelete} />
      <FixedMenu setLng={setLng} lng={lng} /> 
      <EmailForm currentData={emailForm} isEdit={emailForm != null} setLng={setLng} lng={lng} parentId={parentId} onClose={handleClose} open={open} />
      <Container maxWidth={false}>       
        <SettingForm setLng={setLng} currentData={data} lng={lng}/>
      </Container>
      <Box height={30}></Box>
      <Container maxWidth={false}> 
      <Card>
        <Box className={styles.EmailCategory}>
          <Box>
            Form tipləri
          </Box>
          <Box className={styles.AccordionContainer}>
            {map(emailForms,(form,index)=>(
              < Box key={index}>
                 <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
               <AccordionSummary className={styles.AccordionSummary}              
               >                 
                 <Box className={styles.TextAndButton}>
                    <Box></Box>
                    <label>{find(form.translates,a=>a.lng == "az")?.name}</label>
                    <IconButton onClick={()=>handleEdit(form)}><Iconify icon="eva:edit-outline"/></IconButton>
                    <IconButton onClick={()=>handleDeleteDialog(form.id)}><Iconify icon="clarity:remove-line"/></IconButton>                    
                  </Box>
               </AccordionSummary>
               <AccordionDetails>
                <Box className={styles.SubItems}>
                  {map(form.subs,(sub,subi)=>(
                  <Box key={subi} className={styles.TextAndButton}>
                    <Box></Box>
                    <label>{find(sub.translates,a=>a.lng == "az")?.name}</label>
                    <IconButton onClick={()=>handleEdit(sub,form.id)}><Iconify icon="eva:edit-outline"/></IconButton>
                    <IconButton onClick={()=>handleDeleteDialog(sub.id)}><Iconify icon="clarity:remove-line"/></IconButton>                    
                  </Box>))}
                  <Button onClick={()=>handleEdit(null,form.id)}> Əlavə et </Button> 
                </Box>                               
               </AccordionDetails>
               </Accordion>  
              </Box>
                 
            ))}

            <Button onClick={()=>handleEdit()}> Əlavə et </Button>
                      
          </Box>
        </Box>        
      </Card>      
        
      </Container>     
    </Page>
  );
}

export default Settings;