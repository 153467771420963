import http from '../common/http-common'

const getAll = () => {    
    return http.get("blogPages");  
}

const getEdit = (id) => {
    if(id == undefined) return http.get("blogPages/add");  
    return http.get(`blogPages/${id}/edit`);  
}


const edit =(data)=>{

    const form = new FormData();    
    
    form.append('Id',data.id || 0)
    form.append('PageId',data.pageId)
    form.append('ShowHome',data.showHome)
    form.append('ImageName',data.image?.name || data.imageName)
    form.append('Image',data.image || "")    

    data.translates.map((i,index)=>{
       form.append(`Translates[${index}].Id`,i.id || 0) 
       form.append(`Translates[${index}].Lng`,i.lng)  
       form.append(`Translates[${index}].Name`,i.name||"")
       form.append(`Translates[${index}].Title`,i.title ||"")       
       form.append(`Translates[${index}].Description`,i.description ||"")  
    })

    if(data.id == 0) 
        return http.post('blogPages',form,{ "Content-Type": "multipart/form-data" })
    else
        return http.put('blogPages',form,{ "Content-Type": "multipart/form-data" })
}

const del = (id) => {    
    return http.del("blogPages/"+id);  
}

const BlogService = {  
    getAll,
    getEdit,   
    edit,
    del   
};

export default BlogService;