import * as Yup from 'yup';
// form

Yup.addMethod(Yup.string, "requiredEditor", function (errorMessage) {
    return this.test(`test-editor`, errorMessage, function (value) {
      const { path, createError } = this;
  
      return (
        value && value != "<p><br></p>" && value != "" ||
        createError({ path, message: errorMessage })
      );
    });
});