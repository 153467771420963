import { useParams,useNavigate } from 'react-router-dom';
import { useEffect,useState } from 'react';


import { Box, Container } from '@mui/material';

import Page from '../components/Page';
import ServiceNewForm from 'sections/@dashboard/service/ServiceNewForm';
import CustomTable from 'components/table/CustomTable';

import ServiceService from 'services/service-servise';
import ServiceItemService from 'services/service-item-service';

const INFO = {
  title: "Xidmət detallari",
  new : "Yeni detalı",
  edit : "Dəyiş",
  delete : "Sil",
  rows : [
    { id: 'name', label: "  " },
  ],
  avatar : 'name'
}

const ServiceCreate=()=>{
  const navigate = useNavigate();
  const [data, setData] = useState([]);
    
  const { id } = useParams();

  const isEdit = id != undefined

  const handleData=()=>{
    ServiceService.getEdit(id).then(d=>{
      if(!d.hasError) {
        setData(d.data)
        console.log(d.data,"service")      } 
    });  
  }

  useEffect(() => {
    handleData()     
  }, [isEdit]);

  const handleEdit = (itemId) => {
    if(itemId == undefined)    
      navigate(`/dashboard/services/${id}/item/add`);
    else
      navigate(`/dashboard/services/${id}/item/${itemId}/edit`);
  };

  const handleDelete = (id) => {
    ServiceItemService.del(id).then(res=>{
      if(!res.hasError) handleData()
    });
  };

  return (
    <Page title="Xidmətlər">
      <Container maxWidth={false}>       
        <ServiceNewForm isEdit={isEdit} currentData={data}/>
      </Container>
      <Box height={30}></Box>
      
      {isEdit ? <CustomTable info={INFO} onDelete= {handleDelete} onEdit={handleEdit} data={data.serviceItems || []} />:<></>}  
    </Page>
  );
}

export default ServiceCreate;