import { useState ,useEffect } from 'react';
import { Link as RouterLink} from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import UserService from '../services/user-service'
// material

import Page from '../components/Page';
import CustomTable from 'components/table/CustomTable';
import UserForm from 'sections/@dashboard/user/UserForm';

import './User.css'

const INFO = {
  title: "",
  new : "Yeni",
  edit : "Dəyiş",
  delete : "Sil",
  rows : [
    { id: 'username', label: "" },
  ],
  avatar : 'name'
}

export default function User() {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [isEdit, setEdit] = useState(false);
  

  const handleData=()=>{
    UserService.get().then(d=>{
      if(!d.hasError) {
        setData(d.data)      } 
    }); 
  }

  const handleSubmit= async (value)=>{    
    const res = await UserService.edit(value)
    if(!res.hasError) handleData()
    setOpen(false)    
  }


  useEffect(() => {
    handleData() 
  }, []);

  const handleEdit = (id) => {
    if(id){
      setEdit(true);
      console.log(data.find(a=>a.id == id))
      setCurrentUser(data.find(a=>a.id == id))
    }
    else{
      setEdit(false);
      setCurrentUser(null)
    }

    setOpen(true);
    console.log(id,isEdit)
  };

  const handleDelete = (id) => {
    UserService.del(id).then(res=>{
      if(!res.hasError) handleData()
    });
  };


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Page title="User">
       <Dialog
        maxWidth='xs'
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
        <DialogContentText>
          <UserForm currentUser ={currentUser} isEdit={isEdit} onSubmit={handleSubmit} />
          
        </DialogContentText>     
        </DialogContent>
        
      </Dialog>        
        <CustomTable info={INFO} onDelete= {handleDelete} onEdit={handleEdit} data={data} />      
    </Page>
  );
}
