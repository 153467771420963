import ImageService from 'services/image-service';

export default class ImageUploader {
    constructor(quill, options) {
        this.quill = quill;
        this.options = options;
        this.range = null;

        if (typeof this.options.upload !== "function")
            console.warn(
                "[Missing config] upload function that returns a promise is required"
            );

        var toolbar = this.quill.getModule("toolbar");
        toolbar.addHandler("image", this.imageHandle.bind(this));

    }

    imageHandle() {

        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];

            const range = this.quill.getSelection(true);

            ImageService.add(file).then(res => {
                console.log(res.hasError)
                if (!res.hasError) {
                    // Insert uploaded image
                    // this.quill.insertEmbed(range.index, 'image', res.body.image);
                    this.quill.insertEmbed(range.index, 'image', res.data);

                } else {

                }
            })


        };
    }
}