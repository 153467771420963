import { useState,useEffect } from 'react';

import { Container ,Box, Button, IconButton} from '@mui/material';

import Page from '../components/Page';
import StudentNewForm from 'sections/@dashboard/student/StudentNewForm';
import StudentService from 'services/student-service';
import CustomTable from 'components/table/CustomTable';
import VideoNewForm from 'sections/@dashboard/student/VideoNewForm';
import getYoutubeUrl from 'utils/youtube';
import { map } from 'lodash';
import Iconify from 'components/Iconify';


const INFO_VIDEO = {
  title: "Videolar",
  new: "Yeni video",
  delete: "Sil",
  rows: [
    { id: 'name', label: "Ad" },
    { id: 'type', label: "Video tipi" },  
  ],
  actions:[
    "",
    "Ana səhifə",
    "Bitməmiş"
  ],
  filters : [
    { id: 'isYoutube', label: "Youtube" },  
    { id: 'isVideo', label: "Video" },  
    { id: 'notDone', label: "Bitməmiş" },
    { id: 'showOnHomePage', label: "Ana səhifədə" },
  ],
  avatar: 'type'
}

export default function Students() {  
  const [currentData,setData] = useState();
  const [images,setImages] = useState([]);
  const [videos,setVideos] = useState([]);
  const [open,setOpen] = useState(false)
  const [isEdit,setIsEdit] = useState(false)
  const [videoCurrent,setCurrentVideo] = useState()

  const handleImages = ()=>{
    StudentService.getEditImages().then(d=>{
      if(!d.hasError) {
        setImages(d.data)       
      } 
    });
  }

  const handleVideos = ()=>{
    StudentService.getEditVideos().then(d=>{
      if(!d.hasError) {
        setVideos(d.data)       
      } 
    });
  }

  const handleClose =(data)=>{
    if(data) handleVideos()
    setIsEdit(true)
    setOpen(false);
  }

  useEffect(() => {  
    StudentService.getEditText().then(d=>{
      if(!d.hasError) { 
        const a = d?.data?.length ? {translates : d.data} : {
          translates:[{
          id:0,
          lng: 'az',
          text: '',
        },
        {
          id:0,
          lng: 'ru',
          text: '',
        }]}
        setData(a)
      } 
    }); 
    handleImages()
    handleVideos()    
  }, []);

  const handleAddVideo = () => {    
    setCurrentVideo({})
    setIsEdit(false)
    setOpen(true)
  }

  const handleShowHome = async (id,show) => {    
    const res = await StudentService.showHome(id,show)
    if(!res.hasError) handleVideos()
  }

  const handlePosition =  async (id) => {    
    const res = await StudentService.position(id)
    if(!res.hasError) handleVideos()
  }

  const handleDeleteVideo = async (id) => {
    const res = await StudentService.delVideo(id)
    if(!res.hasError) handleVideos()
  }; 

  const handleUpload = (video) => {
    setCurrentVideo(video.video)
    setOpen(true)
  };

  const getVideos =()=>{
    return map(videos,(video,index)=>({...video,
      name: video?.type == 'youtube' ? video?.url :video?.name,
      isYoutube: video?.type == 'youtube',
      isVideo: video?.type == 'video',
      notDone: (video?.status != "Done" && video?.type == 'video'),     
      _actions:[
        index !=0 ? <IconButton sx={{ color: 'text.secondary' }} 
     onClick={()=>handlePosition(video.id)}> 
     <Iconify icon="akar-icons:arrow-down" rotate={2} width={20} height={20} />   
    </IconButton> : null,
    <IconButton sx={{ color: 'text.secondary' }} 
      onClick={()=>handleShowHome(video.id, !video.showOnHomePage)}> 
     { video?.showOnHomePage ? <Iconify icon="icomoon-free:checkbox-checked" width={20} height={20} />:
      <Iconify icon="icomoon-free:checkbox-unchecked" width={20} height={20} />} 
    </IconButton>,        

     (video?.status != "Done" && video?.type == 'video') ? 
      <Button sx={{ color: 'text.secondary' }} size="small" 
        onClick={()=>handleUpload({video})} 
        startIcon={<Iconify icon="uil:play" width={20} height={20} />}>
        Yükləməyə davam et
      </Button>:null
    ]}))
  }
  
  return (
    <Page title="Uğurlu tələbələr">
      <VideoNewForm currentData={videoCurrent}  open={open} isEdit={isEdit} onClose={handleClose}/>     
      <Container maxWidth={false}>       
        <StudentNewForm currentData={currentData} />       
        <Box sx={{ mt: 3 }}>        
          <CustomTable data={getVideos()}  info={INFO_VIDEO} onEdit={handleAddVideo} onDelete={handleDeleteVideo} />
        </Box>        
      </Container>
    </Page>
  );
}

