import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate  } from 'react-router-dom';


import Page from '../components/Page';
import CustomTable from '../components/table/CustomTable';

import PageService from 'services/page-service';


const INFO = {
  title: "",
  new : "Yeni",
  edit : "Dəyiş",
  delete : "Sil",
  rows : [
    { id: 'name', label: "" },
  ],
  avatar : 'name'
}

export default function DynamicPage() {
  const [data, setData] = useState([]);
  let navigate = useNavigate();

  const handleData=()=>{
    PageService.getAll().then(d=>{
      if(!d.hasError) {
        setData(d.data)
        console.log(d.data)
      } 
    });
  }

  useEffect(() => {
    handleData() 
  }, []);
  
  const handleEdit = (id) => {
    if(id == undefined) navigate(`/dashboard/pages/add`);
    else navigate(`/dashboard/pages/${id}/edit`);
  };

  const handleDelete = (id) => {
    PageService.del(id).then(res=>{
      if(!res.hasError) handleData()
    });
  };

  return (
    <Page title="İşçilər">
      <CustomTable info={INFO} onDelete= {handleDelete} onEdit={handleEdit} data={data} />             
    </Page>
  );
}
