import PropTypes from 'prop-types';
import * as Yup from 'yup';
import "../../../modules/custom-validation/EditorValidation"

import 'highlight.js'
import { useCallback, useEffect, useMemo,useState} from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm, Controller, useWatch ,useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, Button as DefButton, FormControlLabel } from '@mui/material';

// components
import Label from '../../../components/Label';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar, RHFEditor } from '../../../components/hook-form';

import EmployeeService from 'services/employee-service';
import FixedMenu from 'components/FixedMenu';
import { findIndex } from 'lodash';

// ----------------------------------------------------------------------

EmployeeNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
};


export default function EmployeeNewForm({ isEdit, currentData }) {
  const navigate = useNavigate();

  const [lng,setLng] = useState('az')

  const employeeSchema = Yup.object().shape({    
    
    image: Yup.mixed().test('required', 'Avatar is required', (value) => value !== ''),
    translates:  Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().nullable().required('Ad məcburidir'),
        surname: Yup.string().nullable().required('Soyad məcburidir'),
        position: Yup.string().nullable().required("Vəzifə məcburidir"),     
        longDescription: Yup.string().nullable()
          .requiredEditor("Mətn məcburidir")
      })
    )
  });

  const translates = [{
    id:0,
    lng :'az',
    position :'',
    name :'',
    surname :'',
    longDescription :''
  },
  {
    lng :'ru',
    position :'',
    name :'',
    surname :'',
    longDescription :''
  }]

  const defaultValues = useMemo(
    () => ({
      id: currentData?.id || 0,
      showOnHomePage: currentData?.showOnHomePage || false,
      image: currentData?.image || '',      
      root: currentData?.root || '',      
      imageName: currentData?.imageName || '',
      desc: currentData?.desc || '',    
      email: currentData?.email || '',
      linkFacebook: currentData?.linkFacebook || '',
      linkLinkedin: currentData?.linkLinkedin || '',
      linkInstagram: currentData?.linkInstagram || '',
      linkTwitter: currentData?.linkTwitter || '',
      officePhonenumber: currentData?.officePhonenumber || '',
      perconalPhonenumber: currentData?.perconalPhonenumber ||'',
      translates:  currentData?.translates.length > 0 ? currentData?.translates: translates     
    }),
    [currentData]
  );

  const methods = useForm({
    resolver: yupResolver(employeeSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors},
  } = methods;

  const {
    fields,  
  } = useFieldArray({
    control,
    name: "translates"
  });

  const translatesField = useWatch({
    control,
    name: "translates",
  });

  useEffect(() => {
    errors.translates && setLng(translatesField[findIndex(errors?.translates,a=>a!=null)].lng) 
  }, [errors]);

  useEffect(() => {
    if (isEdit && currentData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, currentData]);

  const onSubmit = async (formData) => {
    
   const a = await EmployeeService.edit(formData)
    
   if(!a.hasError) navigate("/dashboard/employees");
  
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FixedMenu setLng={setLng} lng={lng}/>
      <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >              
              {fields.map((item, index) =>                
                <RHFTextField sx={{ display:item.lng == lng? "inline-flex":"none" }} key={item.id} name={`translates.${index}.name`} label={`Ad`} />                
              )}              
              {fields.map((item, index) =>                
                <RHFTextField sx={{ display:item.lng == lng? "inline-flex":"none" }} key={item.id} name={`translates.${index}.surname`} label={`Soyad`} />                
              )}          
              <RHFTextField name="root" label="Sehife yolu" />
              <RHFTextField name="email" label="Email" />
              <RHFTextField name="linkFacebook" label="Facebook" />
              <RHFTextField name="linkLinkedin" label="Linkedin" />
              <RHFTextField name="linkInstagram" label="Instagram" />
              <RHFTextField name="linkTwitter" label="Twitter" />
              <RHFTextField name="officePhonenumber" label="Şəxsi Nömrə" />
              <RHFTextField name="perconalPhonenumber" label="İş Nönrəsi" />
              {fields.map((item, index) =>                
                <RHFTextField sx={{ display:item.lng == lng? "inline-flex":"none" }} key={item.id} name={`translates.${index}.position`} label={`Vəzifə`} />                
              )}                          
              {fields.map((item, index) => 
                <Box  key={item.id}  sx={{ display:item.lng == lng? "block":"none", gridColumn: "1 / span 2" }}>               
                  <RHFEditor name={`translates.${index}.longDescription`} nameEditor={`translates${index}longDescription`} />
                </Box>
              )}
            </Box>           

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Yadda saxla' : 'Redatə et'}
              </LoadingButton>
            </Stack>           
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3 }}>
            <Box >
              <RHFUploadAvatar 
                sx={{width:'180px',height:"190px"}}
                name="image"              
                onChange={handleDrop}
                label="Şəkil"
              />               
            </Box>

            <RHFSwitch
              name="showOnHomePage"
              labelPlacement="start"
              label={
                <>                  
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Əsas səhifədə göstər
                  </Typography>
                </>
              }
              sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
            />
          </Card>

        </Grid>

        
      </Grid>
    </FormProvider>
  );
}
