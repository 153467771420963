export default class SymbolModule {
    constructor(quill, options) {
        this.quill = quill;
        this.options = options;
        this.range = null;
      

        var toolbar = this.quill.getModule("toolbar");
        toolbar.addHandler("symbol", this.symbolHandle.bind(this));

    }

    symbolHandle(value) {
        if(value){
            const cursorPosition = this.quill.getSelection().index;
            this.quill.insertText(cursorPosition, value);
            this.quill.setSelection(cursorPosition + value.length);
        }
        
    }
}