import http from '../common/http-common'
import isString from 'lodash/isString';


const getAll = () => {
    return http.get("DynamicPages");
}

const getEdit = (id) => {
    console.log(id,'id')
    return id == undefined ?
        http.get("DynamicPages/add") :
        http.get(`DynamicPages/${id}/edit`);
}

const edit = (data) => {

    const form = new FormData();

    form.append('Id', data.id || 0)  

   if(data.translates) 
   data.translates.map((i, index) => {
        form.append(`Translates[${index}].Id`, i.id || 0)
        form.append(`Translates[${index}].Name`, i.name || "")
        form.append(`Translates[${index}].Lng`, i.lng)
    })

    if(data.contents) 
    data.contents.map((i, index) => {
        console.log(i,'map')
        form.append(`Contents[${index}].Id`, i.id || 0)
        form.append(`Contents[${index}].Position`, index + 1)
        form.append(`Contents[${index}].Image`, i.image)
        form.append(`Contents[${index}].Reverse`, i.reverse)
        form.append(`Contents[${index}].Type`, i.type)

        if(i.translates) 
        i.translates.map((t, index1) => {
            form.append(`Contents[${index}].Translates[${index1}].Id`, t.id || 0)
            form.append(`Contents[${index}].Translates[${index1}].Content`, t.content || "")
            form.append(`Contents[${index}].Translates[${index1}].Lng`, t.lng)
        })
    })

    return data.id == 0 ?
        http.post("DynamicPages", form, { "Content-Type": "multipart/form-data" }) :
        http.put("DynamicPages", form, { "Content-Type": "multipart/form-data" })
}

const del = (id) => {    
    return http.del("DynamicPages/"+id);  
}

const PageService = {
    getAll,   
    getEdit,
    edit,
    del
};

export default PageService;